import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { BsbValidator } from './bsb-validator';
import { policyNumberValidator } from './policy-number-validator';
import { accountNumberValidator } from './account-number-validator';
import { antiFluffValidator } from './anti-fluff-validator';

@Injectable({
    providedIn: 'root'
})
export class ValidatorOrchestrator {
    constructor(public bsbValidator: BsbValidator) {}

    public syncValidatorLookup(validator: string): any {
        return this.syncValidatorTable[validator];
    }

    public asyncValidatorLookup(validator: string): any {
        return this.asyncValidatorTable[validator];
    }

    syncValidatorTable = {
        accountNumberValidator: accountNumberValidator(),
        antiFluffValidator: antiFluffValidator(),
        policyNumberValidator: policyNumberValidator(),
        requiredValidator: Validators.required
    };

    asyncValidatorTable = {
        bsbValidator: this.bsbValidator.validateBsb
    };
}
