import { FormControl } from '@angular/forms';

export function accountNumberValidator() {
    return (control: FormControl): { [key: string]: any } => {
        let regex = new RegExp('^[0-9]{1,9}$');
        if (!control.value || !regex.test(control.value) || !isStringValid(control.value)) {
            return { patternError: true };
        }
    };
}

function isStringValid(input: string): boolean {
    const invalidStringArr = [
        '1111',
        '2222',
        '3333',
        '4444',
        '5555',
        '6666',
        '7777',
        '8888',
        '9999',
        '0000',
        '0123',
        '1234',
        '2345',
        '3456',
        '4567',
        '5678',
        '6789',
        '3210',
        '4321',
        '5432',
        '6543',
        '7654',
        '8765',
        '9876'
    ];
    return !invalidStringArr.some(invalidString => input.indexOf(invalidString) >= 0);
}
