import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CecPackageModel, extraPackageDetailLabels, PackageModel } from '../../models';

@Component({
    selector: 'package-details-component',
    templateUrl: './package-details.component.html'
})
export class PackageDetailsComponent implements OnChanges, OnInit {
    @Input()
    package: PackageModel | CecPackageModel;

    @Input()
    collapsible: boolean;

    @Input()
    title: string;

    availablePackageDetails = [];
    extraPackageDetailLabels = extraPackageDetailLabels;
    panelOpen = true;

    constructor() {}

    ngOnInit() {
        if (!this.package) {
            return;
        }
        this.title = this.title || 'Package details';

        this.availablePackageDetails = Object.keys(this.extraPackageDetailLabels).filter(prop => {
            return this.package[prop] !== undefined && this.package[prop] !== '';
        });
        this.panelOpen = true;
    }

    ngOnChanges() {
        this.ngOnInit();
    }
}
