import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationDetailsSupportingDocumentationModel } from '@brokerportal/common/models/application';
import { environment } from 'environments/environment';
import { QuoteDocumentUploadTypeLabels, QuoteDocumentUploadTypes } from '../../models';
import { QuoteService } from '../../services';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog.component';
import { QuoteFormUploadDialog } from './quote-form-upload-dialog.component';

@Component({
    selector: 'quote-form-upload',
    templateUrl: './quote-form-upload.component.html',
    styleUrls: ['./quote-form-upload.component.scss']
})
export class QuoteFormUploadComponent implements OnInit {
    @Input()
    quoteExternalId: string;

    @Input()
    quoteDocumentUploadType: QuoteDocumentUploadTypes;

    @Input()
    uploadedDocuments: Array<ApplicationDetailsSupportingDocumentationModel> = [];

    @Input()
    useMultiUpload: boolean;

    @Input()
    canDelete = false;

    @Output()
    onUploadedDocumentsRequested = new EventEmitter<any>();

    @Output()
    onDeleteDocument = new EventEmitter<ApplicationDetailsSupportingDocumentationModel>();

    quoteDocumentUploadTypeLabels = QuoteDocumentUploadTypeLabels;
    quoteDocumentUploadTypeLabel: string;
    quoteDocumentUploadTypeButtonLabel: string;
    confirmResponse = 'confirm';

    constructor(public dialog: MatDialog, private quoteService: QuoteService, private snackBar: MatSnackBar) {}

    ngOnInit(): void {
        this.quoteDocumentUploadTypeLabel = this.quoteDocumentUploadTypeLabels[
            QuoteDocumentUploadTypes[this.quoteDocumentUploadType]
        ];
        this.quoteDocumentUploadTypeButtonLabel = this.quoteDocumentUploadTypeLabels[
            QuoteDocumentUploadTypes[this.quoteDocumentUploadType]
        ];
    }

    openUploadDialog() {
        this.dialog
            .open(QuoteFormUploadDialog, {
                width: '600px',
                data: {
                    useMultiUpload: this.useMultiUpload,
                    quoteExternalId: this.quoteExternalId,
                    quoteDocumentUploadType: this.quoteDocumentUploadType,
                    quoteDocumentUploadTypeLabel: this.quoteDocumentUploadTypeLabel
                }
            })
            .afterClosed()
            .subscribe(result => {
                if (result.uploaded) {
                    this.onUploadedDocumentsRequested.next();
                }
            });
    }

    deleteDocument(document: ApplicationDetailsSupportingDocumentationModel) {
        document.documentTypeLabel = QuoteDocumentUploadTypeLabels[QuoteDocumentUploadTypes[document.documentType]];
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    title: 'Delete document',
                    action: `Are you sure you want to delete this document? (${document.documentTypeLabel})`,
                    defaultNo: true
                }
            })
            .afterClosed()
            .subscribe(result => {
                if (result === this.confirmResponse) {
                    this.onDeleteDocument.next(document);
                }
            });
    }

    downloadDocument(documentGuid: string) {
        this.quoteService.downloadDocument(this.quoteExternalId, documentGuid).subscribe(result => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(result.blob);
            link.download = result.filename;
            link.click();
        });
    }
}
