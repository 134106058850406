<mat-button-toggle-group
    [name]="name"
    [disabled]="disabled"
    (change)="_emitValueChange()"
    multiple="true"
    #standardControl="matButtonToggleGroup"
    data-test-id="standard-toggle-group"
>
    <mat-button-toggle *ngFor="let opt of options" [value]="opt.value">
        <mat-icon class="option-icon">{{ opt.icon }}</mat-icon>
        <span class="option-label">{{ opt.label }}</span>
    </mat-button-toggle>
</mat-button-toggle-group>
