import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApplicationLinksService } from './application-links.service';
import { FieldMappingService } from './field-mapping.service';
import { MailTemplate, ProductTag } from '../enums';
import { MailMessage } from '../models';

@Injectable()
export class MailService {
    constructor(
        private httpClient: HttpClient,
        private applicationLinksService: ApplicationLinksService,
        private fieldMappingService: FieldMappingService
    ) {}

    send(template: MailTemplate, message: MailMessage | any): Observable<any> {
        return this.httpClient.post(`${environment.api.baseUrl}/portal/messaging/${template}`, message);
    }

    getParamatersSendToEmail(brokerEmail: string, productTag: ProductTag, data: {} = null, additionalData: {} = null) {
        const parameters = {};
        if (this.applicationLinksService.isNewJourney(productTag) || data) {
            data = Object.assign(data || {}, { brokerEmail });
            const prefilledData = this.fieldMappingService.mapFieldsToApplicationModel(
                productTag,
                data,
                additionalData
            );

            const encodedJson = btoa(JSON.stringify(prefilledData));
            parameters['prefill'] = encodedJson;
        } else {
            parameters['rsrefer_BrokerEmail'] = brokerEmail;
        }
        return parameters;
    }

    resendEmailAndSMSConsent(applicationGuid: string): Observable<any> {
        return this.httpClient.post(
            `${environment.api.baseUrl}/portal/messaging/resendConsent/${applicationGuid}`,
            null
        );
    }
}
