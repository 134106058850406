// C.f. UserAddressType in Venus
export enum AddressType {
    Current = 1,
    Previous = 2,
    CurrentJointApplicant = 3,
    PreviousJointApplicant = 4,
    CurrentCompany = 5,
    PreviousCompany = 6,
    LenderCompany_Current = 7,
    SIPPTrustee = 8,
    SIPPParty = 9,
    LenderCompany_Previous = 10,
    CompanyRegistered = 11,
    CompanyPlaceOfBusiness = 12,
    TrustCompanyRegistered = 13,
    TrustCompanyPlaceOfBusiness = 14,
    TrusteeCurrentAddress = 15,
    BeneficialOwner = 16
}
