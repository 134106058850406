import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductTag } from '@brokerportal/common/enums';
import { PortalConfigurationService } from '@brokerportal/common/services';
import { first } from 'rxjs/operators';
import { AsyncConfig, ExchangeProductModel, SubsidyFinanceLowestPaymentsResult } from '../../models';
import { ErrorCallbackGeneratorService, SubsidyEstimateService } from '../../services';
import { environment } from 'environments/environment';

const blankData = [
    { property: 'Rate', value: '0' },
    { property: 'Repayment', value: '0' },
    { property: 'Term', value: '0 months' }
];

@Component({
    selector: 'rate-preview-table',
    templateUrl: './rate-preview-table.component.html',
    styleUrls: ['./rate-preview-table.component.scss']
})
export class RatePreviewTableComponent implements OnInit, OnChanges {
    @Input()
    product: ExchangeProductModel;

    @Input()
    estimateData: SubsidyFinanceLowestPaymentsResult;

    @Input()
    financeAmount: number;

    @Input()
    isStaticSecondaryOffer: boolean;

    @Output()
    estimateDataChange = new EventEmitter<SubsidyFinanceLowestPaymentsResult>();

    firstRequestMade = false;
    requestLog: number[] = [];
    asyncConfig: AsyncConfig;

    columnsToDisplay: string[];
    rowData: any[];

    displaySuffixPrimary = {
        Rate: '% per annum',
        Repayment: ' per month',
        Term: ` months (terms from 36-84 months available)`
    };

    displaySuffix = this.displaySuffixPrimary;

    displaySuffixSecondary = {
        Rate: '% per annum',
        Repayment: ' per month',
        Term: ` months`
    };

    useDecimalPlaces = {
        Rate: true,
        Repayment: true,
        Term: false
    };

    displayPrefix = {
        Rate: '',
        Repayment: '$',
        Term: ''
    };

    displayLabel = {
        Rate: 'Estimated interest rate',
        Repayment: 'Estimated monthly repayment',
        Term: 'Loan term'
    };

    constructor(
        private productService: SubsidyEstimateService,
        private errorCallback: ErrorCallbackGeneratorService,
        private snackBar: MatSnackBar,
        private portalConfigurationService: PortalConfigurationService
    ) {
        this.columnsToDisplay = ['property', 'value'];
        this.asyncConfig = {
            isLoading: false
        };
        this.rowData = blankData;
    }

    ngOnInit() {
        if (this.isStaticSecondaryOffer) {
            this.displaySuffix = this.displaySuffixSecondary;
        }

        this.updateRateTable();
    }

    ngOnChanges() {
        this.updateRateTable();
    }

    updateRateTable() {
        if (this.needsNewEstimate()) {
            this.getEstimate();
        } else {
            this.setRateTable(this.estimateData);
        }
    }

    getEstimate() {
        this.asyncConfig.isLoading = true;
        this.portalConfigurationService
            .get()
            .pipe(first())
            .subscribe(portalConfig => {
                const matchingStaffBroker = portalConfig.brokers.find(
                    _ => _.productTag === ProductTag.SARenewableEnergy && !_.isDirect
                );

                if (!matchingStaffBroker) {
                    this.raiseBrokerError();
                    return;
                }

                const financeAmount = this.financeAmount;
                this.requestLog.push(financeAmount);
                this.firstRequestMade = true;
                this.productService
                    .getLowestMonthlyPayments(matchingStaffBroker.id, financeAmount, this.product.experience)
                    .pipe(first())
                    .subscribe(
                        result => {
                            if (!this.isNewestRequest(financeAmount)) {
                                return;
                            }
                            this.setNewRateData(result);
                        },
                        this.errorCallback.generate("calculating customer's estimated rate", this.snackBar),
                        () => (this.asyncConfig.isLoading = false)
                    );
            });
    }

    setNewRateData(newData: SubsidyFinanceLowestPaymentsResult) {
        this.estimateData = newData;
        this.setRateTable(this.estimateData);
        this.estimateDataChange.next(this.estimateData);
    }

    private isNewestRequest(financeAmount: number) {
        return this.requestLog[this.requestLog.length - 1] === financeAmount;
    }

    needsNewEstimate(): boolean {
        if (this.isStaticSecondaryOffer) {
            return false;
        }

        if (!this.estimateData) {
            return !this.firstRequestMade;
        }

        return this.estimateData.financeAmount !== this.financeAmount;
    }

    setRateTable(rateData: SubsidyFinanceLowestPaymentsResult) {
        if (!rateData) {
            return;
        }

        const paymentToUse = this.isStaticSecondaryOffer ? rateData.subventedPayment : rateData.lowestPayment;
        this.rowData = [
            { property: 'Rate', value: paymentToUse.rate },
            { property: 'Repayment', value: paymentToUse.monthlyPayment },
            { property: 'Term', value: paymentToUse.termInMonths }
        ];
    }

    raiseBrokerError() {
        this.snackBar.open(
            `Your customer's indicative payment could not be retrieved due to a portal configuration error.
    Log out and try again, or contact ${environment.constants.branding.title} for more information`,
            'Dismiss'
        );
    }
}
