// Refer to ProductTags in Venus
export enum ProductTag {
    Unknown = 0,
    PersonalLoan = 1,
    GreenLoan = 2,
    BusinessFinance = 3,
    CarLoan = 4,
    PointOfSale = 5,
    DeferredPaymentAgreement = 6,
    SwiftWorkingCapital = 7,
    LegalLoan = 8,
    SARenewableEnergy = 12,
    NSWRenewableEnergy = 13,
    RenewableBrokerLoan = 14,
    RenewableInterestFree = 15,
    CommercialCarLoan = 16
}

export const RenewableProductTags = [
    ProductTag.GreenLoan,
    ProductTag.RenewableBrokerLoan,
    ProductTag.SARenewableEnergy,
    ProductTag.NSWRenewableEnergy,
    ProductTag.RenewableInterestFree
];

export const NonQuoteRenewableProductTags = [ProductTag.GreenLoan, ProductTag.RenewableInterestFree];
export const InvoiceReferenceRenewableProductTags = [...NonQuoteRenewableProductTags];

export const ToExperience = (productTag: ProductTag): string => {
    switch (productTag) {
        case ProductTag.GreenLoan:
            return 'Green';
        case ProductTag.RenewableInterestFree:
            return 'RenewableInterestFree';
        case ProductTag.NSWRenewableEnergy:
            return 'NSWRenewableEnergy';
        case ProductTag.SARenewableEnergy:
            return 'SARenewableEnergy';
        case ProductTag.RenewableBrokerLoan:
            return 'RenewableBroker';
        default:
            return 'Unknown';
    }
};
