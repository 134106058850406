import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { datadogRum } from '@datadog/browser-rum';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.type === 'prod') {
  enableProdMode();
}

if (environment.useDatadogRum) {
  datadogRum.init({
    applicationId: environment.datadogApplicationId,
    clientToken: environment.datadogClientToken,
    service: 'broker-ui',
    env: environment.fullEnvName,
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [environment.api.baseUrl],
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true
  });
}

platformBrowserDynamic().bootstrapModule(AppModule);
