import { DateTime } from 'luxon';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { BdmDetails } from '@brokerportal/common/models/bdm-details.model';
import { environment } from 'environments/environment';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartnerType, ProductTag, UserClass } from '../enums';
import {
    BrokerConfiguration,
    PartnerConfiguration,
    PortalConfiguration,
    ProductConfiguration,
    StatusConfiguration,
    StatusGroupConfiguration,
    UserConfiguration
} from '../models';
const CONFIG_EXPIRED_DAYS = 1;

@Injectable()
export class PortalConfigurationService {
    constructor(private httpClient: HttpClient, private localStorage: LocalStorageService) {}

    init(): Observable<PortalConfiguration> {
        this.clear();
        return this.get();
    }

    clear() {
        const key = environment.constants.storage.portalConfigurationKey;
        this.localStorage.remove(key, `${key}_stored_at`);
    }

    isConfigExpired() {
        const key = environment.constants.storage.portalConfigurationKey;

        const configStoredAt = DateTime.fromJSDate(new Date(this.localStorage.get(`${key}_stored_at`)));
        const diff = DateTime.local().diff(configStoredAt, ['days']);
        return diff.days >= CONFIG_EXPIRED_DAYS;
    }

    get(): Observable<PortalConfiguration> {
        const key = environment.constants.storage.portalConfigurationKey;
        const currentConfig = this.localStorage.get<PortalConfiguration>(key);

        if (currentConfig && !this.isConfigExpired()) {
            return observableOf(currentConfig);
        }
        return this.httpClient.get(`${environment.api.baseUrl}/portal`).pipe(
            map((result: any) => {
                const mappedConfiguration: PortalConfiguration = {
                    user: this.processUser(result),
                    partner: this.processPartner(result),
                    brokers: this.processBrokers(result),
                    greenConnect: result.greenConnect,
                    statusGroupPersonal: this.processStatusGroupPersonal(result),
                    statusGroupGreen: this.processStatusGroupGreen(result),
                    statusGroupDpa: this.processStatusGroupDpa(result),
                    crmStatusGroup: this.processCRMStatusGroup(result),
                    products: this.processProducts(result),
                    menuItems: result.menuItems,
                    settings: result.settings
                };

                if (result.menuItems) {
                    this.localStorage.remove(key, `${key}_stored_at`);
                    this.localStorage.set(`${key}_stored_at`, Date.now());
                }

                this.localStorage.set(key, mappedConfiguration);
                return mappedConfiguration;
            })
        );
    }

    private processUser(result: any): UserConfiguration {
        return result.user
            ? {
                  id: result.user.id,
                  title: result.user.title,
                  firstName: result.user.firstName,
                  otherNames: result.user.otherNames,
                  lastName: result.user.lastName,
                  displayName: result.user.displayName,
                  userName: result.user.userName.trim(),
                  userClass: UserClass[<keyof typeof UserClass>result.user.userClass],
                  bdm: this.processBdm(result)
              }
            : null;
    }

    private processBdm(result: any): BdmDetails {
        return result.user.bdm
            ? {
                  id: result.user.bdm.id,
                  name: result.user.bdm.name,
                  contactNumber: result.user.bdm.contactNumber,
                  emailAddress: result.user.bdm.emailAddress,
                  avatarUrl: result.user.bdm.avatarUrl
              }
            : null;
    }

    private processPartner(result: any): PartnerConfiguration {
        return result.partner
            ? {
                  id: result.partner.id,
                  name: result.partner.name,
                  type: PartnerType[<keyof typeof PartnerType>result.partner.type],
                  isChatEnabled: result.partner.isChatEnabled,
                  showBrokerColumns: result.partner.showBrokerColumns
              }
            : null;
    }

    private processBrokers(result: any): Array<BrokerConfiguration> {
        // return result.brokers ? {}
        if (result && result.brokers instanceof Array) {
            let brokers = new Array<BrokerConfiguration>();
            result.brokers.forEach(item => {
                brokers.push(this.processBroker(item));
            });
            return brokers;
        }
        return [];
    }

    private processBroker(broker: any): BrokerConfiguration {
        return {
            id: broker.id,
            name: broker.name,
            description: broker.description,
            referralCode: broker.referralCode,
            isDirect: broker.isDirect,
            productTag: ProductTag[<keyof typeof ProductTag>broker.productTag],
            paperlessSettlement: broker.paperlessSettlement,
            supplierId: broker.supplierId,
            renderSubventionTermsOnly: broker.renderSubventionTermsOnly
        };
    }

    private processStatusGroupPersonal(result: any): StatusGroupConfiguration {
        return result.statusGroupPersonal
            ? {
                  inProgressStatuses: this.processStatusConfiguration(result.statusGroupPersonal.inProgressStatuses),
                  declinedStatuses: this.processStatusConfiguration(result.statusGroupPersonal.declinedStatuses),
                  cancelledStatuses: this.processStatusConfiguration(result.statusGroupPersonal.cancelledStatuses),
                  completedStatuses: this.processStatusConfiguration(result.statusGroupPersonal.completedStatuses)
              }
            : null;
    }

    private processStatusGroupGreen(result: any): StatusGroupConfiguration {
        return result.statusGroupGreen
            ? {
                  inProgressStatuses: this.processStatusConfiguration(result.statusGroupGreen.inProgressStatuses),
                  declinedStatuses: this.processStatusConfiguration(result.statusGroupGreen.declinedStatuses),
                  cancelledStatuses: this.processStatusConfiguration(result.statusGroupGreen.cancelledStatuses),
                  completedStatuses: this.processStatusConfiguration(result.statusGroupGreen.completedStatuses)
              }
            : null;
    }

    private processStatusGroupDpa(result: any) {
        return result.statusGroupDpa
            ? {
                  inProgressStatuses: this.processStatusConfiguration(result.statusGroupDpa.inProgressStatuses),
                  declinedStatuses: this.processStatusConfiguration(result.statusGroupDpa.declinedStatuses),
                  cancelledStatuses: this.processStatusConfiguration(result.statusGroupDpa.cancelledStatuses),
                  completedStatuses: this.processStatusConfiguration(result.statusGroupDpa.completedStatuses)
              }
            : null;
    }

    private processCRMStatusGroup(result: any) {
        return result.crmStatusGroup
            ? {
                  inProgressStatuses: this.processStatusConfiguration(result.crmStatusGroup.inProgressStatuses),
                  declinedStatuses: this.processStatusConfiguration(result.crmStatusGroup.declinedStatuses),
                  cancelledStatuses: this.processStatusConfiguration(result.crmStatusGroup.cancelledStatuses),
                  completedStatuses: this.processStatusConfiguration(result.crmStatusGroup.completedStatuses)
              }
            : null;
    }

    private processStatusConfiguration(config: any): Array<StatusConfiguration> {
        if (config && config instanceof Array) {
            let statusConfigurations = new Array<StatusConfiguration>();
            config.forEach(item => {
                statusConfigurations.push({
                    name: item.name,
                    value: item.value
                });
            });
            return statusConfigurations;
        }
        return [];
    }

    private processProducts(result: any): Array<ProductConfiguration> {
        if (result.products && result.products instanceof Array) {
            let products = new Array<ProductConfiguration>();
            result.products.forEach(item => {
                products.push({
                    id: item.id,
                    name: item.name,
                    type: ProductTag[<keyof typeof ProductTag>item.type]
                });
            });
            return products;
        }
        return [];
    }
}
