import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'feed-in-disclaimer',
    templateUrl: './feed-in-disclaimer-dialog.component.html',
    styleUrls: ['./feed-in-disclaimer-dialog.component.scss']
})
export class FeedInDisclaimerDialog {
    constructor(public dialogRef: MatDialogRef<FeedInDisclaimerDialog>) {}
}
