import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonUiModule } from '@brokerportal/common/common.module';
import { MaterialComponentsModule } from 'app/material-components.module';
import * as components from './components';
import { ScrollbarUiModule } from './modules/scrollbar/scrollbar.module';
import { SidenavUiModule } from './modules/sidenav/sidenav.module';
import { ToolbarUiModule } from './modules/toolbar/toolbar.module';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FlexLayoutModule,
        MaterialComponentsModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        CommonUiModule,
        SidenavUiModule,
        ToolbarUiModule,
        ScrollbarUiModule
    ],
    entryComponents: [],
    declarations: [components.AdminComponent],
    exports: [components.AdminComponent],
    providers: []
})
export class AdminUiModule {}
