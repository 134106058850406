import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@brokerportal/common/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExtraFieldsService {
    constructor(private httpClient: HttpClient) {}

    protected get<T>(applicationGuid: string): Observable<T> {
        return this.httpClient.get<T>(`${environment.api.baseUrl}/portal/details/${applicationGuid}/extrafields`);
    }

    getDictionary(applicationGuid: string): Observable<Dictionary<string>> {
        return this.get<Dictionary<string>>(applicationGuid);
    }

    updateValue(
        applicationGuid: string,
        fieldName: string,
        newValue: any,
        updateApplicationReport: boolean = false
    ): Observable<any> {
        return this.httpClient.post(`${environment.api.baseUrl}/portal/actions/updateExtraFields/${applicationGuid}`, {
            fieldName,
            newValue,
            bypassLoanRevision: true,
            updateApplicationReport: updateApplicationReport
        });
    }
}
