<div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;" class="fade-in">
    <div class="wrapper">
        <div class="logo">
            <img [src]="env.constants.branding.images.logoUrl" />
        </div>
        <p class="title">Broker Portal</p>
        <p>Please login to continue</p>

        <div class="login mat-elevation-z1">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
                <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="E-mail address"
                            type="email"
                            formControlName="email"
                            data-cy="input.username"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Password"
                            type="password"
                            formControlName="password"
                            data-cy="input.password"
                        />
                    </mat-form-field>
                    <button color="primary" mat-raised-button [disabled]="!form.valid" data-cy="button.submit">
                        Continue
                    </button>
                </div>
            </form>
            <bp-card-loading-overlay [isLoading]="isLoading"></bp-card-loading-overlay>
        </div>

        <p class="terms">
            <a [routerLink]="['/authentication/forgot-password']" data-cy="link.forgot-password">Can't log in?</a>
        </p>
    </div>
</div>
