import { parse } from 'tldts';

const parseResult = parse(window.location.hostname);

export let rootDomain = '',
    membersUrl = '',
    marketingUrl = '',
    apiUrl = '',
    harmonyId = '',
    harmonyApiKey = '';

if (parseResult.domain) {
    // Hostname is in the form of e.g. 'members.test.plenti.cloud.
    // Strip the initial segment so we have a common 'test.plenti.cloud'
    const hostChunks = parseResult.hostname.split('.') || [];
    rootDomain = hostChunks.splice(1, hostChunks.length - 1).join('.');
    membersUrl = `https://members.${rootDomain}`;
    apiUrl = `https://api.${rootDomain}`;
} else {
    rootDomain = 'plenti.com.au';
    apiUrl = 'http://localhost:20101';
    membersUrl = 'http://localhost:20100';
}

marketingUrl = `https://www.${rootDomain}`;

harmonyId = 'testplenti';
harmonyApiKey = 'l30SDjNPW7J8HIBvGLwIFeQiWWtPOC25';

if (rootDomain.indexOf('plenti.com.au') !== -1) {
    harmonyId = 'plentiweb';
    harmonyApiKey = 'U23kAWHrO4IPVf6D4ervmiZ5laHxdGtC';
}
