import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'bp-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
    env: any;

    constructor(private router: Router) {
        this.env = environment;

        if (!environment.maintenanceMode) {
            this.router.navigateByUrl('/dashboard');
        }
    }
}
