import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'environments/environment';

import { AuthenticationService } from './authentication.service';
import { VenusDemandMapping } from './demands.const';

@Directive({ selector: '[hasDemand]' })
export class HasDemandDirective implements OnInit {
    @Input()
    hasDemand: VenusDemandMapping;

    constructor(private el: ElementRef, private renderer: Renderer2, private authService: AuthenticationService) {}

    async ngOnInit() {
        if (environment.bypassAuthentication) {
            return true;
        }

        if (!VenusDemandMapping[this.hasDemand]) {
            console.error(`[HasDemand]: WARNING: ${this.hasDemand} does not map to a known demand!`);
            return;
        }

        const hasDemand = await this.authService.hasDemand(VenusDemandMapping[this.hasDemand]);

        if (!hasDemand) {
            this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
        }
    }
}
