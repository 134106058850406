import { Pipe, PipeTransform } from '@angular/core';
import { ApprovalConditionsStatus } from '../models/application/application-details-loan-condition.model';

@Pipe({ name: 'approvalConditionStatusDescription' })
export class ApprovalConditionStatusDescriptionPipe implements PipeTransform {
    constructor() {}

    transform(value: string): string {
        const conditionStatus: ApprovalConditionsStatus = ApprovalConditionsStatus[value];
        switch (conditionStatus) {
            case ApprovalConditionsStatus.Verified: {
                return 'Condition has been verified';
            }
            case ApprovalConditionsStatus.Requested: {
                return 'Condition has not been satisfied';
            }
            case ApprovalConditionsStatus.Received: {
                return 'Condition is yet to be satisfied';
            }
            default: {
                return 'N/A';
            }
        }
    }
}
