import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubsidyFinanceLowestPaymentsResult } from '../models';

@Injectable()
export class SubsidyEstimateService {
    constructor(private httpClient: HttpClient) {}

    getLowestMonthlyPayments(
        partnerId: string,
        financeAmount: number,
        experience: string
    ): Observable<SubsidyFinanceLowestPaymentsResult> {
        return this.httpClient
            .get(
                `${environment.api.baseUrl}/SubventionPartner/${partnerId}/LowestEstimatedRepayments?experienceType=${experience}&financeAmount=${financeAmount}`
            )
            .pipe(
                map((result: any) => {
                    return { ...result, financeAmount: financeAmount };
                })
            );
    }
}
