import { Pipe, PipeTransform } from '@angular/core';
import { IdentityVerificationStatus } from '../enums/identity-verification-status.enum';
import { CreditStatusService } from '../services/credit-status.service';

@Pipe({ name: 'identityStatusDescription' })
export class IdentityStatusDescriptionPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: IdentityVerificationStatus): string {
        // Value is expected to be string version of IdentityVerificationStatus, e.g. 'NotMatched'
        return this.creditStatusService.getIdentityStatusDescription(value);
    }
}
