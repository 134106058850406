export const ExtraFieldKeys = {
    AssociatedSupplierIds: 'associatedsupplierids',
    InvoiceReferenceNumber: 'invoicereferencenumber',
    IncomeVerificationType: 'incomeverificationtype'
};

export const RenewableExtraFieldKeys = {
    GreenIdDriverLicenceNumber: 'GreenIdDriverLicenceNumber',
    GreenIdDriverLicenceState: 'GreenIdDriverLicenceState',
    GreenIdDriverLicenceCardNumber: 'GreenIdDriverLicenceCardNumber',
    GreenLoanInstallationDate: 'GreenLoanInstallationDate',
    ProviderInstallationConfirmationDate: 'providerinstallationconfirmationdate',
    HasContractSigningReminderSentOut: 'hascontractsigningremindersentout',
    RenewableSystemType: 'renewablesystemtype',
    ConfirmSystemType: 'confirmedsystemtype',
    NMI: 'nmi',
    BessNumber: 'bessnumber',
    BatteryExternalId: 'batteryexternalid',
    BatteryCapacity: 'batterycapacity',
    BatteryQuantity: 'batteryquantity',
    BatterySerialNumber: 'batteryserialnumber',
    SolarPhotovoltaicExternalId: 'solarphotovoltaicexternalid',
    SolarPhotovoltaicCapacity: 'solarphotovoltaiccapacity',
    SolarPhotovoltaicQuantity: 'solarphotovoltaicquantity',
    InverterExternalId: 'inverterexternalid',
    InverterSerialNumber: 'inverterserialnumber',
    HasPreinstalledInverter: 'haspreinstalledinverter',
    HeatPumpModel: 'heatpumpmodel',
    HeatPumpManufacturer: 'heatpumpmanufacturer',
    EvChargerModel: 'evchargermodel',
    EvChargerManufacturer: 'evchargermanufacturer',
    SolarHotWaterModel: 'solarhotwatermodel',
    SolarHotWaterManufacturer: 'solarhotwatermanufacturer',
    AirconModel: 'airconmodel',
    AirconManufacturer: 'airconmanufacturer',
    SmartMeterModel: 'smartmetermodel',
    SmartMeterManufacturer: 'smartmetermanufacturer',
    PoolPumpModel: 'poolpumpmodel',
    PoolPumpManufacturer: 'poolpumpmanufacturer',
    EnergyMonitoringSystemModel: 'energymonitoringsystemmodel',
    EnergyMonitoringSystemManufacturer: 'energymonitoringsystemmanufacturer',
    InductionCooktopModel: 'inductioncooktopmodel',
    InductionCooktopManufacturer: 'inductioncooktopmanufacturer',
    RenewableSubsidyApplication: 'renewablesubsidyapplication',
    ExistingEnergyRetailerCustomer: 'existingenergyretailercustomer',
    VppConnectionConfirmationDate: 'vppconnectionconfirmationdate',
    AssociatedSupplierIds: 'associatedsupplierids',
    RenewableSystemDeposit: 'renewablesystemdeposit',
    RenewableSystemCost: 'renewablesystemcost',
    RenewableSubsidyAmount: 'renewablesubsidyamount',
    ProviderPaperlessFallbackEnabledDate: 'providerpaperlessfallbackenableddate',
    SplitApplication: 'SplitApplication',
    InvoiceNumber: 'invoicenumber',
    ContractAccountNumber: 'contractaccountnumber',
    SubsidyVoucherCode: 'subsidyvouchercode'
};
