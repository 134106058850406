import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { LoanDrawer } from '@brokerportal/common/models/application';
import { LoanDrawerChannel } from '@brokerportal/common/models/application/loan-drawer.model';

@Injectable()
export class LoanDrawersService {
    private LoanDrawerChannelDescription = new Map<LoanDrawerChannel, string>([
        [LoanDrawerChannel.DirectCredit, 'Direct Credit'],
        [LoanDrawerChannel.Bpay, 'BPAY'],
        [LoanDrawerChannel.RTGS, 'RTGS']
    ]);

    constructor(private httpClient: HttpClient) {}

    get(applicationGuid: string): Observable<Array<LoanDrawer>> {
        return this.httpClient.get<Array<LoanDrawer>>(
            `${environment.api.baseUrl}/portal/details/${applicationGuid}/loandrawers`
        );
    }

    getLoanDrawerChannelDescription(value: LoanDrawerChannel) {
        return this.LoanDrawerChannelDescription.get(value);
    }

    regenerateRemittance(applicationGuid: string): Observable<any> {
        return this.httpClient.post(
            `${environment.api.baseUrl}/portal/actions/regenerateRemittance/${applicationGuid}`,
            {}
        );
    }
}
