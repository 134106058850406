import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TwoFactorSettings } from '@brokerportal/modules/settings/settings.model';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class IdentityService {
    constructor(private httpClient: HttpClient) {}

    refreshToken(clientId: string) {
        return this.httpClient.get(
            `${environment.api.identityServiceUrl}/api/authentication/oauth2/refreshtoken?clientId=${clientId}`
        );
    }

    getDemand(clientId: string): Observable<string[]> {
        var request = this.httpClient.get(
            `${environment.api.identityServiceUrl}/api/authentication/oauth2/${clientId}/user-demand`
        );
        let defaultValue: string[] = [];
        return request.pipe(
            map(value => value as string[]),
            catchError(() => of(defaultValue))
        );
    }

    getTwoFactorSettings(): Observable<TwoFactorSettings> {
        return this.httpClient.get<TwoFactorSettings>(
            `${environment.api.identityServiceUrl}/api/authentication/oauth2/user-mfa`
        );
    }

    logout(clientId: string) {
        return this.httpClient.post(`${environment.api.identityServiceUrl}/api/authentication/oauth2/logout`, {
            clientId: clientId
        });
    }
}
