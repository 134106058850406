import { AbstractControl, FormControl } from '@angular/forms';

export function copyValidationValidator(validationSource: AbstractControl) {
    let savedValidationSource: AbstractControl;

    return (control: FormControl): { [key: string]: any } => {
        if (!savedValidationSource) {
            savedValidationSource = validationSource;
            savedValidationSource.valueChanges.subscribe(() => control.updateValueAndValidity({ emitEvent: false }));
        }

        return savedValidationSource.errors;
    };
}
