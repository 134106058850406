import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Scheme } from '../models';
import { map } from 'rxjs/operators';

export interface QuoteTableQueryArguments {
    page?: number;
    pageSize?: number;
    status?: string;
    sortField?: string;
    sortDirection?: 'asc' | 'desc';
    scope?: Scheme;
    quoteNumberQuery?: string;
    nameQuery?: string;
    isEaas?: boolean;
}

@Injectable()
export class QuoteTableService extends BehaviorSubject<QuoteGridDataResult> {
    private baseUrl: String;

    sortFieldMap = {
        quoteNumber: 'QuoteNumber',
        customerName: 'FirstName',
        dateCreatedFormatted: 'Created',
        conditionalApprovalDateFormatted: 'ConditionalApprovalDate',
        validUntilDateFormatted: 'QuoteValidUntil',
        currentStatusFormatted: 'QuoteStatus'
    };

    constructor(private httpClient: HttpClient) {
        super(null);
        this.baseUrl = `${environment.useDirectQuoteService ? environment.quoteServiceHost : environment.api.baseUrl}`;
    }

    public query(state: DataSourceRequestState, args: QuoteTableQueryArguments): void {
        this.fetch(state, args).subscribe(result => super.next(result));
    }

    fetch(state: DataSourceRequestState, args: QuoteTableQueryArguments): Observable<QuoteGridDataResult> {
        // Strip out the undefined or null properties so the don't get serialised as querystring params
        Object.keys(args).forEach(key => (args[key] === undefined || args[key] === null) && delete args[key]);

        var queryStr = '';
        if (state.take > 0) {
            var pageNo = Math.floor(state.skip / state.take) + 1;
            queryStr = queryStr + `?page=${pageNo}&pageSize=${state.take}&strict=true`;
        }
        if (state.sort && state.sort.length > 0) {
            if (state.sort[0].field && state.sort[0].dir) {
                if (queryStr === '') {
                    queryStr = queryStr + `?`;
                } else {
                    queryStr = queryStr + `&`;
                }
                queryStr =
                    queryStr + `sortField=${this.sortFieldMap[state.sort[0].field]}&sortDirection=${state.sort[0].dir}`;
            }
        }

        return this.httpClient
            .get<any>(`${this.baseUrl}/quotes${queryStr}`, { params: args as { [key: string]: string } })
            .pipe(
                map(response => {
                    return <QuoteGridDataResult>{
                        data: response._embedded.quotes,
                        total: response.totalQuotes
                    };
                })
            );
    }
}

@Injectable()
export class QuoteGridDataResult implements GridDataResult {
    data: any[];
    total: number;
}
