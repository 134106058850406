import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { isEmptyInputValue } from '../utils';

/**
 * Validator that requires the control's value to be less than or equal to the provided number.
 *
 * @param {number} max the maximum value.
 * @param {boolean} [inclusive=true] whether to accept the maximum value. Default to `true`.
 * @return {ValidatorFn} A validator function that returns an error map with the `max` property if the validation check fails, otherwise `null`.
 * @usageNotes This validator extends Angular's `Validators.max` with option to allow equality comparison.
 */
export function maxValidator(max: number, inclusive = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
            return null;
        }
        const value = parseFloat(control.value);
        return !isNaN(value) && (inclusive ? value > max : value >= max)
            ? { max: { max: max, actual: control.value } }
            : null;
    };
}
