import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlagService } from '../services';

@Injectable()
export class FeatureFlagGuard implements CanActivate, CanActivateChild {
    constructor(private featureFlagService: FeatureFlagService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const features = route.data['canActivateOnFeaturesEnabled'] as string[] | undefined;
        if (Array.isArray(features)) {
            return this.areEnabled(features);
        }
        return of(false);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const features = route.data['canActivateChildOnFeaturesEnabled'] as string[] | undefined;
        if (Array.isArray(features)) {
            return this.areEnabled(features);
        }
        return this.canActivate(route, state);
    }

    private areEnabled(features: string[]) {
        return features?.length > 0
            ? forkJoin(features.map(f => this.featureFlagService.isEnabled(f))).pipe(
                  map(results => results.every(r => r))
              )
            : of(false);
    }
}
