import { Pipe, PipeTransform } from '@angular/core';
import { EmploymentType } from '../enums/employment-type.constants';
import { CreditStatusService } from '../services/credit-status.service';

@Pipe({ name: 'employmentTypeDescription' })
export class EmploymentTypeDescriptionPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: EmploymentType): string {
        return this.creditStatusService.getEmploymentTypeDescription(value);
    }
}
