// C.f. ReportApplicationNextStep in Venus
export enum ReportApplicationNextStep {
    Unknown = -1,
    NotRequired = 0,
    FinishRateEstimate = 1,
    FinishApplication = 2,
    VerifyIdentity = 3,
    SubmitBankStatements = 4,
    CreditAssessment = 5,
    ContactRateSetter = 6,
    NotifyClient = 7,
    FinishQualification = 8,
    Installation = 9,
    Approval = 10,
    VerifyIncome = 11,
    ProvideDocuments = 12,
    ClientConsent = 13,
    PlentiToReviewApplication = 14,
    ProvideInvoiceAndStc = 15,
    EnsureLoanConditionsComplete = 16,
    VerifyIdentityAndIncome = 17,
    ConfirmInstallation = 18,
    SendContractSigningReminder = 19,
    ConfirmAGLVppConnection = 20,
    CompleteSettlement = 21
}

export const renenwableSettlementsV2Steps = [
    ReportApplicationNextStep.ProvideInvoiceAndStc,
    ReportApplicationNextStep.ConfirmInstallation,
    ReportApplicationNextStep.SendContractSigningReminder
];
