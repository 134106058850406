export class BroadcastChannelEvent {
    public eventType: string;
    public message: any;
    public raisedAt: number;

    constructor(eventType: string, message: any) {
        this.eventType = eventType;
        this.message = message;
        this.raisedAt = Date.now();
    }
}
