import { Injectable } from '@angular/core';

@Injectable()
export class KeypressService {
    public checkAnchorKeyPress($event: any, callback: () => any): void {
        if ($event.key === ' ' || $event.code === 'Space' || $event.key === 'Enter' || $event.code === 'Enter') {
            callback();
        }
    }
}
