export const DeclineReason = {
    NotSet: 'Credit policy',
    Age: 'Borrower age',
    BasicEmployment: 'Employment type',
    CitizenResidency: 'Residency status',
    Income: 'Income too low',
    Jurisdiction: 'Credit policy',
    SoftCreditCheck: 'Credit history',
    BureauConsistency: 'Length of credit history',
    EmploymentConsistency: 'Employment history',
    HardCreditCheck: 'Credit history',
    Identity: 'Customer identity',
    NonCompliantFees: 'Loan fees and charges',
    RSExperience: 'Account history',
    Serviceability: 'Serviceability',
    AccommodationExpensesVerification: 'Serviceability',
    BankConduct: 'Credit policy',
    Conduct: 'Credit history',
    CreditAppetite: 'Existing debt',
    DebtCollection: 'Existing debt',
    EmploymentVerification: 'Income not verified',
    Fraud: 'Credit policy',
    Gambling: 'Credit policy',
    GLEAgainstBankStatementVerification: 'Serviceability',
    IncomeVerification: 'Income not verified',
    LargeCashTransactions: 'Credit policy',
    LiabilityVerification: 'Existing debt',
    LoanAmount: 'Credit policy',
    TotalExposureSafetyNet: 'Debt-to-income'
};

export const DeclineReasonExplanation = {
    NotSet: 'Borrower characteristics outside credit policy',
    Age: 'Borrower age outside credit policy',
    BasicEmployment: 'Employment type is not eligible under credit policy',
    CitizenResidency: 'Borrower residency status does not meet credit policy',
    Income: 'Income is less than credit policy minimum ($25,000)',
    Jurisdiction: 'Borrower characteristics outside credit policy',
    SoftCreditCheck: 'Credit file does not meet credit policy',
    BureauConsistency: 'Age of credit file is below credit policy requirements',
    EmploymentConsistency: 'Length of employment does not meet credit policy requirements',
    HardCreditCheck: 'Credit file does not meet credit policy',
    Identity: 'Borrower identity could not be satisfactorily verified',
    NonCompliantFees: 'Proposed loan fees and charges are in excess of statutory limits',
    RSExperience: 'Conduct on existing account is unsatisfactory',
    Serviceability: 'Borrower does not meet credit policy servicability requirements',
    AccommodationExpensesVerification: 'Borrower does not meet credit policy servicability requirements',
    BankConduct: 'Borrower characteristics outside credit policy',
    Conduct: 'Credit file does not meet credit policy',
    CreditAppetite: "Borrower's existing debts are in excess of credit policy limits",
    DebtCollection: "Borrower's existing debts are in excess of credit policy limits",
    EmploymentVerification: "Borrower's stated income unable to be verified",
    Fraud: 'Borrower characteristics outside credit policy',
    Gambling: 'Borrower characteristics outside credit policy',
    GLEAgainstBankStatementVerification: 'Borrower does not meet credit policy servicability requirements',
    IncomeVerification: 'Borrowers stated income unable to be verified',
    LargeCashTransactions: 'Borrower characteristics outside credit policy',
    LiabilityVerification: "Borrower's existing debts are in excess of credit policy limits",
    LoanAmount: 'Borrower characteristics outside credit policy',
    TotalExposureSafetyNet: "Borrower's total unsecured debts are too high relative to income"
};

export const CustomDeclineReason = {
    NotSet: 'Credit policy',
    CreditScoreTooLow: 'Credit score',
    Affordability: 'Serviceability',
    OverLeverage: 'Credit policy',
    IncomeLevel: 'Income too low',
    CreditHistory: 'Credit history',
    Identification: 'Customer identity',
    RecentChangeInCircumstances: 'Credit policy',
    FinancialAssociate: 'Credit policy',
    Uncertainty: 'Credit policy',
    LoanRequestTooLargeForMarket: 'Loan size',
    ThinFile: 'Credit history',
    FalseApplication: 'Credit policy',
    Payday_Loans: 'Credit policy',
    Jurisdication: 'Credit policy',
    RecentCreditChange: 'Credit policy',
    NewToCountry: 'Credit policy',
    OutsidePolicy: 'Credit policy',
    LoanPurpose: 'Credit policy',
    BrokerDeclined: 'Credit policy'
};

export const CustomDeclineReasonExplanation = {
    NotSet: 'Borrower characteristics outside credit policy',
    CreditScoreTooLow: 'Borrower credit score is less than credit policy minimum',
    Affordability: 'Borrower does not meet credit policy servicability requirements',
    OverLeverage: 'Borrower characteristics outside credit policy',
    IncomeLevel: 'Borrower income is insufficient to meet serviceability requirements',
    CreditHistory: 'Credit file does not meet credit policy',
    Identification: 'Borrower identity could not be satisfactorily verified',
    RecentChangeInCircumstances: 'Borrower characteristics outside credit policy',
    FinancialAssociate: 'Borrower characteristics outside credit policy',
    Uncertainty: 'Borrower characteristics outside credit policy',
    LoanRequestTooLargeForMarket: 'Requested loan is larger than permitted by credit policy',
    ThinFile: 'Credit file does not meet credit policy',
    FalseApplication: 'Borrower characteristics outside credit policy',
    Payday_Loans: 'Borrower characteristics outside credit policy',
    Jurisdication: 'Borrower characteristics outside credit policy',
    RecentCreditChange: 'Borrower characteristics outside credit policy',
    NewToCountry: 'Borrower characteristics outside credit policy',
    OutsidePolicy: 'Borrower characteristics outside credit policy',
    LoanPurpose: 'Borrower characteristics outside credit policy',
    BrokerDeclined: 'Borrower characteristics outside credit policy'
};
