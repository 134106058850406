import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';

export class BankValidationModel {
    valid: boolean;
    bankName: string;
}

@Injectable({
    providedIn: 'root'
})
export class BsbValidator {
    constructor(private httpClient: HttpClient) {
        this.validateBsb = this.validateBsb.bind(this);
    }

    validateBsb(control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
        if (control.value) {
            return this.httpClient
                .post<BankValidationModel>(`${environment.api.baseUrl}/bankDetails/validateBsb/${control.value}`, {})
                .pipe(
                    take(1),
                    map(({ valid }) => {
                        if (valid === false) {
                            return { isBSBValid: false };
                        }
                        return null;
                    }),
                    catchError(err => {
                        return of({ isBSBValid: false });
                    })
                );
        }
    }
}
