import { Injectable } from '@angular/core';
import { FullStateNames } from 'app/constants';
import { Scheme } from '../models';

@Injectable()
export class AddressValidationService {
    getReadableStateList(includedStateAbbreviations: string[]): string {
        const includedFullStateNames = [];
        includedStateAbbreviations.forEach(abbrev => includedFullStateNames.push(FullStateNames[abbrev]));

        if (includedFullStateNames.length === 1) {
            return includedFullStateNames[0];
        }

        const lastState = includedFullStateNames.pop();
        let list = includedFullStateNames.join(', ');
        list += ` or ${lastState}`;
        return list;
    }

    getAllowedStates(scheme: Scheme) {
        if (scheme === Scheme.NewSouthWales) {
            return ['NSW'];
        }
        if (scheme === Scheme.SouthAustralia) {
            return ['SA'];
        }
        return [];
    }
}
