import { QuoteCalculationModel, Scheme, InstallationType } from './quote.model';
import { Decimal } from 'decimal.js';

export interface QuoteCalculationRequestModel {
    scope: Scheme;
    installationType: InstallationType;
    installationPrice: number;
    packagePrice: number;
    solarPanelPrice: number;
    price_HEMSDevice: number;
    price_HeatPumpWaterHeater: number;
    price_EVCharger: number;
    price_InductionCooktop: number;
    price_SmartMeter: number;
    price_SplitSystemAirConditioner: number;
    estimatedSubsidy: number;
    customerDeposit: number;
    isGstInc: boolean;
    noInterestBearingLoan: boolean;
    useExtraRequiredDeposit: number;
    useInterestFreeLoanThreshold: number;
}

export class QuoteCalculator {
    minimumLoanAmount = 1000;

    constructor(private request: QuoteCalculationRequestModel) {}

    calculate(): QuoteCalculationModel {
        const total = this.calculateTotal();
        const totalGstExclusive = this.calcTotalGstExclusive(total);
        const totalGstInclusive = this.calcTotalGstInclusive(total);

        const packageTotalIncGstLessSubsidy = this.calculateTotalLessSubsidy(totalGstInclusive);
        const balancePayable = this.calculateBalancePayable(totalGstInclusive);
        const financeAmount = Math.max(Math.ceil(balancePayable), 0);
        const interestFreeLoanCap = Number.MAX_SAFE_INTEGER;
        const balancePayableInterestFree = Math.min(interestFreeLoanCap, balancePayable);
        const balancePayableExcess = balancePayable - balancePayableInterestFree;
        const balancePayableExcessAboveLoanThreshold = Math.max(balancePayableExcess - this.minimumLoanAmount, 0);
        const balancePayableInterestBearing = this.calculateBalancePayableInterestBearing(balancePayableExcess);
        const additionalRequiredDeposit = this.calculateAdditionalRequiredDeposit(
            balancePayableInterestBearing,
            balancePayableExcess
        );

        return {
            financeAmount: financeAmount,
            balancePayable: balancePayable - additionalRequiredDeposit,
            totalGstExclusive: totalGstExclusive,
            totalGstInclusive: totalGstInclusive,
            estimatedSubsidy: this.request.estimatedSubsidy,
            balancePayableInterestFree: balancePayableInterestFree,
            balancePayableInterestBearing: balancePayableInterestBearing,
            balancePayableExcess: balancePayableExcess,
            additionalRequiredDeposit: additionalRequiredDeposit,
            interestFreeLoanCap: interestFreeLoanCap,
            totalCustomerDeposit: this.request.customerDeposit + additionalRequiredDeposit,
            minimumLoanAmount: this.minimumLoanAmount,
            balancePayableExcessAboveLoanThreshold: balancePayableExcessAboveLoanThreshold,
            packageTotalIncGstLessSubsidy: packageTotalIncGstLessSubsidy
        };
    }

    private calculateBalancePayable(totalGstInclusive: number) {
        return Math.max(totalGstInclusive - this.request.estimatedSubsidy, 0) - this.request.customerDeposit;
    }

    private calculateTotalLessSubsidy(totalGstInclusive: number) {
        return totalGstInclusive - this.request.estimatedSubsidy;
    }

    private calculateTotal() {
        var total = 0;
        for (var type of this.request.installationType) {
            switch (type) {
                case InstallationType.Battery:
                    total += this.request.packagePrice;
                    break;
                case InstallationType.Solar:
                    total += this.request.solarPanelPrice;
                    break;
                case InstallationType.HEMSDevice:
                    total += this.request.price_HEMSDevice;
                    break;
                case InstallationType.HeatPumpWaterHeater:
                    total += this.request.price_HeatPumpWaterHeater;
                    break;
                case InstallationType.EVCharger:
                    total += this.request.price_EVCharger;
                    break;
                case InstallationType.InductionCooktop:
                    total += this.request.price_InductionCooktop;
                    break;
                case InstallationType.SmartMeter:
                    total += this.request.price_SmartMeter;
                    break;
                case InstallationType.SplitSystemAirConditioner:
                    total += this.request.price_SplitSystemAirConditioner;
                    break;
            }
        }
        return total + this.request.installationPrice;
    }

    private calcTotalGstExclusive(total: number): number {
        return this.request.isGstInc ? new Decimal(total).div(1.1).toNumber() : total;
    }

    private calcTotalGstInclusive(total: number): number {
        return this.request.isGstInc ? total : new Decimal(total).mul(1.1).toNumber();
    }

    private calculateBalancePayableInterestBearing(balancePayableExcess: number): number {
        if (this.request.noInterestBearingLoan || this.request.useExtraRequiredDeposit > 0) {
            return 0;
        }

        if (balancePayableExcess < this.minimumLoanAmount) {
            return 0;
        }

        return balancePayableExcess;
    }

    private calculateAdditionalRequiredDeposit(
        balancePayableInterestBearing: number,
        balancePayableExcess: number
    ): number {
        if (this.request.useExtraRequiredDeposit) {
            return this.request.useExtraRequiredDeposit;
        }

        if (balancePayableInterestBearing > 0 || balancePayableExcess === 0) {
            return 0;
        }
        return balancePayableExcess;
    }
}
