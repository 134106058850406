<div class="user-button-container" #userButton (msClickOutside)="onClickOutside()">
  <button class="user-button" mat-button (click)="toggleDropdown()" [class.open]="isOpen">
    <span fxLayout="row" fxLayoutAlign="start center">
      <img class="avatar" src="/assets/img/avatars/noavatar.png">
      <span class="name" fxHide fxShow.gt-sm>{{displayName}}</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>
  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <div class="item" fxLayout="row" fxLayoutAlign="start center" mat-ripple [routerLink]="['/settings']">
          <mat-icon class="icon">build</mat-icon>
          <span class="title">Settings</span>
        </div>
        <mat-divider></mat-divider>
        <div class="item" fxLayout="row" fxLayoutAlign="start center" mat-ripple (click)="handleLogout()">
          <mat-icon class="icon">exit_to_app</mat-icon>
          <span class="title">Logout</span>
        </div>
      </div>
    </div>
  </div>
</div>
