<div class="main-toolbar mat-elevation-z2" fxFlex fxLayout="row">
  <div fxLayout="row" fxLayoutAlign="start center" fxHide="false" fxHide.gt-sm>
    <button (click)="sidenav.open()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="auto" fxHide fxShow.gt-md>
    <bp-search-bar></bp-search-bar>
  </div>
  <span fxFlex></span>
  <button id="news-and-updates-beamer" mat-button>
    News and updates
    <mat-icon>notifications</mat-icon>
  </button>

  <div class="user-button-container">
    <bp-toolbar-user-button></bp-toolbar-user-button>
  </div>
</div>
