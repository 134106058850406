import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'environments/environment';

@Directive({ selector: '[featureFlag]' })
export class FeatureFlagDirective {
    private hasView = false;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

    @Input() set featureFlag(featureFlag: string) {
        if (environment.features[featureFlag] === undefined) {
            console.log(`[FeatureFlag]: feature \'${featureFlag}\' is not defined!`);
        }

        // Hide if explicitly disabled, or if it is undefined (for safety!)
        if (!featureFlag || !environment.features[featureFlag] === true) {
            this.viewContainer.clear();
            this.hasView = false;
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        }
    }
}
