import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, iif, of } from 'rxjs';
import { AuthenticationService } from '@brokerportal/common/auth';
import { map, switchMap } from 'rxjs/operators';
import { FeatureFlagService } from '@brokerportal/common/services';
import { BrokerPortalFeatureCodes } from '@brokerportal/common/enums';
import { ExchangeAuthComponent } from '@brokerportal/modules/authentication/exchange-auth/exchange-auth.component';
import { LoginComponent } from '@brokerportal/modules/authentication/login/login.component';

@Injectable({ providedIn: 'root' })
export class LoginPageGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private featureFlagService: FeatureFlagService
    ) {}
    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.isAuthenticated().pipe(
            switchMap(isAuthenticated =>
                iif(
                    () => isAuthenticated,
                    of(this.router.createUrlTree(['/'], { queryParamsHandling: 'preserve' })),
                    this.featureFlagService.isEnabled(BrokerPortalFeatureCodes.EnableFusionAuth).pipe(
                        map(isFusionAuthEnabled => {
                            if (isFusionAuthEnabled && route.component !== ExchangeAuthComponent) {
                                return this.router.createUrlTree(['/authentication/login-auth'], {
                                    queryParamsHandling: 'preserve'
                                });
                            }
                            if (!isFusionAuthEnabled && route.component !== LoginComponent) {
                                return this.router.createUrlTree(['/authentication/login'], {
                                    queryParamsHandling: 'preserve'
                                });
                            }
                            return true;
                        })
                    )
                )
            )
        );
    }
}
