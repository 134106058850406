<h2 mat-dialog-title>
    {{data.title}}
</h2>
<mat-dialog-content>
    <p>
       {{data.action}}
    </p>

    <div>
      <button mat-raised-button color="primary" mat-dialog-close>OK</button>
    </div>
</mat-dialog-content>
  