<section>
    <h5>Battery System photographs</h5>
    <ul>
        <li>DT37 - Stand back photo of MSB (1m)</li>
        <li>DT38 - Close up of MSB din rail/escutcheon panel</li>
        <li>DT39 - Stand back photo of DB, if applicable</li>
        <li>DT40 - Close up of DB din rail/escutcheon panel, if applicable</li>
        <li>DT41 - 5m back of BESS enabling assessment of environmental conditions</li>
        <li>DT42 - Stand back of BESS (1m)</li>
        <li>DT43 - Close up of battery over current protection</li>
        <li>DT44 - Close up, showing Battery-inverter serial number/model number</li>
        <li>DT45 - Close up, showing battery serial number/model number</li>
        <li>DT46 - The mounting bracket before the battery is fixed onto the wall, if applicable</li>
        <li>DT47 - Wide shot of where cars may be parked and the location of the battery</li>
        <li>DT48 - DC cables between the battery and the over current protection</li>
    </ul>

    <ng-container *ngIf="isBatteryAndSolarSystem()">
        <h5>Solar Panel System photographs</h5>
        <ul>
            <li>DT49 - 5m back of separate Solar Inverter enabling assessment of environmental conditions</li>
            <li>DT50 - Stand back of Solar Inverter (1m)</li>
            <li>DT51 - Solar Inverter Data Plate</li>
            <li>DT52 - Close up of all PV DC isolators</li>
            <li>DT53 - The Array / Strings</li>
            <li>DT54 - The roof penetration type</li>
            <li>DT56 - The cables /conduit for the PV DC run from the ceiling to the inverter, if applicable</li>
            <li>DT57 - Photograph in the ceiling cavity looking at the roof penetration, if applicable</li>
            <li>DT58 - Photograph in the ceiling cavity from the roof penetration looking back to the conduit towards the inverter, if applicable</li>
            <li>DT59 - Equipotential Bonding conductor as it emerges from its conduit run particularly showing the sealing of the transition</li>
            <li>DT60 - Solar Panel Data Plate</li>
            <li>DT61 - 1 x Solar Panel Serial Number</li>
        </ul>
    </ng-container>

    <ng-container *ngIf="quoteFormModel.inverterPackage">
        <h5>Inverter photographs</h5>
        <ul>
            <li>Close-up, showing inverter serial number/Model number</li>
            <li>In context, showing inverter location on the home’s wall showing at least 1m of space on each side so that clearances can be assessed</li>
        </ul>    
    </ng-container>
</section>