import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AsyncConfig } from '@brokerportal/modules/quote/shared/models';

@Injectable()
export class ErrorCallbackGeneratorService {
    constructor() {}

    generate(
        callDescription: string,
        targetSnackBar: MatSnackBar,
        callerConfig?: AsyncConfig,
        arrayErrorCallback?: (err: any) => any
    ): (any) => void {
        return error => {
            if (arrayErrorCallback && Array.isArray(error)) {
                this.setLoaded(callerConfig);
                arrayErrorCallback(error);
                return;
            }

            error = this.formStringFromErrorObject(error);
            targetSnackBar.open(`Error ${callDescription}: ${error}`, 'Dismiss');
            this.setLoaded(callerConfig);
        };
    }

    setLoaded(callerConfig) {
        if (callerConfig) {
            callerConfig.isLoading = false;
        }
    }

    private formStringFromErrorObject(error) {
        let singleError = '';
        if (Array.isArray(error)) {
            error.forEach((errorComponent, index) => (singleError += `[${index}]: ${errorComponent} `));
        } else if (error instanceof Object) {
            Object.keys(error).forEach(key => (singleError += `[${key}]: ${error[key]} `));
        }
        return singleError || error;
    }
}
