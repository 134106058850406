import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'bp-rt-row',
    template: `
        <div fxLayout fxLayout.xs="column wrap">
            <div fxFlex="calc(50% - 25px)">
                <b>{{ title }}</b>
                <span *ngIf="toolTip" class="help" [matTooltip]="toolTip">?</span>
            </div>
            <div fxFlex="calc(50% - 25px)">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./responsive-table-row.component.scss']
})
export class ResponsiveTableRowComponent implements OnInit {
    @Input()
    title: String;

    @Input()
    toolTip: String | null;

    constructor() {}

    ngOnInit() {}
}
