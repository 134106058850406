import {
    AfterContentChecked,
    AfterViewChecked,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaReplayService, PortalConfigurationService, WorkflowManifestService } from '@brokerportal/common/services';
import { SubNavigationService } from '@brokerportal/modules/admin/services';
import { environment } from 'environments/environment';
import { Subject, Subscription } from 'rxjs';
import {
    AdditionalInstallationType,
    AsyncConfig,
    BuilderModel,
    HomeOwnershipOrInterest,
    homeOwnershipOrInterestLabels,
    InstallationType,
    InstallationTypeV1,
    PackageCategory,
    PackagesModel,
    PackageWithCategoryModel,
    QuoteCalculationModel,
    QuoteCalculationRequestModel,
    QuoteCalculator,
    QuoteCreateStepper,
    QuoteFormCrudMode,
    QuoteFormModel,
    QuoteRetrievedModel,
    QuoteReviewModel,
    Scheme
} from '../../models';
import {
    AddressValidationService,
    ErrorCallbackGeneratorService,
    QuoteService,
    QuoteStatusService,
    SubsidyPriceService
} from '../../services';
import { TemplateText } from '../../template-text';
import {
    acceptedValuesValidator,
    childrenRequiredValidator,
    conditionallyRequired,
    conditionallyRequiredTrue,
    dependentNonNegativeValidator,
    requiredWhen,
    requiredWhenDependentPositive,
    unacceptedValuesValidator,
    minimumNumberValidator
} from '../../validators';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog.component';
import { QuoteRenewalConfirmDialog } from '../confirm-dialog/confirm-dialog.quote.renewal.component';
import { FeedInDisclaimerDialog } from '../disclaimer/feed-in-disclaimer-dialog.component';
import { SubsidyPriceStep } from './../../models/subsidy-price.model';
import * as converter from '@brokerportal/common/utils/convertor';
import { debounceTime } from 'rxjs/operators';
import { AuthenticationService } from '@brokerportal/common/auth';
import { VenusDemandMapping } from '@brokerportal/common/auth/demands.const';
import { ManifestConfigurationContext, ProductTag, ManifestControlTarget } from '@brokerportal/common/enums';
import { ToExperience } from '@brokerportal/common/enums/product-tag.enum';
import { AssignableBillingOwners } from '@brokerportal/common/enums/billing-owner.enum';

@Component({
    selector: 'quoteV1-form',
    templateUrl: './quoteV1-form.component.html',
    styleUrls: ['./quote-form.component.scss']
})
export class QuoteV1FormComponent implements OnInit, AfterContentChecked, AfterViewChecked, OnDestroy {
    @Input()
    initialCrudMode: QuoteFormCrudMode;

    @Input()
    parentCrudMode: QuoteFormCrudMode;

    @Input()
    quoteExternalId: string;

    @Input()
    quoteNumber: string;

    @Input()
    quoteStatus: string;

    @Input()
    applicationGuid: string;

    @Input()
    quoteScope: string;

    @Input()
    isEaas: boolean;

    @Input()
    defaultVpp: string;

    @Input()
    billingOwner: string;

    quoteCalculationModel: QuoteCalculationModel = {
        totalGstExclusive: 0,
        totalGstInclusive: 0,
        estimatedSubsidy: 0,
        balancePayable: 0,
        financeAmount: 0,
        balancePayableInterestFree: 0,
        balancePayableInterestBearing: 0,
        additionalRequiredDeposit: 0,
        interestFreeLoanCap: 0,
        totalCustomerDeposit: 0,
        minimumLoanAmount: 0,
        balancePayableExcess: 0,
        balancePayableExcessAboveLoanThreshold: 0,
        packageTotalIncGstLessSubsidy: 0
    };

    private _quoteFormModel: QuoteFormModel;
    @Input()
    set quoteFormModel(value: QuoteFormModel) {
        this._quoteFormModel = value;
        if (this._quoteFormModel) {
            this.quoteForm.patchValue(this._quoteFormModel);
            this.quoteScope = this._quoteFormModel.scope;
        }
    }
    get quoteFormModel(): QuoteFormModel {
        return this._quoteFormModel;
    }

    @Input()
    externalSubmitErrors: string[];

    @Output()
    onFormSubmitted = new EventEmitter<QuoteReviewModel>();
    @Output()
    onToConfirmInstallation = new EventEmitter<any>();
    @Output()
    onCancelled = new EventEmitter<any>();
    @Output()
    onPackageChanged = new EventEmitter<PackageWithCategoryModel>();

    schemeCoverage: string[] = [];
    txt: TemplateText;
    selectedBuilder: BuilderModel;
    viewEditButtonText: string;
    hasErrorsToDisplay: boolean;
    hasErrorsOnSubmit: boolean;
    asyncConfig: AsyncConfig;
    currentCrudMode: QuoteFormCrudMode;
    // I know it's ridiculous to have this but it's to keep the current mode (Edit) after doc upload dialog pops up
    currentCrudModeBackUp: QuoteFormCrudMode;

    allDocumentsProvided = false;
    approvedBuilders: BuilderModel[] = [];

    selectedPackages: PackagesModel = {
        battery: null,
        solar: null,
        inverter: null,
        dcIsolator: null
    };

    propertyOwnershipStatuses = [
        { name: 'Has ownership or ownership interest', value: true },
        { name: 'No ownership, no ownership interest', value: false }
    ];

    installationTypes = [
        { name: 'Battery system only', value: InstallationTypeV1.BatteryOnly },
        { name: 'Battery and solar system', value: InstallationTypeV1.BatteryAndSolar }
    ];

    panelInstallationTypes = [
        { name: 'Replacement of existing solar system', value: AdditionalInstallationType.Replacement },
        { name: 'Extension of existing solar system', value: AdditionalInstallationType.Extension }
    ];

    batteryInstallationTypes = [
        { name: 'Replacement of existing battery system', value: AdditionalInstallationType.Replacement },
        { name: 'Extension of existing battery system', value: AdditionalInstallationType.Extension }
    ];

    homeOwnershipOrInterestStatuses = [
        { name: homeOwnershipOrInterestLabels[HomeOwnershipOrInterest.Direct], value: HomeOwnershipOrInterest.Direct },
        {
            name: homeOwnershipOrInterestLabels[HomeOwnershipOrInterest.Indirect],
            value: HomeOwnershipOrInterest.Indirect
        },
        {
            name: homeOwnershipOrInterestLabels[HomeOwnershipOrInterest.NoOwnership],
            value: HomeOwnershipOrInterest.NoOwnership
        }
    ];

    nmiPatterns = {
        [Scheme.SouthAustralia]: /^200[12][0-9]{6}([0-9]{1}|[A-Ha-hJ-Nj-nP-Zp-z1-9]{2})?$/,
        [Scheme.NewSouthWales]: /^4((5080[0-9]{5})|((001|102|103|104|204|407)[0-9]{6})|(31[0-9]{7})|(60810[0-8][0-9]{3}))([0-9]{1}|[A-Ha-hJ-Nj-nP-Zp-z1-9]{2})?$/
    };

    maxValidityMonths = 6;
    currencyPattern = /^[0-9]+(\.[0-9][0-9]?)?$/;
    decimalPattern = /^[0-9]{1,5}(\.[0-9][0-9]?)?$/;
    namePattern = /^[ '&a-zA-Z\-]{2,30}$/;
    // See https://www.aemo.com.au/-/media/Files/Electricity/NEM/Retail_and_Metering/Metering-Procedures/2018/MSATS-National-Metering-Identifier-Procedure.pdf
    postCodeRegex = /^[0-9]{4}$/;
    confirmResponse = 'confirm';
    allowedStates = [];
    subsidyRate: SubsidyPriceStep;
    readonly converter = converter;

    timestamp = new Date();
    today: Date;
    maxValidityDate: Date;
    isMobile: boolean;
    minimumLoanAmountNsw = 2001;
    manifestControlTargets: Set<string> = new Set<string>();

    private readonly approvedVpps: string[] = ['Tesla', 'EnergyAustralia'];

    quoteForm = new FormGroup({
        scope: new FormControl(''),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        mobile: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
        energyConcessionRecipient: new FormControl(false, []),
        statutoryPremiumFeedInMember: new FormControl(false, []),
        installationAddress: new FormGroup(
            {
                unitNumber: new FormControl('', []),
                streetNumber: new FormControl('', [Validators.required]),
                street: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
                suburb: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)]),
                postCode: new FormControl('', [
                    Validators.required,
                    Validators.pattern(this.postCodeRegex),
                    acceptedValuesValidator(this.schemeCoverage, true)
                ]),
                state: new FormControl('', [Validators.required, acceptedValuesValidator(this.allowedStates, true)])
            },
            childrenRequiredValidator(['streetNumber', 'street', 'suburb', 'postCode', 'state'])
        ),
        nmi: new FormControl('', [conditionallyRequired(['isNewBuild', 'alternateNetwork'], [false, false])]),
        meterIdentificationNumber: new FormControl('', [
            conditionallyRequired(['isNewBuild', 'alternateNetwork'], [false, true]),
            Validators.pattern('^[0-9]{1,50}$')
        ]),
        installationAddressIsPredominantResidential: new FormControl(false, [Validators.requiredTrue]),
        alternateNetwork: new FormControl(false, []),
        isCustomerJoiningVpp: new FormControl(false),
        vppOperator: new FormControl(null, [conditionallyRequired(['isCustomerJoiningVpp'], [true])]),
        hasHomeOwnershipOrInterest: new FormControl('', conditionallyRequired(['scope'], [Scheme.SouthAustralia])),
        confirmOwnerInstallationConsent: new FormControl(false, [
            conditionallyRequiredTrue('hasHomeOwnershipOrInterest', false)
        ]),
        batteryPackage: new FormControl('', Validators.required),
        solarPackage: new FormControl(
            null,
            conditionallyRequired(['installationType', 'scope'], ['BatteryAndSolarSystem', Scheme.NewSouthWales])
        ),
        inverterPackage: new FormControl(null, []),
        dcIsolatorPackage: new FormControl(
            null,
            conditionallyRequired(['installationType', 'scope'], ['BatteryAndSolarSystem', Scheme.NewSouthWales])
        ),
        approvedBuilder: new FormControl('', [conditionallyRequiredTrue('isNewBuild', true)]),

        existingSolarPanels: new FormControl(false, [
            conditionallyRequired(['installationType', 'scope'], ['BatteryOnly', Scheme.NewSouthWales])
        ]),
        existingBattery: new FormControl(false, []),
        intendedInstallationDate: new FormControl('', [Validators.required]),
        packagePrice: new FormControl('', [Validators.required, Validators.pattern(this.currencyPattern)]),
        installationPrice: new FormControl('', [Validators.required, Validators.pattern(this.currencyPattern)]),
        solarPanelPrice: new FormControl('', [
            Validators.pattern(this.currencyPattern),
            conditionallyRequired(['installationType'], ['BatteryAndSolarSystem'])
        ]),
        customerDeposit: new FormControl('', [Validators.required, Validators.pattern(this.currencyPattern)]),
        quoteValidity: new FormControl(''),
        fairPricingConfirmation: new FormControl(false, [Validators.requiredTrue]),
        isPriceGstInc: new FormControl(true),
        isNewBuild: new FormControl(false, [Validators.required]),
        newBuildConfirmation: new FormControl(false, [conditionallyRequiredTrue('isNewBuild', true)]),
        notApprovedForLowIncomeHouseholdsConfirmation: new FormControl(false, [
            conditionallyRequiredTrue('scope', Scheme.NewSouthWales)
        ]),
        householdIncomeConfirmation: new FormControl(false, [conditionallyRequiredTrue('scope', Scheme.NewSouthWales)]),
        installationType: new FormControl('', [
            conditionallyRequiredTrue('scope', Scheme.NewSouthWales),
            requiredWhen(() => this.quoteScope === Scheme.SouthAustralia && this.isCrudCreateMode())
        ]),
        newSolarPanelInstallationType: new FormControl('', [
            conditionallyRequired(
                ['existingSolarPanels', 'installationType', 'scope'],
                [true, 'BatteryAndSolarSystem', Scheme.NewSouthWales]
            )
        ]),
        newBatteryInstallationType: new FormControl('', [
            conditionallyRequired(['existingBattery', 'scope'], [true, Scheme.NewSouthWales])
        ]),
        homeOwnershipOrInterestStatus: new FormControl('', [
            conditionallyRequiredTrue('scope', Scheme.NewSouthWales),
            unacceptedValuesValidator([HomeOwnershipOrInterest.NoOwnership])
        ]),
        noInterestBearingLoan: new FormControl(null, [
            requiredWhenDependentPositive(this.quoteCalculationModel, 'balancePayableExcessAboveLoanThreshold')
        ]),
        previousExtraRequiredDeposit: new FormControl(),
        previousInterestFreeLoanThreshold: new FormControl(),
        newSolarPanelCapacity: new FormControl('', [
            conditionallyRequired(['installationType', 'scope'], ['BatteryAndSolarSystem', Scheme.NewSouthWales]),
            Validators.pattern(this.decimalPattern)
        ]),
        existingSolarPanelCapacity: new FormControl('', [
            conditionallyRequired(['existingSolarPanels', 'scope'], [true, Scheme.NewSouthWales]),
            Validators.pattern(this.decimalPattern)
        ]),
        existingBatteryCapacity: new FormControl('', [
            conditionallyRequired(['existingBattery', 'scope'], [true, Scheme.NewSouthWales]),
            Validators.pattern(this.decimalPattern)
        ]),
        estimatedSubsidy: new FormControl('', [
            dependentNonNegativeValidator(this.quoteCalculationModel, 'packageTotalIncGstLessSubsidy')
        ]),
        isEaas: new FormControl(''),
        providerCustomerId: new FormControl('', conditionallyRequiredTrue('isEaas', true)),
        providerInvoiceNumber: new FormControl(''),
        billingOwner: new FormControl('')
    });

    private _mediaSubscription: Subscription;
    submissionErrorSubject: Subject<void>;

    env = environment;
    actionPermissions = {
        canPatchQuote: false,
        canCancelQuote: false,
        canConfirmInstallation: false
    };
    providerName: string;

    subsidyCountMessage: string = null;

    get ManifestControlTarget() {
        return ManifestControlTarget;
    }

    constructor(
        private quoteService: QuoteService,
        public quoteStatusService: QuoteStatusService,
        private subNavigationService: SubNavigationService,
        private mediaReplayService: MediaReplayService,
        private errorCallback: ErrorCallbackGeneratorService,
        private feedInDisclaimerDialog: MatDialog,
        private cancelDialog: MatDialog,
        private snackBar: MatSnackBar,
        private addressValidation: AddressValidationService,
        private subsidyPriceService: SubsidyPriceService,
        private quoteRenewalConfirmDialog: MatDialog,
        private authService: AuthenticationService,
        private portalConfigService: PortalConfigurationService,
        private workflowManifestService: WorkflowManifestService
    ) {
        this.today = new Date(this.timestamp.getFullYear(), this.timestamp.getMonth(), this.timestamp.getDate());
        this.maxValidityDate = new Date(
            this.timestamp.getFullYear(),
            this.timestamp.getMonth() + this.maxValidityMonths,
            this.timestamp.getDate()
        );

        this.viewEditButtonText = 'Edit';
        this.asyncConfig = {
            isLoading: false
        };

        authService.hasDemand(VenusDemandMapping.CanCancelQuote).then(value => {
            this.actionPermissions.canCancelQuote = value;
        });
        authService.hasDemand(VenusDemandMapping.CanPatchQuote).then(value => {
            this.actionPermissions.canPatchQuote = value;
        });
        authService.hasDemand(VenusDemandMapping.CanConfirmInstallation).then(value => {
            this.actionPermissions.canConfirmInstallation = value;
        });
    }

    ngOnInit(): void {
        this.portalConfigService.get().subscribe(portalConfig => {
            this.providerName = portalConfig.partner?.name;
            let productTag = this.schemeToProductTag(this.quoteScope);
            let broker = portalConfig?.brokers?.find(broker => {
                return broker.productTag === productTag && broker.isDirect === true;
            });

            if (!broker) return;
            this.workflowManifestService
                .GetConfigurationsByBrokerIdAndExperience(broker.id, ToExperience(productTag))
                .subscribe(configurations => {
                    let brokerManifestConfig = configurations.find(
                        config => config.context === ManifestConfigurationContext.BorrowerApplication
                    );
                    // calling set constructor with null/undefined will just return a set with 0 elements.
                    this.manifestControlTargets = new Set(brokerManifestConfig?.brokerManifestControlTargets);
                });
        });

        this.isEaas = this.isEaas ? this.isEaas : false; // cast isEaas to boolean
        this.submissionErrorSubject = new Subject<void>();

        this._mediaSubscription = this.mediaReplayService.isMobile$.subscribe(isMobile => (this.isMobile = isMobile));

        this.getSubsidy();

        this.setInitialFormValues();
        this.loadQuoteResources();
        if (this.isCrudCreateMode() && this.isSaScheme() && this.isSubsidyRateAnnounced()) {
            this.getCurrentSubsidyRate();
        }
        this.quoteForm.get('isCustomerJoiningVpp').valueChanges.subscribe((value: boolean) => {
            if (!value) {
                this.quoteForm.get('vppOperator').setValue('');
            }
        });

        this.quoteForm.get('installationType').valueChanges.subscribe(value => {
            if (value === InstallationTypeV1.BatteryOnly) {
                this.field('solarPanelPrice').setValue('0');
            }
        });
    }

    private getCurrentSubsidyRate() {
        this.subsidyPriceService
            .getStepsByScope(this.quoteScope)
            .pipe(debounceTime(5000))
            .subscribe(steps => {
                this.subsidyRate = steps
                    .sort((a, b) => a.priority - b.priority)
                    .find(step => step.remainingSubsidies > 0);

                this.subsidyCountMessage = this.generateSubsidyCountMessage(this.subsidyRate);
            });
    }

    generateSubsidyCountMessage(subsidyRate: SubsidyPriceStep) {
        if (!subsidyRate) return null;

        const { totalSubsidies, remainingSubsidies } = subsidyRate;
        const hideCount = totalSubsidies > 900000;
        if (hideCount) return null;

        let subsidyRemaining = `${remainingSubsidies}`;
        if (100 > remainingSubsidies) {
            subsidyRemaining = 'less than 100';
        }

        return `Subsidies remaining at this subsidy rate: ${subsidyRemaining}`;
    }

    setInitialFormValues() {
        this.hasErrorsOnSubmit = false;
        this.currentCrudMode = this.initialCrudMode;
        this.currentCrudModeBackUp = this.initialCrudMode;

        if (!this.field('scope').value) {
            this.field('scope').setValue(this.quoteScope);
        }

        if (!this.field('isEaas').value) {
            this.field('isEaas').setValue(this.isEaas);
        }

        if (this.shouldLoadDefaultVppAndBillingOwner()) {
            if (this.approvedVpps.includes(this.defaultVpp)) {
                this.field('isCustomerJoiningVpp').setValue(true);
                this.field('vppOperator').setValue(this.defaultVpp);
            }
            if (AssignableBillingOwners.includes(this.billingOwner)) {
                this.field('billingOwner').setValue(this.billingOwner);
            }
        }

        this.txt = new TemplateText(this.field('scope').value, this.isPriceGstInc());
        this.setDefaultQuoteValidity();
        this.setNmiValidation();
        this.setCustomerDepositValidation();

        if (this.allowedStates.length === 0) {
            this.allowedStates.push(...this.addressValidation.getAllowedStates(this.field('scope').value));
        }

        this.initialiseQuoteCalculation();
    }

    setNmiValidation() {
        const field = this.field('nmi');
        const scope = this.field('scope').value;
        field.setValidators([
            field.validator,
            Validators.pattern(this.nmiPatterns[scope]),
            requiredWhen(() => this.quoteStatusService.isConfirmable(this.quoteStatus))
        ]);
    }

    setCustomerDepositValidation() {
        const field = this.field('customerDeposit');
        const scope = this.field('scope').value;
        field.setValidators([
            field.validator,
            minimumNumberValidator(
                this.quoteCalculationModel,
                'balancePayableInterestFree',
                scope === Scheme.NewSouthWales ? this.minimumLoanAmountNsw : 0
            )
        ]);
    }

    setDefaultQuoteValidity() {
        if (!this.isNswScheme()) {
            return;
        }
        const nswDefaultQuoteValidityDays = 90;
        const validDate = new Date();
        validDate.setDate(validDate.getDate() + nswDefaultQuoteValidityDays);
        this.field('quoteValidity').setValue(validDate);
    }

    initialiseQuoteCalculation() {
        this.handleQuoteAmountChange(null, null, false);
    }

    loadQuoteResources() {
        if (this.isNswScheme()) {
            this.getSchemeCoverage();
        }
        if (this.isSaScheme()) {
            this.getApprovedBuilders();
        }

        if (this.quoteFormModel) {
            this.loadApprovedBuilder(this.quoteFormModel.approvedBuilder);
        }
    }

    ownershipStatusChanged(selectedValue: boolean) {
        this.field('confirmOwnerInstallationConsent').setValue(selectedValue);
    }

    homeOwnershipOrInterestStatusChanged(selectedValue: any) {
        this.field('homeOwnershipOrInterestStatus').setValue(selectedValue);
        const hasHomeOwnershipOrInterest =
            [HomeOwnershipOrInterest.Direct, HomeOwnershipOrInterest.Indirect].indexOf(selectedValue) >= 0;
        this.field('hasHomeOwnershipOrInterest').setValue(hasHomeOwnershipOrInterest);
    }

    handleQuoteAmountChange(newValue: string, updatedField: string, isManualChange: boolean = true) {
        const isViewMode = this.isCrudViewMode();

        const calculationRequestModel = {
            installationPrice: this.fieldNumeric('installationPrice'),
            packagePrice: this.fieldNumeric('packagePrice'),
            solarPanelPrice: this.fieldNumeric('solarPanelPrice'),
            price_HEMSDevice: 0,
            price_HeatPumpWaterHeater: 0,
            price_EVCharger: 0,
            price_InductionCooktop: 0,
            price_SmartMeter: 0,
            price_SplitSystemAirConditioner: 0,
            estimatedSubsidy: this.quoteCalculationModel.estimatedSubsidy,
            customerDeposit: this.fieldNumeric('customerDeposit'),
            scope: this.field('scope').value,
            installationType: this.field('installationType').value,
            isGstInc: this.isPriceGstInc(),
            noInterestBearingLoan: this.field('noInterestBearingLoan').value === false ? false : true,
            useInterestFreeLoanThreshold: isViewMode ? this.quoteFormModel.previousInterestFreeLoanThreshold : null,
            useExtraRequiredDeposit: isViewMode ? this.quoteFormModel.previousExtraRequiredDeposit : null
        };

        if (updatedField !== null) {
            calculationRequestModel[updatedField] = parseFloat(newValue) >= 0 ? parseFloat(newValue) : 0;
        }

        this.updateQuoteCalculation(calculationRequestModel, isManualChange);
    }

    derivedFinanceChangedFromLastQuote(): boolean {
        if (!this.quoteFormModel) {
            return false;
        }

        return (
            this.quoteFormModel.previousExtraRequiredDeposit !== this.quoteCalculationModel.additionalRequiredDeposit ||
            this.quoteFormModel.previousInterestFreeLoanThreshold !== this.quoteCalculationModel.interestFreeLoanCap
        );
    }

    updateQuoteCalculation(requestModel: QuoteCalculationRequestModel, isManualChange: boolean) {
        const newQuote = new QuoteCalculator(requestModel).calculate();
        Object.assign(this.quoteCalculationModel, newQuote);

        this.updateExtraPaymentTypeValueAndState();
        this.field('customerDeposit').updateValueAndValidity();
        this.field('noInterestBearingLoan').updateValueAndValidity();
        this.field('estimatedSubsidy').updateValueAndValidity();

        if (isManualChange || this.derivedFinanceChangedFromLastQuote()) {
            this.field('fairPricingConfirmation').setValue(false);
        }
    }

    private updateExtraPaymentTypeValueAndState() {
        if (!this.isEaas) return;

        const installationType = this.field('installationType').value as InstallationTypeV1;
        const noInterestBearingLoanCtrl = this.field<FormControl>('noInterestBearingLoan');
        if (
            (installationType === InstallationTypeV1.BatteryOnly &&
                this.quoteCalculationModel.totalGstInclusive > 9000) ||
            (installationType === InstallationTypeV1.BatteryAndSolar &&
                this.quoteCalculationModel.totalGstInclusive > 14000)
        ) {
            noInterestBearingLoanCtrl.setValue(true, { emitEvent: false });
            noInterestBearingLoanCtrl.disable();
        } else {
            noInterestBearingLoanCtrl.enable();
        }
    }

    ngAfterContentChecked(): void {
        this.currentCrudModeBackUp = this.parentCrudMode ? this.parentCrudMode : this.currentCrudModeBackUp;
        this.parentCrudMode = null;
        this.currentCrudMode = this.currentCrudModeBackUp;
    }

    ngAfterViewChecked(): void {
        this.ngAfterContentChecked();
    }

    field<T extends AbstractControl>(name) {
        return this.quoteForm.get(name) as T;
    }

    fieldNumeric(fieldName: string): number {
        return parseFloat(this.field(fieldName).value) || 0;
    }

    nonNegative(input: number) {
        return input > 0 ? input : 0;
    }

    headerTitle() {
        const nswSchemeName = this.isEaas ? 'NSW EHP EaaS' : 'NSW Empowering Homes';
        if (this.currentCrudMode === QuoteFormCrudMode.Create) {
            return `Create ${this.isNswScheme() ? nswSchemeName : 'a'} Quote Record`;
        } else if (this.currentCrudMode === QuoteFormCrudMode.View || this.currentCrudMode === QuoteFormCrudMode.Edit) {
            return `Edit ${this.isNswScheme() ? `${nswSchemeName} ` : ''}Quote Record`;
        }
    }

    isCrudCreateMode() {
        return this.currentCrudMode === QuoteFormCrudMode.Create;
    }

    isCrudViewMode() {
        return this.currentCrudMode === QuoteFormCrudMode.View;
    }

    isCrudEditMode() {
        return this.currentCrudMode === QuoteFormCrudMode.Edit;
    }

    isPriceGstInc() {
        return this.isCrudCreateMode() || (this.quoteFormModel && this.quoteFormModel.isPriceGstInc);
    }

    isExpiredQuote(): boolean {
        return this.quoteStatusService.isExpired(this.quoteStatus);
    }

    isNswScheme() {
        return this.field('scope').value === Scheme.NewSouthWales;
    }

    isSaScheme() {
        return this.field('scope').value === Scheme.SouthAustralia;
    }

    schemeToProductTag(scheme: string) {
        switch (scheme) {
            case Scheme.NewSouthWales:
                return ProductTag.NSWRenewableEnergy;
            case Scheme.SouthAustralia:
                return ProductTag.SARenewableEnergy;
            default:
                return ProductTag.Unknown;
        }
    }

    isSubsidyRateAnnounced() {
        return true;
    }

    isBatteryAndSolarSystem() {
        return this.field('installationType').value === InstallationTypeV1.BatteryAndSolar;
    }

    isInstallationTypeNotSet() {
        return this.field('installationType').value === InstallationTypeV1.NotSet;
    }

    isNswBatteryAndSolarSystem() {
        return this.isBatteryAndSolarSystem() && this.isNswScheme();
    }

    shouldDisplayPanelPrice() {
        return this.isBatteryAndSolarSystem() || this.isInstallationTypeNotSet();
    }

    hasInterestBearingPortion() {
        return this.quoteCalculationModel.balancePayableInterestBearing > 0;
    }

    shouldLoadDefaultVppAndBillingOwner() {
        return !!this.isEaas && this.isCrudCreateMode();
    }

    changeToEditMode() {
        if (this.isCrudViewMode() && !this.isNewBuildEditable()) {
            this.field('email').disable();
            this.field('mobile').disable();
            this.currentCrudMode = QuoteFormCrudMode.Edit;
            this.currentCrudModeBackUp = QuoteFormCrudMode.Edit;
        } else if (this.isCrudViewMode() && this.isNewBuildEditable()) {
            this.quoteForm.disable();
            this.field('nmi').enable();
            this.field('nmi').markAsTouched(); // Mark touched to trigger validation.
            this.currentCrudMode = QuoteFormCrudMode.Edit;
            this.currentCrudModeBackUp = QuoteFormCrudMode.Edit;
        }
    }

    hasExternalErrors(): boolean {
        return this.externalSubmitErrors && this.externalSubmitErrors.length > 0;
    }

    cancelEditMode() {
        this.asyncConfig.isLoading = true;
        if (this.quoteFormModel) {
            this.quoteForm.reset(this.quoteFormModel);
        }
        this.hasErrorsOnSubmit = false;
        this.currentCrudMode = QuoteFormCrudMode.View;
        this.currentCrudModeBackUp = QuoteFormCrudMode.View;
        if (this.isNswScheme()) {
            this.asyncConfig.isLoading = false;
        }
        this.getSubsidy();
    }

    isConfirmable() {
        return (
            this.allDocumentsProvided &&
            this.quoteStatusService.isConfirmable(this.quoteStatus) &&
            this.quoteFormModel &&
            (this.quoteFormModel.nmi ||
                (this.isSaScheme() &&
                    this.quoteFormModel.alternateNetwork &&
                    this.quoteFormModel.meterIdentificationNumber))
        );
    }

    updateDocumentAvailability(documentAvailability: boolean) {
        this.allDocumentsProvided = documentAvailability;
    }

    isNewBuildEditable() {
        return (
            this.quoteFormModel && this.quoteFormModel.isNewBuild && this.quoteStatus === 'SubsidyConditionallyApproved'
        );
    }

    confirmInstallation() {
        this.onToConfirmInstallation.next({});
    }

    cancelQuote() {
        this.cancelDialog
            .open(ConfirmDialog, {
                data: {
                    title: 'Cancel Quote Record',
                    action: 'Are you sure you want to cancel this Quote Record?',
                    defaultNo: true
                }
            })
            .afterClosed()
            .subscribe(result => {
                if (result === this.confirmResponse) {
                    this.asyncConfig.isLoading = true;
                    this.quoteService.cancelQuote(this.quoteExternalId).subscribe(
                        cancelResult => {
                            this.onCancelled.next();
                        },
                        this.errorCallback.generate('cancelling Quote Record', this.snackBar, this.asyncConfig),
                        () => (this.asyncConfig.isLoading = false)
                    );
                }
            });
    }

    touchForm() {
        this.field('installationAddressIsPredominantResidential').markAsTouched();
        this.field('fairPricingConfirmation').markAsTouched();
        this.field('installationAddress').markAsTouched();
        this.field('batteryPackage').markAsTouched();

        const installationAddress = <FormGroup>this.field('installationAddress');
        for (const control of Object.keys(installationAddress.controls)) {
            installationAddress.get(control).markAsTouched();
        }

        Object.keys(this.quoteForm.controls).forEach(field => {
            const control = this.quoteForm.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }

    onSubmit(model: QuoteFormModel) {
        this.externalSubmitErrors = [];
        this.hasErrorsOnSubmit = false;

        this.touchForm();

        if (this.quoteForm.valid) {
            this.setSelectedBuilder(model.approvedBuilder);
            this.onFormSubmitted.next({
                formModel: model,
                calculationModel: this.quoteCalculationModel,
                selectedPackages: this.selectedPackages,
                selectBuilder: this.selectedBuilder
            });
            if (this.isCrudEditMode()) {
                this.currentCrudMode = QuoteFormCrudMode.View;
                this.currentCrudModeBackUp = QuoteFormCrudMode.View;
            }
        } else {
            this.hasErrorsOnSubmit = true;
            this.submissionErrorSubject.next();
            this.navigate();
        }
    }

    navigate() {
        this.subNavigationService.signalNavigation(QuoteCreateStepper.Create);
    }

    loadApprovedBuilder(builderId: string) {
        if (!builderId) {
            return;
        }
        this.quoteService.getApprovedBuilder(builderId).subscribe(
            result => {
                this.selectedBuilder = result;
            },
            this.errorCallback.generate('getting approved builder', this.snackBar, this.asyncConfig),
            () => (this.asyncConfig.isLoading = false)
        );
    }

    getApprovedBuilders() {
        this.asyncConfig.isLoading = true;
        this.quoteService.getApprovedBuilders().subscribe(
            result => {
                this.approvedBuilders = result;
            },
            this.errorCallback.generate('getting approved builders', this.snackBar, this.asyncConfig),
            () => {
                this.asyncConfig.isLoading = false;
            }
        );
    }

    getSchemeCoverage() {
        this.asyncConfig.isLoading = true;
        this.quoteService.getSchemeCoverage(this.quoteScope).subscribe(
            result => {
                this.schemeCoverage.push(...result);
            },
            this.errorCallback.generate('getting eligible postcodes', this.snackBar, this.asyncConfig),
            () => {
                this.asyncConfig.isLoading = false;
            }
        );
    }

    getSubsidy() {
        if (!this.isSaScheme()) {
            return;
        }

        if (this.quoteExternalId && !this.isCrudEditMode()) {
            this.asyncConfig.isLoading = true;
            this.quoteService.getSubsidy(this.quoteExternalId).subscribe(
                result => {
                    this.asyncConfig.isLoading = false;
                    this.quoteCalculationModel.estimatedSubsidy = result;
                    this.handleQuoteAmountChange(result, 'estimatedSubsidy', false);
                },
                this.errorCallback.generate('finding subsidy amount', this.snackBar, this.asyncConfig),
                () => (this.asyncConfig.isLoading = false)
            );
        } else {
            this.getEstimatedSubsidy(this.field('batteryPackage').value);
        }
    }

    homeOwnershipFriendlyName(status: HomeOwnershipOrInterest) {
        return homeOwnershipOrInterestLabels[status];
    }

    packageTypeChange(model: PackageWithCategoryModel) {
        this.selectedPackages[model.category] = model.package;
        if (model.package && model.category === PackageCategory.Battery) {
            this.getEstimatedSubsidy(model.package.shortName);
        }
        this.onPackageChanged.next(model);
    }

    builderChange(selectedValue: string) {
        this.setSelectedBuilder(selectedValue);
    }

    setSelectedBuilder(selectedValue: string) {
        this.selectedBuilder = this.approvedBuilders.find(b => b.shortName === selectedValue);
    }

    onEnergyConcessionRecipientChange($event) {
        this.getEstimatedSubsidy(this.field('batteryPackage').value);
    }

    getEstimatedSubsidy(packageType: string) {
        if (!packageType || !this.isSaScheme()) {
            return;
        }
        if (this.quoteStatusService.showLockedInSubsidyAmount(this.quoteStatus)) {
            return;
        }
        this.asyncConfig.isLoading = true;
        this.quoteService
            .calculateSubsidy(packageType, {
                scope: Scheme.SouthAustralia,
                isEnergyConcessionHolder: this.field('energyConcessionRecipient').value
            })
            .subscribe(
                result => {
                    this.quoteCalculationModel.estimatedSubsidy = result;
                    this.handleQuoteAmountChange(result, 'estimatedSubsidy', false);
                },
                this.errorCallback.generate('calculating subsidy', this.snackBar, this.asyncConfig),
                () => (this.asyncConfig.isLoading = false)
            );
    }

    panelInstallationTypeRequired() {
        return this.isNswScheme() && this.field('existingSolarPanels').value === true && this.isBatteryAndSolarSystem();
    }

    batteryInstallationFieldsRequired() {
        return this.isNswScheme() && this.field('existingBattery').value === true;
    }

    showPropertyConsent() {
        return this.field('hasHomeOwnershipOrInterest').value === false;
    }

    hasExtraDeposit() {
        if (!this.quoteCalculationModel.balancePayableExcess) {
            return false;
        }
        return (
            this.field('noInterestBearingLoan').value ||
            this.quoteCalculationModel.balancePayableExcess < this.quoteCalculationModel.minimumLoanAmount
        );
    }

    newBuildSelected() {
        return this.field('isNewBuild').value === true;
    }

    openFeedInDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        this.feedInDisclaimerDialog.open(FeedInDisclaimerDialog);
    }

    openQuoteRenewalConfirmDialog(): void {
        this.quoteRenewalConfirmDialog.open(QuoteRenewalConfirmDialog, {
            data: {
                quoteExternalId: this.quoteExternalId,
                isMobile: this.isMobile,
                onRenewQuote: this.onRenewQuote
            }
        });
    }

    onRenewQuote = (newExpiryDate): void => {
        this.asyncConfig.isLoading = true;
        this.quoteService.renewQuote(this.quoteExternalId, newExpiryDate).subscribe(
            (result: QuoteRetrievedModel) => {
                this.quoteFormModel = result.quoteFormModel;
                this.quoteStatus = result.currentStatus;
                this.snackBar.open('Quote Record successfully renewed', 'Dismiss');
            },
            this.errorCallback.generate('renewing Quote Record', this.snackBar, this.asyncConfig),
            () => (this.asyncConfig.isLoading = false)
        );
    };

    ngOnDestroy() {
        if (this._mediaSubscription) {
            this._mediaSubscription.unsubscribe();
        }
    }

    isFeatureInstallerCanRenewQuoteRecordEnabled(): boolean {
        return environment.features.InstallerCanRenewQuoteRecord;
    }

    getValidationErrorMessageForViewMode() {
        if (this.quoteStatusService.isConfirmable(this.quoteStatus)) {
            if (
                this.allDocumentsProvided &&
                this.quoteFormModel &&
                this.quoteFormModel.isNewBuild &&
                !this.quoteFormModel.nmi
            ) {
                return 'Please provide NMI by editing this quote record to confirm installation';
            }
        }
        return '';
    }

    getInstallationTypeLabel(selectedInstallationType) {
        const filteredInstallationTypes = this.installationTypes.filter(it => it.value === selectedInstallationType);
        return filteredInstallationTypes.length > 0 ? filteredInstallationTypes[0].name : 'Unspecified';
    }
}
