import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'timezoneFormat' })
export class TimezoneFormat implements PipeTransform {
    transform(timeZone: string): string {
        timeZone = timeZone === 'local' ? DateTime.local().zoneName : timeZone;

        let dt = DateTime.local();

        if (timeZone !== 'local') {
            dt = dt.setZone(timeZone);
        }

        return dt.toFormat('ZZZZ');
    }
}
