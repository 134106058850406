import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { BrokerPortalFeatureCodes, ProductTag, RenewableSystemType } from '@brokerportal/common/enums';
import { FeatureFlagService } from '@brokerportal/common/services/feature-flag.service';
import { Subscription } from 'rxjs';
import { HEUF_SYSTEM_TYPES, STANDARD_SYSTEM_TYPES, SystemTypeOption } from './renewable-system-type-options';

export const BP_RENEWABLE_SYSTEM_TYPES_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RenewableSystemTypesComponent),
    multi: true
};

@Component({
    selector: 'bp-renewable-system-types',
    templateUrl: './renewable-system-types.component.html',
    styleUrls: ['./renewable-system-types.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [BP_RENEWABLE_SYSTEM_TYPES_VALUE_ACCESSOR],
    host: {
        class: 'bp-renewable-system-types',
        '[attr.aria-disabled]': 'disabled'
    }
})
export class RenewableSystemTypesComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @Input() name: string;
    @Input() productTag: ProductTag;
    @Input()
    public set value(value: string | null | undefined) {
        if (!this.viewInitialized) {
            this.initialValue = value;
        } else {
            this.setSelectionByValue(value);
            this.valueChange.emit(value);
        }
    }
    public get value() {
        return this.getValue();
    }
    @Input()
    public set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }
    public get disabled() {
        return this._disabled;
    }
    private _disabled = false;

    @Output('change') readonly valueChange = new EventEmitter<string>();
    @ViewChild('standardControl') private standardControl?: MatButtonToggleGroup;

    private touched = false;
    private initialValue: string | null | undefined;
    private viewInitialized = false;
    private featureFlagSubscription: Subscription;
    private useHEUFAssetsForGreen = false;
    private _options: ReadonlyArray<SystemTypeOption> = STANDARD_SYSTEM_TYPES;

    get rawValue() {
        return this.standardControl?.value as string[] | undefined;
    }

    get options(): ReadonlyArray<SystemTypeOption> {
        return this._options;
    }

    constructor(private cdr: ChangeDetectorRef, private featureFlagService: FeatureFlagService) {}

    ngOnInit() {
        this.featureFlagSubscription = this.featureFlagService
            .isEnabled(BrokerPortalFeatureCodes.HEUFAssetsForGreen)
            .subscribe(enabled => {
                this.useHEUFAssetsForGreen = enabled;
                this.refreshOptions(); // Refresh options after the value is updated
            });
    }

    ngAfterViewInit() {
        this.viewInitialized = true;
        if (this.initialValue) {
            this.setSelectionByValue(this.initialValue);
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy() {
        if (this.featureFlagSubscription) {
            this.featureFlagSubscription.unsubscribe();
        }
    }

    // Placeholder methods only. Will be replaced with actual methods when registered.
    private onChange = (_: any) => {};
    private onTouched = () => {};

    //#region ControlValueAccessor methods
    writeValue(obj: string | null | undefined) {
        this.value = obj;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    markAsTouched() {
        if (!this.touched) {
            this.touched = true;
            this.onTouched();
        }
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
    //#endregion ControlValueAccessor methods

    _emitValueChange() {
        this.onChange(this.value);
        this.valueChange.emit(this.value);
    }

    private refreshOptions() {
        if (this.useHEUFAssetsForGreen && this.productTag == ProductTag.GreenLoan) {
            this._options = [...STANDARD_SYSTEM_TYPES, ...HEUF_SYSTEM_TYPES];
            const currentValue = this.viewInitialized ? this.value : this.initialValue;
            const shouldEnableEms = new RegExp(`((${RenewableSystemType.EnergyMonitoringSystem})[,]?)+\\b`).test(
                currentValue
            );
            if (!shouldEnableEms) {
                this._options = this._options.filter(opt => opt.value !== RenewableSystemType.EnergyMonitoringSystem);
            }
        } else {
            this._options = STANDARD_SYSTEM_TYPES;
        }
    }

    private setSelectionByValue(value: string | null | undefined) {
        if (this.standardControl) {
            const values = (value || '').split(',');
            this.standardControl.value = values;
        }
    }

    private getValue(): string | undefined {
        return this.standardControl?.value?.join(',');
    }
}
