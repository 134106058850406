import { Injectable } from '@angular/core';

@Injectable()
export class QuoteStatusService {
    private quoteStatusesCancelable = [
        'Quoted',
        'SubsidyApplicationCommenced',
        'PendingIdentityVerification',
        'SubsidyConditionallyApproved',
        'FinanceConditionallyApproved'
    ];

    private quoteStatusesEditable = ['Quoted', 'SubsidyApplicationCommenced', 'PendingIdentityVerification'];

    private quoteStatusesConfirmable = ['SubsidyConditionallyApproved', 'FinanceConditionallyApproved', 'Accepted'];

    private isNotEditableReasons = {
        SubsidyDeclined: 'because the subsidy application has been declined',
        InstallationConfirmedByInstaller: 'because installation has been confirmed',
        InstallationConfirmedByCustomer: 'because installation has been confirmed',
        Cancelled: 'because it has been cancelled',
        Expired: 'because it has expired',
        Other: 'because of its status'
    };

    private quoteStatusesToShowLockedInSubsidyAmount = [
        'SubsidyConditionallyApproved',
        'InstallationConfirmedByInstaller',
        'InstallationConfirmedByCustomer',
        'SubsidyApproved'
    ];

    readonly validSAQuoteStatuses: ReadonlyArray<string> = [
        'Quoted',
        'SubsidyApplicationCommenced',
        'PendingIdentityVerification',
        'SubsidyConditionallyApproved',
        'InstallationConfirmedByInstaller',
        'InstallationConfirmedByCustomer',
        'SubsidyApproved',
        'SubsidyDeclined',
        'Cancelled',
        'Expired'
    ];

    readonly validNSWQuoteStatuses: ReadonlyArray<string> = [
        'Quoted',
        'FinanceApplied',
        'FinanceConditionallyApproved',
        'InstallationConfirmedByInstaller',
        'FinanceApproved',
        'InstallationConfirmedByCustomer',
        'FinanceDrawn',
        'SubsidyDeclined',
        'Cancelled',
        'Expired',
        'FinanceDeclined',
        'FinanceCancelled'
    ];

    private lockedDownStatusesForDocumentDeletion = ['SubsidyApproved', 'FinanceDrawn'];

    constructor() {}

    notEditableReasonText(quoteStatus: string) {
        if (this.quoteStatusesEditable.includes(quoteStatus)) {
            return null;
        }

        return this.isNotEditableReasons[quoteStatus] || this.isNotEditableReasons.Other;
    }

    isEditable(quoteStatus: string) {
        if (!quoteStatus) {
            return false;
        }
        return this.quoteStatusesEditable.includes(quoteStatus);
    }

    isConfirmable(quoteStatus: string) {
        if (!quoteStatus) {
            return false;
        }
        return this.quoteStatusesConfirmable.includes(quoteStatus);
    }

    isCancelable(quoteStatus: string) {
        if (!quoteStatus) {
            return false;
        }
        return this.quoteStatusesCancelable.includes(quoteStatus);
    }

    isExpired(quoteStatus: string): boolean {
        if (!quoteStatus) {
            return false;
        }
        return 'Expired' === quoteStatus;
    }

    showLockedInSubsidyAmount(quoteStatus: string): boolean {
        if (!quoteStatus) {
            return false;
        }
        return this.quoteStatusesToShowLockedInSubsidyAmount.includes(quoteStatus);
    }

    canDeleteDocument(quoteStatus: string): boolean {
        if (!quoteStatus) {
            return false;
        }
        return !this.lockedDownStatusesForDocumentDeletion.includes(quoteStatus);
    }
}
