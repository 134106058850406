import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { NgRadio } from 'ng-radio';
import { Subscription } from 'rxjs';
import { GLOBAL_CONSTANTS } from './constants';
import { PortalConfigurationService, TitleService } from './common/services';
import { AuthenticationService } from './common/auth';
import { ProductTag } from './common/enums';
import { CustomBroadcastChannel } from './common/services/custom-broadcast-channel';
declare var Harmony;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    private tokenRefresh$: Subscription;
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private radio: NgRadio,
        private portalConfigurationService: PortalConfigurationService,
        private authenticationService: AuthenticationService,
        private titleService: TitleService,
        private bc: CustomBroadcastChannel
    ) {}

    ngOnInit() {
        this.titleService.init();

        this.subscriptions.add(
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    if (window['Appcues']) {
                        // Essential to re-init AppCues on each route change
                        // Check the login component for how we set the user upon successful auth
                        window['Appcues'].start();
                    }
                }
            })
        );

        this.subscriptions.add(
            this.radio.on(GLOBAL_CONSTANTS.CHANNEL.USER.LOGIN).subscribe(result => {
                this.refreshToken();
                this.portalConfigurationService.get().subscribe(res => {
                    this.initBeamer(res);
                });
            })
        );

        this.subscriptions.add(
            this.radio.on(GLOBAL_CONSTANTS.CHANNEL.USER.LOGOUT).subscribe(result => {
                if (this.tokenRefresh$) {
                    this.tokenRefresh$.unsubscribe();
                }
            })
        );

        this.bc.subscribeEvent(GLOBAL_CONSTANTS.CHANNEL.USER.LOGOUT, ev => {
            window.location.href = '/authentication/logout';
        });
    }

    ngAfterViewInit() {
        if (!this.isBypassCallRefreshToken()) {
            this.authenticationService.isAuthenticated().subscribe(result => {
                if (result) {
                    this.refreshToken();
                    this.portalConfigurationService.get().subscribe(result => {
                        if (result.partner.isChatEnabled) {
                            this.initBeamer(result);
                        }
                    });
                }
            });
        }

        if (window['Harmony']) {
            window['Harmony'].init(environment.harmony.userName, environment.harmony.key, Harmony.AUSTRALIA);
        }
    }

    private isBypassCallRefreshToken = () => {
        if (!this.authenticationService.isUserLoginAsFusionAuth()) return false;

        if (
            window.location.pathname === '/authentication/logout' ||
            window.location.pathname === '/authentication/login-auth'
        )
            return true;

        return false;
    };

    private initBeamer(portalConfig) {
        if (!environment.useBeamer) {
            return;
        }

        if (window['beamer_config']) {
            return;
        }

        const beamerFilterString = this.getBeamerFilter(portalConfig.brokers);
        const userDetails = portalConfig.user
            ? {
                  user_email: portalConfig.user.userName,
                  user_firstname: portalConfig.user.firstName,
                  user_lastname: portalConfig.user.lastName
              }
            : {};

        window['beamer_config'] = {
            product_id: 'HHHRCSyv12730',
            selector: 'news-and-updates-beamer',
            button: false,
            filter: beamerFilterString,
            top: 8,
            right: 6,
            ...userDetails
        };
    }

    private getBeamerFilter(brokers) {
        const filterDict = {
            [ProductTag.Unknown]: 'unknown',
            [ProductTag.PersonalLoan]: 'personal',
            [ProductTag.GreenLoan]: 'green',
            [ProductTag.RenewableBrokerLoan]: 'renewableBroker',
            [ProductTag.BusinessFinance]: 'business',
            [ProductTag.CarLoan]: 'auto',
            [ProductTag.PointOfSale]: 'pointOfSale',
            [ProductTag.DeferredPaymentAgreement]: 'paymentAgreement',
            [ProductTag.SwiftWorkingCapital]: 'swiftWorkingCapital',
            [ProductTag.LegalLoan]: 'legal',
            [ProductTag.SARenewableEnergy]: 'saRenewableEnergy',
            [ProductTag.NSWRenewableEnergy]: 'nswRenewableEnergy',
            [ProductTag.RenewableInterestFree]: 'renewableInterestFree'
        };

        const filterArray = ['general'];

        if (brokers) {
            brokers.forEach(broker => {
                if (filterDict[broker.productTag]) {
                    filterArray.push(filterDict[broker.productTag]);
                }
            });
        }

        return filterArray.join(';');
    }

    private refreshToken() {
        if (
            this.authenticationService.isUserLoginAsFusionAuth() &&
            window.location.pathname !== '/authentication/logout'
        ) {
            var refreshTokenResult = this.authenticationService.refreshToken();
            if (refreshTokenResult) {
                this.tokenRefresh$ = this.authenticationService.refreshTokenPeriodic().subscribe(
                    refreshed => {},
                    error => {}
                );
            }
            return;
        }

        const refresh_token = this.authenticationService.fetchToken();
        if (refresh_token && window.location.pathname !== '/authentication/logout') {
            this.tokenRefresh$ = this.authenticationService.refreshTokenPeriodic().subscribe(
                refreshed => {},
                error => {}
            );
        }
    }

    ngOnDestroy() {
        if (this.tokenRefresh$) {
            this.tokenRefresh$.unsubscribe();
        }

        this.subscriptions.unsubscribe();

        this.bc.unsubscribeAllEvent();
    }
}
