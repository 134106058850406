import {
    Directive,
    ElementRef,
    forwardRef,
    Inject,
    InjectionToken,
    Input,
    Optional,
    Provider,
    Renderer2
} from '@angular/core';
import { COMPOSITION_BUFFER_MODE, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// tslint:disable: no-use-before-declare no-host-metadata-property
export const NEWLINE_DELIMITED_INPUT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NewlineDelimitedInputDirective),
    multi: true
};

export interface NewlineDelimitedInputOpts {
    removeEmptyEntries?: boolean;
    trimEntryValue?: boolean;
}

const defaultNewlineDelimitedInputOptions: NewlineDelimitedInputOpts = {
    removeEmptyEntries: true,
    trimEntryValue: false
};

export const newlineDelimitedInputOptionsFactory = () => defaultNewlineDelimitedInputOptions;

export const NEWLINE_DELIMITED_INPUT_OPTIONS = new InjectionToken<NewlineDelimitedInputOpts>(
    'NewlineDelimitedInputOptions'
);

export const NEWLINE_DELIMITED_INPUT_OPTIONS_PROVIDER = <Provider>{
    provide: NEWLINE_DELIMITED_INPUT_OPTIONS,
    useFactory: newlineDelimitedInputOptionsFactory
};

const selectorId = 'newlineDelimitedInput';

@Directive({
    selector: `textarea[formControlName][${selectorId}],textarea[formControl][${selectorId}],textarea[ngModel][${selectorId}]`,
    host: {
        '(input)': '$any(this)._handleInput($event.target.value)',
        '(blur)': 'onTouched()',
        '(compositionstart)': '$any(this)._compositionStart()',
        '(compositionend)': '$any(this)._compositionEnd($event.target.value)'
    },
    providers: [NEWLINE_DELIMITED_INPUT_VALUE_ACCESSOR]
})
export class NewlineDelimitedInputDirective extends DefaultValueAccessor {
    private _options: NewlineDelimitedInputOpts;
    @Input(selectorId)
    set options(opts: NewlineDelimitedInputOpts) {
        this._options = opts;
    }

    get options() {
        return this.coerceOptions(this._options);
    }
    private el: HTMLTextAreaElement;
    constructor(
        private renderer: Renderer2,
        _elementRef: ElementRef<HTMLTextAreaElement>,
        @Optional() @Inject(COMPOSITION_BUFFER_MODE) _compositionMode: boolean
    ) {
        super(renderer, _elementRef, _compositionMode);
        this.el = _elementRef.nativeElement;
    }

    writeValue(value: string[]) {
        const normalizedValue = value || [];
        this.renderer.setProperty(this.el, 'value', this.toString(normalizedValue));
    }

    registerOnChange(fn: (_: string[]) => void) {
        this.onChange = (value: string) => {
            const normalizedValue = value || '';
            fn(this.toArray(normalizedValue));
        };
    }

    private coerceOptions = (opts: NewlineDelimitedInputOpts) =>
        Object.assign({}, defaultNewlineDelimitedInputOptions, opts);

    private toString(value: string[]) {
        if (this.options.trimEntryValue) {
            value = value.map(val => val.trim());
        }
        if (this.options.removeEmptyEntries) {
            value = value.filter(val => val.length > 0);
        }
        return value.join('\n');
    }

    private toArray(value: string) {
        let arrValues = value.split('\n');
        if (this.options.trimEntryValue) {
            arrValues = arrValues.map(val => val.trim());
        }
        if (this.options.removeEmptyEntries) {
            arrValues = arrValues.filter(val => val.length > 0);
        }
        return arrValues;
    }
}
