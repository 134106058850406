import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'toLuxonDate' })
export class ToLuxonDatePipe implements PipeTransform {
    transform(value: number | string | Date): DateTime {
        let d: DateTime;

        const type = typeof value;

        switch (type) {
            case 'number': {
                d = DateTime.fromMillis(value as number);
                break;
            }
            case 'string': {
                d = DateTime.fromISO(value as string, { zone: 'utc' });
                break;
            }
            default: {
                d = DateTime.fromJSDate(value as Date);
                break;
            }
        }
        return d;
    }
}
