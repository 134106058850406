export enum QuoteCreateStepper {
    Create = 1,
    ReviewConfirm = 2,
    CompleteSuccess = 3
}

export enum QuoteViewStepper {
    View = 1,
    ConfirmInstallation = 2,
    CancelSuccess = 3,
    ConfirmInstallationSuccess = 4
}
