import { Pipe, PipeTransform } from '@angular/core';
import { LoanDrawersService } from '@brokerportal/modules/application/detail/loan-drawers/loan-drawers.service';
import { LoanDrawerChannel } from '../models/application/loan-drawer.model';

@Pipe({
    name: 'loanDrawerChannelDescription'
})
export class LoanDrawerChannelDescriptionPipe implements PipeTransform {
    constructor(private loanDrawersService: LoanDrawersService) {}

    transform(value: LoanDrawerChannel) {
        return this.loanDrawersService.getLoanDrawerChannelDescription(value);
    }
}
