// See PredefinedApprovalConditions in Venus
// NOTE: loan conditions can also be custom, i.e. free text, so may have no corresponding entry here
export enum LoanConditionPredefinedApprovalConditions {
    TaxInvoiceRequest = 0,
    PrivateSaleInvoiceTemplate = 1,
    InsuranceCertificate = 2,
    IncomeAndExpenses = 3,
    Identity = 4,
    YodleeAccountBalanceExceedingUpfrontPayment = 5,
    FraudCheckMustBePerformed = 6,
    BankAccountValidation = 7,
    PrimaryLoanCreated = 8,
    ExistingFinancierDetails = 9,
    ExistingSecurityDetails = 10,
    BankStatements = 11,
    NoExistingSecurity = 12,
    UnderwritingCommenced = 13,
    ApprovedPendingInstallation = 14,
    SubsidyApproved = 15,
    ApplicationDocumentsProvided = 16
}

export enum LoanConditionStatus {
    NotMet = 1,
    Met = 2,
    Pending = 3
}

export interface ApplicationDetailsLoanConditionModel {
    condition: string;
    status: LoanConditionStatus;
}

export enum ApprovalConditionsStatus {
    Requested = 1,
    Received = 2,
    Verified = 3,
    NotRequired = 4,
    Accepted = 5
}

export enum ApprovalConditionsDocumentStatus {
    NotUploaded = 'NotUploaded',
    Uploaded = 'Uploaded',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Accepted = 'Accepted'
}

export const ApprovalConditionName = {
    InstallationConfirmation: 'InstallationConfirmation',
    PaymentDetailsInstallationConfirmation: 'PaymentDetailsInstallationConfirmation',
    ClientDetailsConfirmation: 'ClientDetailsConfirmation',
    SystemDetailsConfirmation: 'SystemDetailsConfirmation',
    InstallerDetailsConfirmation: 'InstallerDetailsConfirmation',
    SmallScaleTechnologyCertificate: 'SmallScaleTechnologyCertificate',
    GreenEquipmentInvoice: 'GreenEquipmentInvoice',
    Identity: 'Identity',
    IdentityGreenId: 'IdentityGreenId',
    BiometricVerification: 'BiometricVerification',
    BiometricIdentity: 'BiometricIdentity',
    BiometricIdentity_Second: 'BiometricIdentity_Second',
    BankStatementsThirdParty: 'BankStatementsThirdParty',
    DealershipVerificationThirdParty: 'DealershipVerificationThirdParty'
};

export interface InformationType {
    Type: string;
}

export interface ApprovalDocumentType extends InformationType {
    DocumentType: string;
}

export interface FreeTextType extends InformationType {
    Note: string;
}

export interface ValidatedObjectType extends InformationType {
    Object: any;
    Schema: any;
}

export interface BooleanType extends FreeTextType {
    AcceptanceStatus: string;
}

export interface ThirdPartyType extends InformationType {
    ThirdPartyDetails: any;
    ThirdPartyIntegrationType: string;
    ThirdPartyStatus: string;
    IntegrationMetaData: any;
}

type _InformationType =
    | InformationType
    | ApprovalDocumentType
    | FreeTextType
    | ValidatedObjectType
    | BooleanType
    | ThirdPartyType;

export interface ApprovalCondition {
    ConditionId: number; // using conditionId == 0 for ARS for now
    Name: string;
    RequestStatus: string;
    ParentRequirementRequestStatus: string;
    InformationType: _InformationType;
    Title: string;
    Description: string;
    LongDescription?: string;
    Error?: string;
    Note?: string;
    Stage: string;
    DocumentStatus: ApprovalConditionsDocumentStatus;
    RejectionReason?: string;
    RejectionReasonDescription: string;
    DocumentGuid: string;
    ProvidedBy?: string;
    RequirementName: string;
    IsCustomRequirement?: boolean;
    AcceptedFileTypes?: string[];
    Instructions?: string[];
}

export interface NewLoanApprovalConditionModel {
    DefaultApprovalConditions: Array<ApprovalCondition>;
    CustomApprovalConditions: Array<ApprovalCondition>;
}
