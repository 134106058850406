import { Pipe, PipeTransform } from '@angular/core';
import { ExternalReferenceNumberType } from '../enums/external-reference-number-type.enum';
import { ExternalReferenceNumberService } from '../services/external-reference-number.service';

@Pipe({
    name: 'externalReferenceNumberTypeDescription'
})
export class ExternalReferenceNumberTypeDescriptionPipe implements PipeTransform {
    constructor(private externalReferenceNumberService: ExternalReferenceNumberService) {}

    transform(type: ExternalReferenceNumberType): string {
        return this.externalReferenceNumberService.getExternalReferenceNumberTypeDescription(type);
    }
}
