import { FormControl, ValidationErrors } from '@angular/forms';

const fluff = ['tba', 'tbc', 'na', 'n/a'];

const fluffPattern = '(?:' + fluff.join(')|(?:') + ')';
const adriftFluffRegex = new RegExp(fluffPattern, 'i');
const anchoredFluffRegex = new RegExp('^(?:' + fluffPattern + ')$', 'i');

export const antiFluffValidator = () => (control: FormControl): ValidationErrors | null => {
    const trimmed = (control.value + '').trim();
    return typeof control.value !== 'string' || trimmed.length < 2 || anchoredFluffRegex.test(trimmed)
        ? { patternError: true }
        : null;
};

export const eliminateFluff = (value: string) => {
    let prev: string;
    let result = value;
    do {
        prev = result;
        result = prev.replace(adriftFluffRegex, '');
    } while (prev !== result);
    return result;
};
