import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RateProfile } from '@brokerportal/common/models/rate-profile.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RatesService {
    constructor(private httpClient: HttpClient) {}

    public getRatesProfile(applicationGuid: string): Observable<RateProfile> {
        return this.httpClient
            .get<any>(`${environment.api.baseUrl}/Rates/Profile?applicationId=${applicationGuid}`)
            .pipe(
                map(res => ({
                    maxTerm: res.MaxTerm,
                    minAmount: res.MinAmount,
                    maxAmount: res.MaxAmount
                }))
            );
    }
}
