import { FormControl } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'firstError' })
export class FirstErrorPipe implements PipeTransform {
    transform(errors: any): string {
        if (!errors) {
            return '';
        }

        return Object.keys(errors)[0];
    }
}
