import { AbstractControl, ValidatorFn } from '@angular/forms';

export function conditional(predicate: () => boolean, validators: (ValidatorFn | null | undefined)[] | null) {
    return (ctrl: AbstractControl) => {
        if (typeof predicate !== 'function' || !Array.isArray(validators)) return null;
        const validValidators: ValidatorFn[] = validators.filter(fn => typeof fn === 'function');
        if (validValidators.length === 0) return null;

        if (predicate()) {
            return validValidators.map(fn => fn(ctrl)).find(err => err) || null;
        }

        return null;
    };
}
