import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'information-message-dialog',
    templateUrl: './information-message-dialog.component.html'
})
export class InformationMessageDialog {
    constructor(public dialogRef: MatDialogRef<InformationMessageDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
