import { FormControl } from '@angular/forms';
import { NamedAbstractControl } from '../models';

export function childrenRequiredValidator(childControlNames: string[]) {
    let savedControl: FormControl;
    const watchedFieldControls: NamedAbstractControl[] = [];

    return (control: FormControl): { [key: string]: any } => {
        if (!savedControl) {
            savedControl = control;
            childControlNames.forEach(fieldName =>
                watchedFieldControls.push({ name: fieldName, control: savedControl.get(fieldName) })
            );
        }

        let errors = null;
        watchedFieldControls.forEach(watched => {
            if (watched.control.value === '') {
                errors = { required: true };
            }
        });
        return errors;
    };
}
