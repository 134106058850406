<mat-form-field fxFill>
    <input matInput ngModel [disabled]="disabled || loading" [matAutocomplete]="auto" placeholder="{{placeholder}}" (blur)="markAsTouched()"
        [attr.data-cy]="'input.' + category + 'CecPackage'" #input #autoTrigger="matAutocompleteTrigger" #matInput="matInput" />
    <mat-autocomplete autoActiveFirstOption [displayWith]="_getDisplayText" class="bp-cec-package-select-panel"
        (optionSelected)="_onSelected($event.option.value)" #auto="matAutocomplete">
        <mat-option *ngFor="let pck of filteredPackages" [value]="pck">
            <div class="title">{{pck.model}}</div>
            <div class="sub-title"><small>{{pck.brand}}</small></div>
            <mat-divider></mat-divider>
        </mat-option>
        <mat-option disabled *ngIf="matInput.ngControl?.dirty && filteredPackages?.length === 0 && !loading">
            <span class="empty-result-text">{{input.value?.length > 0 ? 'No result found' : 'Enter a search term to begin'}}</span>
        </mat-option>
    </mat-autocomplete>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <button *ngIf="!disabled && input.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="clear();markAsTouched();autoTrigger.openPanel();" data-cy="button.clear">
        <mat-icon>clear</mat-icon>
    </button>
    <mat-error><ng-content select="mat-error"></ng-content></mat-error>
    <mat-hint><ng-content select="mat-hint"></ng-content></mat-hint>
</mat-form-field>