// C.f. IdentityCheckResult in Venus
export enum IdentityVerificationStatus {
    Unavailable = -1,
    NotChecked = 0,
    Pass = 1,
    Refer = 2,
    Error = 3,
    NotMatched = 4,
    Picklist = 5,
    Declined = 6,
    Skipped = 7,
    DocumentProvided = 99 // Front-end value only
}
