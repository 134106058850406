<ng-container *ngIf="collapsible; then collapsibleDisplay else noncollapsibleDisplay">
</ng-container>

<ng-template #collapsibleDisplay>
    <mat-expansion-panel *ngIf="package && availablePackageDetails.length > 0" [expanded]="panelOpen" (opened)="panelOpen = true" (closed)="panelOpen = false">
        <mat-expansion-panel-header>
            <mat-panel-title>{{title}}</mat-panel-title>
            <mat-panel-description>Click to {{panelOpen ? 'hide' : 'expand'}}</mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="packageList"></ng-container>
    </mat-expansion-panel>
</ng-template>

<ng-template #noncollapsibleDisplay>
    <ng-container *ngIf="package && availablePackageDetails.length > 0">
        <p>{{title}}</p>
        <mat-card>
            <ng-container *ngTemplateOutlet="packageList"></ng-container>
        </mat-card>
    </ng-container>
</ng-template>

<ng-template #packageList>
    <ng-container *ngFor="let packageProperty of availablePackageDetails">
        <div *ngIf="package[packageProperty]">
            {{extraPackageDetailLabels[packageProperty]}}: <b>{{package[packageProperty]}}</b>
        </div>
    </ng-container>
</ng-template>