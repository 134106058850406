import { Injectable } from '@angular/core';
import { BrokerSettingService } from './broker-setting.service';
import {
    BrokerSettingGroupNames,
    BrokerSettingGroupValueType,
    ExternalReferenceNumberType,
    RenewableExtraFieldKeys
} from '../enums';
import { ExtraFieldsService } from './extra-fields.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ExternalReferenceNumberService {
    private externalReferenceNumberTypeDescription = new Map<ExternalReferenceNumberType, string>([
        [ExternalReferenceNumberType.InvoiceReferenceNumber, 'invoice/reference number'],
        [ExternalReferenceNumberType.ContractAccountNumber, 'contract account number']
    ]);

    private indefiniteExternalReferenceNumberTypeDescription = new Map<ExternalReferenceNumberType, string>([
        [ExternalReferenceNumberType.InvoiceReferenceNumber, 'an invoice/reference number'],
        [ExternalReferenceNumberType.ContractAccountNumber, 'a contract account number']
    ]);

    private externalReferenceNumberExtraField = new Map<ExternalReferenceNumberType, string>([
        [ExternalReferenceNumberType.InvoiceReferenceNumber, RenewableExtraFieldKeys.InvoiceNumber],
        [ExternalReferenceNumberType.ContractAccountNumber, RenewableExtraFieldKeys.ContractAccountNumber]
    ]);

    constructor(private brokerSettingService: BrokerSettingService, private extraFieldsService: ExtraFieldsService) {}

    getExternalReferenceNumberType(brokerGuid: string) {
        return this.brokerSettingService
            .Get<string>(
                brokerGuid,
                BrokerSettingGroupNames[BrokerSettingGroupNames.BrokerPortalReportingColumns],
                BrokerSettingGroupValueType.String
            )
            .pipe(
                map(result => {
                    return (ExternalReferenceNumberType[result] ??
                        ExternalReferenceNumberType.InvoiceReferenceNumber) as ExternalReferenceNumberType;
                })
            );
    }

    updateExternalReferenceNumber(applicationGuid: string, type: ExternalReferenceNumberType, value: string) {
        return this.extraFieldsService.updateValue(
            applicationGuid,
            this.getExternalReferenceNumberExtraField(type),
            value,
            true // update application report
        );
    }

    getExternalReferenceNumberTypeDescription(type: ExternalReferenceNumberType) {
        return this.externalReferenceNumberTypeDescription.get(type);
    }

    getIndefiniteExternalReferenceNumberTypeDescription(type: ExternalReferenceNumberType) {
        return this.indefiniteExternalReferenceNumberTypeDescription.get(type);
    }

    getExternalReferenceNumberExtraField(type: ExternalReferenceNumberType) {
        return this.externalReferenceNumberExtraField.get(type);
    }
}
