import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'environments/environment';

import { AuthenticationService } from './authentication.service';
import { VenusDemandMapping } from './demands.const';

//only one structural directive per element
@Directive({ selector: '[hasDemandStructural]' })
export class HasDemandDirectiveStructural implements OnInit {
    @Input()
    hasDemandStructural: VenusDemandMapping;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private authService: AuthenticationService
    ) {}

    async ngOnInit() {
        if (environment.bypassAuthentication) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
            return;
        }

        if (!VenusDemandMapping[this.hasDemandStructural]) {
            console.error(`[HasDemand]: WARNING: ${this.hasDemandStructural} does not map to a known demand!`);
            this.viewContainerRef.clear();
            return;
        }

        const hasDemand = await this.authService.hasDemand(VenusDemandMapping[this.hasDemandStructural]);

        if (!hasDemand) {
            this.viewContainerRef.clear();
        } else {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
