import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationQuote, ProductQuote } from '@brokerportal/modules/quote/shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from '../utils';

export interface QuoteQuery {
    loanAmount?: string;
    platformType?: string;
    vehicleCategory?: string;
}

@Injectable()
export class ProductQuoteService {
    constructor(private httpClient: HttpClient) {}

    public getWarehouseQuote(
        applicationGuid: string,
        commissionPercentage = null,
        brokerage = null,
        modelYear = null,
        vehicleCondition = null,
        balloonPaymentPercentage = null,
        loanAmount = null,
        saleType = null
    ): Observable<any> {
        let url = `${environment.api.baseUrl}/FinanceQuote/application/${applicationGuid}/pricing?platformType=Warehouse`;

        url += !isNullOrUndefined(commissionPercentage)
            ? `&commissionpercentage=${commissionPercentage.toFixed(2)}`
            : '';
        url += !isNullOrUndefined(modelYear) ? `&modelYear=${modelYear}` : '';
        url += !isNullOrUndefined(vehicleCondition) ? `&vehiclecondition=${vehicleCondition}` : '';
        url += !isNullOrUndefined(brokerage) ? `&brokerage=${brokerage}` : '';
        url += !isNullOrUndefined(balloonPaymentPercentage)
            ? `&balloonPaymentPercentage=${balloonPaymentPercentage}`
            : '';
        url += !isNullOrUndefined(loanAmount) ? `&loanAmount=${loanAmount}` : '';
        url += !isNullOrUndefined(saleType) ? `&saleType=${saleType}` : '';

        return this.httpClient.get(url).pipe(
            map((result: any) => {
                return result.productQuotes;
            })
        );
    }

    public getRetailQuote(applicationGuid: string, brokerage, loanAmount): Observable<any> {
        return this.httpClient
            .get(
                `${environment.api.baseUrl}/FinanceQuote/application/${applicationGuid}/pricing?platformType=Retail&brokerage=${brokerage}&loanAmount=${loanAmount}`
            )
            .pipe(
                map((result: any) => {
                    return result.productQuotes;
                })
            );
    }

    public getQuote(applicationGuid: string, params: QuoteQuery): Observable<ApplicationQuote> {
        const queryStrings: { [key: string]: string } = {
            ...params
        };

        return this.httpClient.get<ApplicationQuote>(
            `${environment.api.baseUrl}/FinanceQuote/application/${applicationGuid}/quote`,
            { params: queryStrings }
        );
    }

    public getRetailQuoteEstimate(
        loanAmount: number,
        productId: number,
        partnerId: string,
        experience: string,
        platform = 'Retail',
        creditScore = 600
    ): Observable<any> {
        return this.httpClient
            .get(
                `${environment.api.baseUrl}/FinanceQuote/platform/${platform}/experience/${experience}/partner/${partnerId}/creditScore/${creditScore}/loanAmount/${loanAmount}/product/${productId}/quote`
            )
            .pipe(
                map((result: any) => {
                    return result;
                })
            );
    }
}
