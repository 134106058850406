import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MediaReplayService {
    activeMediaQuery = '';
    isMobile$: Observable<boolean>;

    constructor(mediaObserver: MediaObserver) {
        this.isMobile$ = mediaObserver.media$.pipe(
            map((change: MediaChange) => {
                this.activeMediaQuery = change.mqAlias;
                if (change.mqAlias === 'xs' || change.mqAlias === 'sm') {
                    return true;
                }
                return false;
            })
        );
    }

    get currentMediaQuery(): string {
        return this.activeMediaQuery;
    }
}
