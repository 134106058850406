import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from 'environments/environment';

const BAD_REQUEST = 400;
const CONFLICT = 409;
const UNAUTHORISED = 401;
const FORBIDDEN = 403;
const INTERAL_SERVER_ERROR = 500;
const DISPLAYABLE_TYPES = [CONFLICT, BAD_REQUEST, INTERAL_SERVER_ERROR];

// DEVNOTE: https://www.digitalocean.com/community/tutorials/how-to-use-angular-interceptors-to-manage-http-requests-and-error-handling

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private snackBar: MatSnackBar, private injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (DISPLAYABLE_TYPES.indexOf(error.status) >= 0 && error.error) {
                    const errorMessage = this.getErrorMessage(error);
                    this.showError(errorMessage);
                    return throwError(error.error);
                }

                if (error.status === UNAUTHORISED) {
                    // DEVNOTE: a HTTP 401 or 403 may be generated when attempting to log in (and failing due to credentials), and HTTP 403 will no longer log out.
                    // so act conditionally here
                    this.showTemporaryError('Sorry, you are not authenticated. Please log in.');
                    if (this.router.url.indexOf('/authentication') === -1) {
                        this.router.navigateByUrl('/authentication/logout');
                        return EMPTY;
                    }
                }

                let errorMessage =
                    'Something went wrong while processing your request. Try again, or contact Plenti for support';
                return throwError((error && error.error) || errorMessage);
            })
        );
    }

    showError(message: string): void {
        console.error(message);
        // this.snackBar.open(message, 'X', { duration: 5000, panelClass: ['error', 'long'] });
    }

    showTemporaryError(message: string): void {
        console.error(message);
        this.snackBar.open(message, null, { duration: 5000 });
    }

    getErrorMessage(error: HttpErrorResponse): string {
        let message = `${error.error?.Message || 'An error has occurred'}.`;
        if (environment.envName === 'dev') {
            message += `\n${error.error?.ExceptionType}\n${error.error?.ExceptionMessage}\n${error.error?.StackTrace}`;
        }
        return message;
    }
}
