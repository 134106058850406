import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ScrollbarUiModule } from '../scrollbar/scrollbar.module';
import { IconSidenavDirective } from './icon-sidenav.directive';
import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';
import { SidenavComponent } from './sidenav.component';
import { SidenavService } from './sidenav.service';
import { MaterialComponentsModule } from 'app/material-components.module';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MaterialComponentsModule,
        RouterModule,
        FlexLayoutModule,
        ScrollbarUiModule
    ],
    entryComponents: [],
    declarations: [SidenavComponent, SidenavItemComponent, IconSidenavDirective],
    exports: [SidenavComponent, SidenavItemComponent],
    providers: [SidenavService]
})
export class SidenavUiModule {}
