<h2 mat-dialog-title>Do you wish to renew this quote?</h2>
<mat-dialog-content>
    <mat-form-field style="width: 100%">
        <input matInput readonly
               [matDatepicker]="newExpiredDateDatePicker"
               placeholder="New expiry date (DD/MM/YYYY)"
               (click)="newExpiredDateDatePicker.open()"
               [min]="today" [(ngModel)]="newExpiryDate"
        />
        <mat-datepicker-toggle matPrefix [for]="newExpiredDateDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #newExpiredDateDatePicker [touchUi]="isMobile"></mat-datepicker>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <span fxFlex></span>
    <button mat-raised-button mat-dialog-close>Close</button>
    <button [disabled]="!isValidNewExpiryDate()"
            (click)="handleRenewQuote(); $event.preventDefault();"
            mat-raised-button
            mat-dialog-close
            color="primary"
    >
        Renew
    </button>
</mat-dialog-actions>
