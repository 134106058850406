import { FormControl } from '@angular/forms';

interface INumberValidatorConfig {
    min?: number;
    max?: number;
    canEqual?: boolean;
}

export function numberValidator(config: INumberValidatorConfig) {
    return (control: FormControl): { [key: string]: any } => {
        let input: number = control.value || 0;
        let canEqual: boolean = config.canEqual || false;
        let minVal: number = config.min;
        let maxVal: number = config.max;

        if (isNaN(input)) {
            return { number: true };
        }

        let minCheck = true;
        if (!isNaN(minVal)) {
            minCheck = canEqual ? input >= minVal : input > minVal;
        }

        let maxCheck = true;
        if (!isNaN(maxVal)) {
            maxCheck = canEqual ? input <= maxVal : input < maxVal;
        }

        return minCheck && maxCheck ? null : { number: true };
    };
}
