import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CurrencyService {
    transformCurrency(value: any) {
        return new CurrencyPipe('en_AU').transform(value, 'AUD', 'symbol-narrow', '1.0-0');
    }

    untransformCurrency(value: any) {
        return parseInt(value.replace(/[$,]+/g, '')) || 0;
    }
}
