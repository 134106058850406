import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SubNavigationService {
    private navigationSource: BehaviorSubject<number>;
    navigation$: Observable<number>;

    constructor() {
        this.navigationSource = new BehaviorSubject<number>(0);
        this.navigation$ = this.navigationSource.asObservable();
    }

    signalNavigation(value: any) {
        this.navigationSource.next(value);
    }
}
