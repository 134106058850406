import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GLOBAL_CONSTANTS } from 'app/constants';
import { NgRadio } from 'ng-radio';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bp-editable-label',
    templateUrl: './editable-label.component.html',
    styleUrls: ['./editable-label.scss']
})
export class EditableLabel implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() name: string;
    @Input() placeholder: string;
    edittingInfo: boolean;

    private subscriptions = new Subscription();

    constructor(private radio: NgRadio) {
        this.edittingInfo = false;
    }

    ngOnInit() {
        this.subscriptions.add(
            this.radio.on(GLOBAL_CONSTANTS.CHANNEL.APPLICATION.APPLICATION_UPDATED).subscribe(result => {
                this.edittingInfo = false;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    toggleEdit() {
        this.edittingInfo = !this.edittingInfo;
    }
}
