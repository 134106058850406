export const GENERIC_DESCRIPTION = `<p>Request a personalised RateEstimate, then proceed to apply on behalf of your client online in minutes.</p>
                                <p>To submit an application you will typically require:</p>
                                <ul>
                                <li>A government issued identity document, such as a driver licence</li>
                                <li>Bank statements for the most recent 3 month period</li>
                                <li>If your client is self-employed, the last 2 years' notices of assessment and income tax returns</li>
                                </ul>
                                <p>See the personal loan product guide or application checklist for more information.</p>`;

export const DEFAULT_GREEN_DESCRIPTION = `<p>Request a personalised RateEstimate, then apply on behalf of your client in minutes.</p>
                                    <p>To submit an application you will typically require:</p>
                                    <ul>
                                    <li>A government issued identity document, such as a driver licence</li>
                                    <li>Bank statements for the most recent 3 month period</li>
                                    </ul>
                                    <p>See the quick reference sheet for more information.</p>`;

export const INTEREST_FREE_GREEN_DESCRIPTION = `<p>Submit an application and your client could be conditionally approved in minutes.</p>
    <p>For zero interest payment plan applications we typically require:</p>
    <ul>
    <li>a government issued identity document, such as a driver licence to verify your client’s identity</li>
    <li>your client to be a homeowner and the main income earner in their household</li>
    <li>your client to be employed for 25 or more hours a week</li>
    </ul>`;

export const AGL_SUBSIDY_DESCRIPTION = `<p><b>To qualify for the AGL subsidy, we also require:</b></p>
    <ul>
    <li>your client to be located in NSW, SA, QLD or VIC</li>
    <li>your client to be purchasing an eligible battery (e.g. Sungrow or Tesla Powerwall)</li>
    <li>your client to join the AGL Virtual Power Plant</li>
    <li>a dual-channel meter for AC-coupled Sungrow and LG battery installations</li>
    <li>a hardwired Ethernet or Wi-Fi connection (SIM connected batteries are not applicable)</li>
    <li>a smart meter to be installed or the customer agreeing to have one installed</li>
    </ul>`;

export const QCELLS_SUBSIDY_DESCRIPTION = `<p><b>To qualify for the Q cells battery discount, you will need to:</b></p>
    <ul>
    <li>Be purchasing an eligible Q cells battery</li>
    <li>Provide Q cells with your voucher code on your Purchase Order. Your voucher code will be sent to you via email and will also be available in the Plenti portal</li>
    <li>Apply for finance with Plenti. You will not be eligible if your client pays exclusively with cash</li>
    </ul>`;

export const SONNEN_SUBSIDY_DESCRIPTION = `<p><b>To qualify for the Sonnen battery discount, you will need to:</b></p>
    <ul>
    <li>Apply for finance with Plenti. You will not be eligible if your client pays exclusively with cash</li>
    <li>Be purchasing an eligible sonnen battery</li>
    <li>Ensure your client is in an eligible location</li>
    </ul>`;

export const DEFAULT_CAR_DESCRIPTION = `<p>Apply on behalf of your client in minutes.</p>
                                    <p>To submit an application you will typically require:</p>
                                    <ul>
                                    <li>A government issued identity document, such as a driver licence</li>
                                    <li>Bank statements for the most recent 3 month period</li>
                                    </ul>
                                    <p>See the car loan product guide or application checklist for more information.</p>`;

export const DEFAULT_COMMERCIAL_CAR_DESCRIPTION = `<p>Apply on behalf of your client in minutes.</p>
                                    <p>To submit a commercial car loan application you will typically require:</p>
                                    <ul>
                                    <li>Driver's licence for every guarantor, director, shareholder >25%, beneficiary and appointer of any trust</li>
                                    <li>Medicare card or passport for every guarantor, director, shareholder >25% beneficiary and appointer of any trust</li>
                                    <li>Trust deed if the entity is a trust. A certified copy will be required at settlement</li>
                                    </ul>
                                    <p>See the commercial product guide for more information.</p>`;

export const PERSONAL_LOAN_DESCRIPTION = `<p>Client requirements before you begin:</p>
                                  <ul>
                                    <li>A goverment issued identity document, like a driver's license</li>
                                    <li>Bank statements for the most recent 3 month period</li>
                                    <li>If your client is self-employed, the last 2 years's notices of assessment and tax returns</li>
                                  </ul>
`;
