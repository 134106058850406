<p *ngIf="!isInline">
    Questions? <ng-container *ngTemplateOutlet="contactText"></ng-container>
</p>

<ng-container *ngIf="isInline">
    <ng-container  *ngTemplateOutlet="contactText" ></ng-container>
</ng-container>


<ng-template #contactText>
    {{isInline ? 'call' : 'Call'}} us on <strong class="phone-number-text">{{txt.phone}}</strong> (9:00am to 6:00pm AET) 
    or email <a href="mailto:{{txt.email}}">{{txt.email}}</a> 
</ng-template>
