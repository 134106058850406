<section fxLayout="column" fxFlex="100%;" [formGroup]="form">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex="100%;" fxFlex.xs="80%">
            <bp-address-search name="fullAddress" formControlName="fullAddress" [placeholder]="_getPlaceholder('fullAddress')"
                #search></bp-address-search>
            <mat-hint *ngIf="form.controls['fullAddress'].touched &&
                !form.controls['fullAddress'].pending &&
                form.controls['fullAddress'].invalid &&
                form.controls['fullAddress'].hasError('_internalError')" class="field-warning">
                We had some trouble finding that address. Please use the button below to enter the address.
            </mat-hint>
            <mat-error *ngIf="form.controls['fullAddress'].touched &&
                !form.controls['fullAddress'].pending &&
                form.controls['fullAddress'].invalid &&
                !form.controls['fullAddress'].hasError('_internalError')">
                {{_getFirstOrDefaultErrorMessage('fullAddress')}}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxFlex="100%;" fxFlex.xs="80%" fxLayoutGap.lt-md="0px">
        <mat-expansion-panel fxFlex="100%;" fxFlex.xs="100%" class="manual-address-panel" [formGroup]="form" #panel>
            <mat-expansion-panel-header>
                <mat-panel-description>
                    {{ panel.expanded ? inputFormHeaderExpandedText : inputFormHeaderCollapsedText }}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="100%;" fxFlex.xs="100%">
                    <input matInput [placeholder]="_getPlaceholder('unitNumber')" formControlName="unitNumber" data-cy="input.unitNumber" />
                    <mat-error *ngIf="form.controls['unitNumber'].touched && form.controls['unitNumber'].invalid">
                        {{ _getFirstOrDefaultErrorMessage('unitNumber') }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="100%;" fxFlex.xs="100%">
                    <input matInput [placeholder]="_getPlaceholder('streetNumber')" formControlName="streetNumber"
                        data-cy="input.streetNumber" />
                    <mat-error *ngIf="form.controls['streetNumber'].touched && form.controls['streetNumber'].invalid">
                        {{ _getFirstOrDefaultErrorMessage('streetNumber') }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="100%;" fxFlex.xs="100%">
                    <input matInput [placeholder]="_getPlaceholder('street')" formControlName="street" data-cy="input.street" />
                    <mat-error *ngIf="form.controls['street'].touched && form.controls['street'].invalid">
                        {{ _getFirstOrDefaultErrorMessage('street') }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="100%;" fxFlex.xs="100%">
                    <input matInput [placeholder]="_getPlaceholder('suburb')" formControlName="suburb" data-cy="input.suburb" />
                    <mat-error *ngIf="form.controls['suburb'].touched && form.controls['suburb'].invalid">
                        {{ _getFirstOrDefaultErrorMessage('suburb') }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="100%;" fxFlex.xs="100%">
                    <input matInput [placeholder]="_getPlaceholder('postcode')" formControlName="postcode" data-cy="input.postcode" />
                    <mat-error *ngIf="form.controls['postcode'].touched && form.controls['postcode'].invalid">
                        {{ _getFirstOrDefaultErrorMessage('postcode') }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="03px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="100%;" fxFlex.xs="100%">
                    <mat-select [placeholder]="_getPlaceholder('state')" formControlName="state" data-cy="input.state">
                        <mat-option *ngFor="let state of states" [value]="state.key"> {{ state.value || state.key }} </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.controls['state'].touched && form.controls['state'].invalid">
                        {{ _getFirstOrDefaultErrorMessage('state') }}
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
    </div>
</section>
