import {
    Component,
    Input,
    OnInit,
    Optional,
    Self,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    ViewEncapsulation
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormGroupDirective,
    FormGroupName,
    ValidationErrors
} from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import _ from 'lodash-es';

import { FullStateNames, RegexPatterns } from 'app/constants';
import { KeyValuePair } from '@brokerportal/common/enums';
import { fromDictToKeyValuePairs } from '@brokerportal/common/utils';
import { AddressSearchComponent } from './search/search.component';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export type AddressFormControlNames =
    | 'fullAddress'
    | 'unitNumber'
    | 'streetNumber'
    | 'street'
    | 'suburb'
    | 'postcode'
    | 'state';
export type AddressPlaceholderConfigs = { [key in AddressFormControlNames]?: string };
export type AddressValidationMessageConfigs = { [key in AddressFormControlNames]?: { [error: string]: string } };

const DefaultPlaceholderConfigs: AddressPlaceholderConfigs = {
    fullAddress: 'Enter an address to start searching',
    unitNumber: 'Unit number',
    streetNumber: 'Street number',
    street: 'Street name',
    suburb: 'Suburb',
    postcode: 'Postcode',
    state: 'State'
};

const DefaultValidationMessages: AddressValidationMessageConfigs = {
    fullAddress: {
        required: 'Please enter an address',
        selectedAddressRequired: 'Please select an address'
    },
    unitNumber: {
        required: 'Please enter a unit number'
    },
    streetNumber: {
        required: 'Please enter a street number'
    },
    street: {
        required: 'Please enter a street name'
    },
    suburb: {
        required: 'Please enter a suburb'
    },
    postcode: {
        required: 'Please enter a postcode'
    },
    state: {
        required: 'Please enter a state'
    }
};

@Component({
    selector: 'bp-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'bp-address-search'
    }
})
export class AddressComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    get placeholderConfigs() {
        return this._placeholderConfigs;
    }
    set placeholderConfigs(val) {
        this._placeholderConfigs = _.merge({}, DefaultPlaceholderConfigs, val);
    }
    private _placeholderConfigs: AddressPlaceholderConfigs = DefaultPlaceholderConfigs;
    @Input()
    get errorMessageConfigs() {
        return this._errorMessageConfigs;
    }
    set errorMessageConfigs(val) {
        this._errorMessageConfigs = _.merge({}, DefaultValidationMessages, val);
    }
    private _errorMessageConfigs: AddressValidationMessageConfigs = DefaultValidationMessages;
    @Input() states: Array<KeyValuePair<string, string>> = fromDictToKeyValuePairs(FullStateNames);
    @Input() inputFormHeaderExpandedText = 'Enter the installation address';
    @Input() inputFormHeaderCollapsedText = 'Or click here to enter the address manually';
    @ViewChild('search') private searchComponent: AddressSearchComponent;
    @ViewChild('panel') public readonly inputFormSection: MatExpansionPanel;

    /**
     * Gets the form group associated with this component.
     *
     * @memberof AddressComponent
     */
    get form() {
        return this._form;
    }
    // Boilerplate for form structure
    private _form = new FormGroup({
        fullAddress: new FormControl(null),
        unitNumber: new FormControl(null),
        streetNumber: new FormControl(null),
        street: new FormControl(null),
        suburb: new FormControl(null),
        postcode: new FormControl(null),
        state: new FormControl(null)
    });

    /**
     * Returns the current value. including disabled controls.
     *
     * Use `form.value` to get the value excluding disabled controls.
     *
     * Returned value has the following structure:
     * ```typescript
     *  {
     *      fullAddress: string,
     *      unitNumber: string,
     *      streetNumber: string,
     *      street: string,
     *      suburb: string,
     *      postcode: string,
     *      state: string
     *  }
     * ```
     * @memberof AddressComponent
     */
    get value() {
        return this.form.getRawValue();
    }

    readonly destroyed$ = new Subject<void>();

    constructor(
        @Optional() @Self() private formGroup: FormGroupDirective,
        @Optional() @Self() private formGroupName: FormGroupName
    ) {}

    ngOnInit() {
        if (this.formGroup) {
            this._form = this.formGroup.form;
        } else if (this.formGroupName) {
            this._form = this.formGroupName.formDirective.getFormGroup(this.formGroupName);
        }
    }

    ngAfterViewInit() {
        this.updateValidators();

        this.searchComponent.selectedValueChange.subscribe(address => {
            const formValue: any = {};

            let unitNumber = '';
            if (!address.streetNumber && address.lotNumber) {
                formValue.streetNumber = address.subdwelling;
            } else {
                formValue.streetNumber = address.streetNumber;
                unitNumber = address.subdwelling;
            }
            unitNumber += address.buildingName;
            formValue.unitNumber = unitNumber;
            formValue.street = address.street;
            formValue.suburb = address.locality;

            if (RegexPatterns.Postcode.test(address.postcode)) {
                formValue.postcode = address.postcode;
            }
            formValue.state = address.state;

            this.form.patchValue(formValue);
            this.form.markAllAsTouched();
            if (this.form.invalid) this.inputFormSection.open();
        });
    }

    ngOnDestroy() {
        this.destroyed$.complete();
    }

    _getPlaceholder(fieldName: string) {
        return this.placeholderConfigs[fieldName] || null;
    }

    _getFirstOrDefaultErrorMessage(fieldName: string) {
        const ctrl = this.form.get(fieldName);
        const errorKeys = Object.keys(ctrl.errors);
        if (errorKeys.length) {
            return (
                (this.errorMessageConfigs[fieldName] && this.errorMessageConfigs[fieldName][errorKeys[0]]) ||
                'Please enter a valid value.'
            );
        }
        return null;
    }

    private updateValidators() {
        const fullAddressCtrl = this.form.get('fullAddress');
        const originalValidators = fullAddressCtrl.validator ? [fullAddressCtrl.validator] : [];

        /** Validator that requires the address component to have a selected address. */
        const selectedAddressValidator = (ctrl: AbstractControl): ValidationErrors | null => {
            if (
                !ctrl.pending &&
                ctrl.value &&
                this.searchComponent.results.length > 0 &&
                !this.searchComponent.selectedValue
            ) {
                return { selectedAddressRequired: true };
            }
            return null;
        };
        fullAddressCtrl.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(50)).subscribe(value => {
            if (this.searchComponent.results.length === 0 && !this.searchComponent.selectedValue) {
                // Reset validators before searching
                fullAddressCtrl.setValidators(originalValidators);
                fullAddressCtrl.updateValueAndValidity({ emitEvent: false });
            }
        });
        this.searchComponent.searchCompleted.subscribe(() => {
            fullAddressCtrl.setValidators([...originalValidators, selectedAddressValidator]);
            fullAddressCtrl.updateValueAndValidity({ emitEvent: false });
        });
    }
}
