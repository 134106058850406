<section fxLayout="column" fxFlex="100%;">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
        <mat-form-field [formGroup]="searchAddressGroup" class="mat-sub-form" fxFlex="100%;" fxFlex.xs="80%">
            <input
                matInput
                type="text"
                placeholder="Installation address"
                formControlName="searchAddress"
                (keydown)="checkKeyDown($event)"
                data-cy="input.searchAddress"
            />
            <mat-error *ngIf="!harmonySearchError && field('searchAddress').hasError('state')">
                Please enter an address in {{ getStateList() }}
            </mat-error>
            <mat-hint *ngIf="harmonySearchError" class="field-warning">
                We had some trouble finding that address. Please use the button below to enter the address.
            </mat-hint>
        </mat-form-field>

        <mat-selection-list *ngIf="suggestions && suggestions.length" class="address-suggestions-list">
            <mat-list-option
                *ngFor="let suggestion of suggestions; let i = index"
                class="list-group-item"
                (click)="selectAddress(i)"
                (mouseover)="selectedIndex = i"
                [ngClass]="{ active: selectedIndex === i }"
                title=""
            >
                {{ suggestion.fullAddress }}
            </mat-list-option>
        </mat-selection-list>
    </div>

    <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="20px"
        fxFlex="100%;"
        fxFlex.xs="80%"
        fxLayoutGap.lt-md="0px"
    >
        <mat-expansion-panel
            fxFlex="100%;"
            fxFlex.xs="100%"
            [expanded]="manualPanelExpanded"
            class="manual-address-panel"
            (opened)="manualPanelExpanded = true"
            (closed)="manualPanelExpanded = false"
        >
            <mat-expansion-panel-header>
                <mat-panel-description>
                    {{
                        manualPanelExpanded
                            ? 'Enter the installation address'
                            : 'Or click here to enter the address manually'
                    }}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field [formGroup]="addressGroup" fxFlex="100%;" fxFlex.xs="100%">
                    <input matInput placeholder="Unit number" formControlName="unitNumber" data-cy="input.unitNumber" />
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field [formGroup]="addressGroup" fxFlex="100%;" fxFlex.xs="100%">
                    <input
                        matInput
                        placeholder="Street number"
                        (change)="onUpdateManualField()"
                        formControlName="streetNumber"
                        data-cy="input.streetNumber"
                    />
                    <mat-error *ngIf="field('streetNumber').hasError('required')">
                        Please enter a street number
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field [formGroup]="addressGroup" fxFlex="100%;" fxFlex.xs="100%">
                    <input
                        matInput
                        placeholder="Street name"
                        (change)="onUpdateManualField()"
                        formControlName="street"
                        data-cy="input.street"
                    />
                    <mat-error *ngIf="field('street').hasError('required')">
                        Please enter a street name
                    </mat-error>
                    <mat-error *ngIf="!field('street').hasError('required') && field('street').hasError('pattern')">
                        Please enter a valid street name
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field [formGroup]="addressGroup" fxFlex="100%;" fxFlex.xs="100%">
                    <input
                        matInput
                        placeholder="Suburb"
                        (change)="onUpdateManualField()"
                        formControlName="suburb"
                        data-cy="input.suburb"
                    />
                    <mat-error *ngIf="field('suburb').hasError('required')">
                        Please enter a suburb
                    </mat-error>
                    <mat-error *ngIf="!field('suburb').hasError('required') && field('suburb').hasError('pattern')">
                        Please enter a valid suburb
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                <mat-form-field [formGroup]="addressGroup" fxFlex="100%;" fxFlex.xs="100%">
                    <input
                        matInput
                        placeholder="Postcode"
                        (change)="onUpdateManualField()"
                        formControlName="postCode"
                        data-cy="input.postCode"
                    />
                    <mat-error *ngIf="(field('postCode').errors | firstError) === 'required'">
                        Please enter a postcode
                    </mat-error>
                    <mat-error *ngIf="(field('postCode').errors | firstError) === 'pattern'">
                        Please enter a valid postcode
                    </mat-error>
                    <mat-error *ngIf="(field('postCode').errors | firstError) === 'unacceptedValue'">
                        That postcode is not in the coverage area for this program
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="03px" fxLayoutGap.lt-md="0px">
                <mat-form-field [formGroup]="addressGroup" fxFlex="100%;" fxFlex.xs="100%">
                    <mat-select
                        placeholder="State"
                        (selectionChange)="onUpdateManualField()"
                        formControlName="state"
                        data-cy="input.state"
                    >
                        <mat-option *ngFor="let state of states" [value]="state">
                            {{ state }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="field('state').touched && (field('state').errors | firstError) === 'required'">
                        Please select a state
                    </mat-error>
                    <mat-error
                        *ngIf="field('state').touched && (field('state').errors | firstError) === 'unacceptedValue'"
                    >
                        State must be {{ getStateList() }}
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
    </div>
</section>
