import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, catchError, shareReplay, take } from 'rxjs/operators';
import { initFeatureFlags, isEnabled as isFeatureEnabled } from '@plentiau/fe-feature-flag-client';
import { Scope } from '@plentiau/fe-feature-flag-client/build/config';
import { FlagsByProjects } from '@plentiau/fe-feature-flag-client/build/model/flag';

import { environment } from 'environments/environment';
import { FFScope, getFeatureName } from '@brokerportal/common/enums/feature-flag';
import { Dictionary } from '../models';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService implements OnDestroy {
    private cacheInitialized = false;
    private cache$: Observable<Dictionary<boolean>>;
    private init$ = new ReplaySubject<void>(1);
    constructor() {
        this.cache$ = this.init$.pipe(
            switchMap(() => this.init().pipe(catchError((err, caught) => caught))),
            map(flags =>
                (flags[FFScope.BrokerPortal] || []).reduce<Dictionary<boolean>>(
                    (acc, cur) => ({ ...acc, [cur.code]: cur.isEnabled }),
                    {}
                )
            ),
            take(1),
            shareReplay(1)
        );
    }

    ngOnDestroy() {
        if (!this.init$.closed) {
            this.init$.complete();
        }
    }

    /**
     * @deprecated Consider using `isEnabled('PlCarNewStatusDisplay')`.
     * @return {boolean}
     */
    PlCarNewStatusDisplay() {
        return environment.features.PlCarNewStatusDisplay;
    }

    /**
     * @deprecated Consider using `isEnabled('EnableEditBorrowerInfo')`.
     * @return {boolean}
     */
    EnableEditBorrowerInfo() {
        return environment.features.EnableEditBorrowerInfo;
    }

    /**
     * @deprecated Consider using `isEnabled('EnableIdentityAsLoanCondition')`.
     * @return {boolean}
     */
    EnableIdentityAsLoanCondition() {
        return environment.features.EnableIdentityAsLoanCondition;
    }

    init() {
        return new Observable<FlagsByProjects>(observer => {
            initFeatureFlags(
                [FFScope.BrokerPortal],
                environment.featureScope as Scope,
                undefined,
                undefined,
                error => {
                    console.error('Feature Flag init failed: ', error);
                    observer.error(error);
                },
                flags => {
                    console.debug('Feature Flags init successfully: ');
                    observer.next(flags);
                    observer.complete();
                }
            );
        });
    }

    isEnabled(
        featureCode: string,
        { overrideWithLocal = true, useCache = true }: { overrideWithLocal?: boolean; useCache?: boolean } = {}
    ) {
        return this.get(useCache).pipe(
            map(flags => {
                const isEnabledLocal = environment.features[getFeatureName(featureCode)];
                if (overrideWithLocal !== false && typeof isEnabledLocal === 'boolean') return isEnabledLocal;
                return isFeatureEnabled(FFScope.BrokerPortal, featureCode, !!isEnabledLocal);
            })
        );
    }

    private fetch() {
        this.cacheInitialized = true;
        this.init$.next();
    }

    private get(useCache = true) {
        if (useCache === false || !this.cacheInitialized) {
            this.fetch();
        }
        return this.cache$;
    }
}

export const isEnabledOverwriteFunc = (project: string, flagCode: string): boolean => {
    if (project === FFScope.BrokerPortal) {
        return !!environment.features[getFeatureName(flagCode)];
    }

    return false;
};
