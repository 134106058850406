<h2 mat-dialog-title>
    Feed In Tariff legislation
</h2>
<mat-dialog-content>
    <p>
        A customer with an existing solar PV generating system who receives feed-in credits under South 
        Australia’s statutory feed-in scheme will no longer be eligible to receive feed-in credits under 
        the scheme if [the capacity of their PV system is increased or] an energy storage device is installed.
    </p>
    <p>
        The customer’s eligibility to receive feed-in credits will cease from the date the customer’s
        application for approval to connect the home battery system is submitted to SA Power Networks.
    </p>
    <div>
      <button mat-raised-button mat-dialog-close class="spaced-button">I understand</button>
    </div>
</mat-dialog-content>
  