import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstCharUppercase'
})
export class FirstCharUppercasePipe implements PipeTransform {
    transform(str: string) {
        return !str ? str : str.charAt(0).toUpperCase() + str.slice(1);
    }
}
