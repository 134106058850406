import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonUiModule } from '@brokerportal/common/common.module';
import { UiModule } from '@brokerportal/modules/ui.module';
import { LocalStorageModule } from 'angular-2-local-storage';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from 'environments/environment';
import { NgRadio } from 'ng-radio';
import { RoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './common/auth/auth.interceptor';
import { rateSetterOAuthStorageFactory } from './common/auth/custom-oauth-storage';
import { HttpRequestInterceptor } from './common/auth/HttpRequestInterceptor';
import { ErrorInterceptor } from './common/interceptor/error.interceptor';
import { CustomBroadcastChannel } from './common/services/custom-broadcast-channel';
import { WindowRef } from './common/services/window';
import { MaterialComponentsModule } from './material-components.module';
import { AdminUiModule } from './ui/admin/admin.module';

const providers: any = [
    NgRadio,
    WindowRef,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: OAuthStorage, useFactory: rateSetterOAuthStorageFactory },
    CustomBroadcastChannel
];

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RoutingModule,
        BrowserAnimationsModule,
        MaterialComponentsModule,
        CommonUiModule,
        UiModule,
        AdminUiModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.api.baseUrl],
                sendAccessToken: false
            }
        }),
        Angulartics2Module.forRoot([Angulartics2GoogleAnalytics], {
            pageTracking: {
                clearQueryParams: true,
                clearHash: true
            }
        }),
        LocalStorageModule.forRoot({
            prefix: 'rs-bp',
            storageType: 'sessionStorage' // localStorage
        }),
        ReactiveFormsModule
    ],
    declarations: [AppComponent],
    entryComponents: [],
    providers: providers,
    bootstrap: [AppComponent]
})
export class AppModule {}
