export enum MailTemplate {
    /* A clean, plain co-branded email */
    Empty = 0,
    /* Email sent when creating a new application for a user */
    StartNewApplication = 1,
    /* Email intructing the user to complete their identity details */
    CompleteIdentityVerification = 2,
    /* Email intructing the user to complete their bank details */
    CompleteIncomeVerification = 3,
    /* When inviting a customer to continue their application */
    ContinueApplication = 4,
    /* Email reminding customer to sign the contract */
    ContractSigningReminder = 5,
    /* Email sent when creating a new application for a user with prefilled fields and pre determined loan amount */
    StartNewApplicationWithPrefilledLoanApp = 6
}
