import { FormControl } from '@angular/forms';

export function acceptedValuesValidator(acceptedValues: any[], validWhenEmpty = false) {
    return (control: FormControl): { [key: string]: any } => {
        if (validWhenEmpty && acceptedValues.length === 0) {
            return;
        }
        if (acceptedValues.indexOf(control.value) === -1) {
            return { unacceptedValue: true };
        }
    };
}
