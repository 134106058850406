import { DateTime } from 'luxon';
import { LocalStorageService } from 'angular-2-local-storage';
import { CachedValue, CachedSetting } from '../models';
import { Injectable } from '@angular/core';

@Injectable()
export class CachedService {
    private key: string;
    private expiryMinutes: number;
    constructor(private localStorage: LocalStorageService) {}

    public configure(key: string, expiryMinutes: number) {
        this.key = key;
        this.expiryMinutes = expiryMinutes;
    }
    // clear the localstorage. This is called when user logs out.
    public clear() {
        this.localStorage.remove(this.key);
    }

    public GetCached(objKey: string) {
        let settings = this.localStorage.get<CachedSetting>(this.key);
        const targetSetting: CachedValue = settings ? settings[objKey] : null;
        if (targetSetting && !this.isConfigExpired(targetSetting.storedAtTimestamp)) {
            return targetSetting.value;
        }

        return null;
    }

    public SetCached(objKey: string, value: any) {
        let settings = this.localStorage.get<CachedSetting>(this.key);
        if (!settings) settings = {};
        const updatedSetting: CachedValue = {
            value,
            storedAtTimestamp: Date.now()
        };

        settings[objKey] = updatedSetting;
        this.localStorage.set(this.key, settings);
    }

    private isConfigExpired(storedAtTimestamp: number) {
        const configStoredAt = DateTime.fromJSDate(new Date(storedAtTimestamp));
        const diff = DateTime.local().diff(configStoredAt, ['minute']);
        return diff.minutes >= this.expiryMinutes;
    }
}
