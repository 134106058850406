export enum ReportTableColumn {
    DateAppCommenced,
    ClientName,
    ClientEmail,
    ProductType,
    AmountRequested,
    InvoiceReferenceNumber,
    ContractAccountNumber,
    Installer,
    CurrentStatus,
    ActionRequired
}
