// C.f. LoanPurpose in Venus
export enum LoanPurpose {
    NotSet = -1,
    HomeImprovement = 1,
    Car = 2,
    Holiday = 3,
    MedicalExpenses = 4,
    Wedding = 5,
    SchoolFees = 6 /* Unused */,
    Business = 7,
    Other = 8,
    Motorbike = 9,
    DebtConsolidation = 10,
    PayoffCreditCard = 11,
    PayoffLoan = 12,
    Investment = 13,
    MajorEvent = 14,
    MajorPurchase = 15,
    SecondProperty = 16,
    BetterRate = 17,
    HomeFurnishings = 18,
    LegalCosts = 19,
    Education = 20,
    Boat = 21,
    CarRepairs = 22,
    LegalLoan = 23,
    Cosmetics = 24,
    FuneralExpenses = 25,
    Solar = 26,
    MinorPurchase = 27,
    MedicalEquipment = 28,
    DentalExpenses = 29,
    HospitalExpenses = 30,
    SolarEnergy = 31,
    AdditionalFunds = 32,
    SolarPanels = 33,
    SolarWaterHeater = 34,
    AirSourceHeatPump = 35,
    Battery = 36,
    Lighting = 37,
    PowerFactorCorrection = 38,
    AirConditioning = 39,
    Truck = 40,
    VariableSpeedDrive = 41,
    VariableFrequencyDrive = 42
}
