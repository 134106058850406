import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'bp-card-loading-overlay',
    templateUrl: './card-loading-overlay.component.html',
    styleUrls: ['./card-loading-overlay.component.scss']
})
export class CardLoadingOverlayComponent {
    @Input()
    isLoading = false;
}
