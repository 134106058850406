
<div fxLayout="row" class="heading-box">
    <h2 mat-dialog-title>Upload verification documents</h2>
    <div> 
        <mat-dialog-actions class="top-actions"><button mat-raised-button (click)="closeHandler()">Close</button></mat-dialog-actions>
    </div>
</div>


<mat-dialog-content>
    <div fxLayout="row" >
        <p>
            Please upload {{ quoteDocumentUploadTypeLabel }}<span *ngIf="multiUpload">. 
                <br/>You can upload files individually, or together as a zip file.</span>
        </p>
    </div>
    <div>
        <div *ngIf="multiUpload" class="thumbnail-box" fxLayout="row">
            <div class="single-thumbnail" *ngFor="let thumbnail of thumbnails" [title]="thumbnail.name">
                <img *ngIf="thumbnail.isImage" class="upload-thumbnail" [src]="thumbnail.src" />
                <mat-icon *ngIf="!thumbnail.isImage">insert_drive_file</mat-icon>
            </div>
        </div>
        <kendo-upload [autoUpload]="false" [saveUrl]="uploadSaveUrl" [restrictions]="uploadRestrictions" (complete)="completeEventHandler($event)"
            (upload)="uploadEventHandler($event)" [multiple]="false" [withCredentials]="true" *ngIf="!isFeatureUploadToDSSEnabled()">
        </kendo-upload>
        <kendo-upload [ngClass]="{'multi-upload' : multiUpload}" [autoUpload]="multiUpload" 
            [saveUrl]="uploadSaveUrl" [restrictions]="uploadRestrictions" (complete)="completeEventHandler($event)"
            (upload)="uploadEventHandler($event)" (select)="selectEventHandler($event)" 
            (error)="uploadFinishedHandler()" (success)="uploadFinishedHandler()"
            [multiple]="multiUpload" [withCredentials]="true" [saveField]="'file'" *ngIf="isFeatureUploadToDSSEnabled()">
            <kendo-upload-messages *ngIf="multiUpload" select="Select or drag and drop files..."></kendo-upload-messages>
        </kendo-upload>
    </div>
</mat-dialog-content>

