import { Pipe, PipeTransform } from '@angular/core';
import { CreditStatus } from '../enums/credit-status.enum';
import { CreditStatusService } from '../services';

@Pipe({ name: 'creditStatusDescription' })
export class CreditStatusDescriptionPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: CreditStatus): string {
        // Value is expected to be string version of CreditStatus, e.g. 'NoStatus'
        return this.creditStatusService.getCreditStatusDescription(value);
    }
}
