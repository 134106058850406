<ng-container>
    <div
        *ngIf="isViewMode"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="20px"
        fxLayoutGap.lt-md="0px"
        style="margin-bottom: 25px;"
    >
        <div fxFlex="40%;" fxFlex.xs="80%">{{ fieldTitle || (fieldDisplayName | titlecase) }}</div>
        <div fxFlex="40%;" fxFlex.xs="80%">
            {{ selectedPackage ? selectedPackage.name : controlFromParent.value || 'Unspecified' }}
        </div>
    </div>

    <div
        *ngIf="!isViewMode"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="20px"
        fxLayoutGap.lt-md="0px"
        style="margin-top: 25px;"
        class="text-field"
    >
        <div *ngIf="!wideField" fxFlex="20%;" fxFlex.xs="80%" class="label">{{ fieldDisplayName }}</div>
        <div *ngIf="!wideField && !inputWidth" fxFlex="20%;" class="label">

        </div>
        <mat-form-field fxFlex="{{ getInputFlexRatio() }}" fxFlex.xs="80%">
            <input
                matInput
                [formControl]="displayedControl"
                name="{{ category + 'Package' }}"
                [matAutocomplete]="auto"
                #autoTrigger="matAutocompleteTrigger"
                placeholder="Start typing to search for {{ indefiniteArticleText }} {{ sentenceName }}"
                [attr.data-cy]="'input.' + category + 'Package'"
            />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="_getDisplayName">
                <mat-option *ngFor="let pck of filteredPackages | async" [value]="pck"
                    [disabled]="!_isSelectable(pck)">{{ pck.name }}</mat-option>
            </mat-autocomplete>

            <button
                *ngIf="displayedControl.value"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearPackage($event);autoTrigger.openPanel();"
                data-cy="button.clearPackage"
            >
                <mat-icon class="large-icon">clear</mat-icon>
            </button>
            <mat-icon matSuffix>search</mat-icon>

            <mat-error *ngIf="(displayedControl.errors | firstError) === 'required'">
                Please select {{ indefiniteArticleText }} {{ sentenceName }}
            </mat-error>

            <mat-error *ngIf="!optional && (displayedControl.errors | firstError) === 'idNotFound'">
                Please select a valid {{ sentenceName }} from the list
            </mat-error>

            <mat-error *ngIf="optional && (displayedControl.errors | firstError) === 'idNotFound'">
                Please select a valid {{ sentenceName }} from the list, or enter no {{ sentenceName }}
            </mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="category === 'battery' || showPackageDescription" fxLayout="row" fxLayout.lt-md="column">
        <package-details-component
            fxFlex="80%"
            [package]="selectedPackage"
            [collapsible]="!isViewMode"
            title="{{ packageDescriptionTitle || 'The following system specifics must be included within the invoice:'}}"
        >
        </package-details-component>
    </div>
</ng-container>
