import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { SidenavItem } from './sidenav-item/sidenav-item.model';
import { SidenavService } from './sidenav.service';
import { range, keys, isArray, sortBy } from 'lodash-es';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
    selector: 'bp-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit, OnDestroy {
    numberOfPlaceholders = 5;
    placeholderItems = Array<Number>();

    items: SidenavItem[];

    private itemsSubscription: Subscription;
    private routerEventsSubscription: Subscription;
    private configRefresh$: Subscription;

    env: any;

    constructor(private sidenavService: SidenavService, private router: Router) {
        this.placeholderItems = range(this.numberOfPlaceholders);
        this.env = environment;
    }

    ngOnInit() {
        this.itemsSubscription = this.sidenavService.items$.subscribe((items: SidenavItem[]) => {
            this.items = this.sortRecursive(items, 'position');
        });

        this.routerEventsSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.sidenavService.nextCurrentlyOpenByRoute(event.url);
            }
        });

        this.sidenavService.buildItems();
    }

    toggleIconSidenav() {
        this.sidenavService.isIconSidenav = !this.sidenavService.isIconSidenav;
    }

    isIconSidenav(): boolean {
        return this.sidenavService.isIconSidenav;
    }

    sortRecursive(array: SidenavItem[], propertyName: string) {
        let that = this;

        array.forEach(function(item) {
            let ks = keys(item);
            ks.forEach(function(key) {
                if (isArray(item[key])) {
                    item[key] = that.sortRecursive(item[key], propertyName);
                }
            });
        });

        return sortBy(array, propertyName);
    }

    ngOnDestroy() {
        if (this.itemsSubscription) {
            this.itemsSubscription.unsubscribe();
        }

        if (this.routerEventsSubscription) {
            this.routerEventsSubscription.unsubscribe();
        }

        if (this.configRefresh$) {
            this.configRefresh$.unsubscribe();
        }
    }
}
