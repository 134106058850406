import { Injectable } from '@angular/core';
import { ProductTag } from '../enums';
import dot from 'dot-object';

const RENEWABLE_FIELDS_TO_APPLICATION_MODEL_PATH_MAPPING = {
    clientEmail: 'contact.email',
    brokerEmail: 'broker.brokerEmail',
    systemCost: 'loan.systemCost',
    deposit: 'loan.deposit',
    term: 'loan.term',
    electricityBillSavingsFrequency: 'system.electricityBillSavingsFrequency',
    estimatedElectricityBillSavings: 'system.estimatedElectricityBillSavings',
    systemType: 'system.systemType',
    solarPanelSize: 'system.solarPanelSize',
    batterySize: 'system.batterySize',
    pricingExplanationConfirmation: 'system.pricingExplanationConfirmation',
    additionalLoanType: 'loan.additionalLoanType'
};

const PERSONAL_LOAN_BROKER_FIELDS_TO_APPLICATION_MODEL_PATH_MAPPING = {
    brokerEmail: 'broker.brokerEmail'
};

const AUTO_BROKER_FIELDS_TO_APPLICATION_MODEL_PATH_MAPPING = {
    brokerEmail: 'broker.brokerEmail'
};

const EXTRA_FIELD_PATHS_MAPPING = {
    [ProductTag.RenewableInterestFree]: {
        greenIdDriverLicenceNumber: 'extraFields[0]',
        greenIdDriverLicenceState: 'extraFields[1]',
        invoiceNumber: 'extraFields[7]',
        contractAccountNumber: 'extraFields[7]',
        renewableSubsidyApplication: 'extraFields[4]',
        clientLocation: 'extraFields[5]'
    },
    [ProductTag.GreenLoan]: {
        greenIdDriverLicenceNumber: 'extraFields[0]',
        greenIdDriverLicenceState: 'extraFields[1]',
        invoiceNumber: 'extraFields[5]',
        nmi: 'extraFieldsDict.nmi',
        renewableSubsidyApplication: 'extraFields[8]',
        clientLocation: 'extraFields[7]'
    }
};

const RENEWABLE_EXTRA_FIELD_KEY_MAPPING = {
    greenIdDriverLicenceNumber: 'GreenIdDriverLicenceNumber',
    greenIdDriverLicenceState: 'GreenIdDriverLicenceState',
    invoiceNumber: 'invoicenumber',
    contractAccountNumber: 'contractaccountnumber',
    nmi: 'NMI',
    renewableSubsidyApplication: 'renewablesubsidyapplication',
    clientLocation: 'renewableofferclientlocation'
};

@Injectable()
export class FieldMappingService {
    mapFieldsToApplicationModel(productTag: ProductTag, data: {}, additionalData: {} = null) {
        const appModel = {};
        if (data) {
            const flattenedData = {};

            switch (productTag) {
                case ProductTag.RenewableInterestFree:
                case ProductTag.GreenLoan:
                    for (const [key, value] of Object.entries(data)) {
                        this.buildFlattenedDataFromRenewableField(flattenedData, key, value, productTag);
                    }
                    // Converts dotted data to nested object
                    Object.assign(appModel, dot.object(flattenedData));
                    break;
                case ProductTag.PersonalLoan:
                    for (const [key, value] of Object.entries(data)) {
                        this.buildFlattenedDataFromPersonalLoanBrokerField(flattenedData, key, value);
                    }
                    // Converts dotted data to nested object
                    Object.assign(appModel, dot.object(flattenedData));
                    break;
                case ProductTag.CarLoan:
                case ProductTag.CommercialCarLoan:
                    for (const [key, value] of Object.entries(data)) {
                        this.buildFlattenedDataFromAutoBrokerField(flattenedData, key, value);
                    }
                    // Converts dotted data to nested object
                    Object.assign(appModel, dot.object(flattenedData));
                    break;
                default:
                    break;
            }
        }

        Object.assign(appModel, additionalData);
        return appModel;
    }

    private buildFlattenedDataFromRenewableField = (
        flattenedObject: object,
        mappingKey: string,
        value: any,
        productTag: ProductTag
    ) => {
        const path = RENEWABLE_FIELDS_TO_APPLICATION_MODEL_PATH_MAPPING[mappingKey];
        if (path) {
            Object.assign(flattenedObject, { [path]: value });
        }

        const extraFieldPathsMap = EXTRA_FIELD_PATHS_MAPPING[productTag];
        if (extraFieldPathsMap) {
            this.buildExtraFieldsData(
                flattenedObject,
                extraFieldPathsMap,
                RENEWABLE_EXTRA_FIELD_KEY_MAPPING,
                mappingKey,
                value
            );
        }
    };

    private buildFlattenedDataFromPersonalLoanBrokerField = (
        flattenedObject: object,
        mappingKey: string,
        value: any
    ) => {
        const path = PERSONAL_LOAN_BROKER_FIELDS_TO_APPLICATION_MODEL_PATH_MAPPING[mappingKey];
        if (path) {
            Object.assign(flattenedObject, { [path]: value });
        }
    };

    private buildFlattenedDataFromAutoBrokerField = (flattenedObject: object, mappingKey: string, value: any) => {
        const path = AUTO_BROKER_FIELDS_TO_APPLICATION_MODEL_PATH_MAPPING[mappingKey];
        if (path) {
            Object.assign(flattenedObject, { [path]: value });
        }
    };

    private buildExtraFieldsData = (
        targetObject: object,
        pathsMap: object,
        keyMap: object,
        key: string,
        value: any
    ) => {
        const path = pathsMap[key];
        const extraFieldKey = keyMap[key];
        if (path && extraFieldKey) {
            Object.assign(targetObject, {
                [`${path}.key`]: extraFieldKey,
                [`${path}.value`]: value
            });
        }
    };
}
