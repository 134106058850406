import { ExtraFieldKeys, RenewableExtraFieldKeys } from '../enums';
import { Dictionary } from '../models';
import { FieldModel } from '../models/application/finance-application-details-model';

export const getValueField = (fields: Array<FieldModel>, fieldName: string): FieldModel =>
    fields?.find(_ => _.name.toLowerCase() === fieldName.toLowerCase() && _.accepted === true) ??
    fields?.find(_ => _.name.toLowerCase() === fieldName.toLowerCase());

export const getParentField = (fields: Array<FieldModel>, fieldName: string): FieldModel =>
    fields?.find(_ => _.name.toLowerCase() === fieldName.toLowerCase() && _.fields !== undefined);

export const getItemsField = (fields: Array<FieldModel>, fieldName: string): FieldModel =>
    fields?.find(_ => _.name.toLowerCase() === fieldName.toLowerCase() && _.items !== undefined);

export const getExtraFieldsDictionary = (fields: FieldModel[]): Dictionary<string> => {
    const extraFieldKeys = new Set(
        [...Object.values(ExtraFieldKeys), ...Object.values(RenewableExtraFieldKeys)].map(k => k.toLowerCase())
    );
    const fieldsOccurence = fields.reduce<Dictionary<number>>((acc, cur) => {
        acc[cur.name] = ++acc[cur.name] || 1;
        return acc;
    }, {});
    return fields.reduce<Dictionary<string>>((acc, cur) => {
        if (fieldsOccurence[cur.name] === 1 || cur.accepted) {
            if (cur.name === 'extraFieldsDict' && cur.fields?.length > 0) {
                //Fetch extra fields under 'extraFieldsDict' to result.
                cur.fields.forEach(f => (acc[f.name] = f.value));
            } else if (extraFieldKeys.has(cur.name.toLowerCase())) {
                acc[cur.name] = cur.value;
            }
        }
        return acc;
    }, {});
};
