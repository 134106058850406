import { FeatureFlagService } from './feature-flag.service';
import { Injectable } from '@angular/core';
import { WorkflowModel } from '@brokerportal/common/models';
import { ProductTag } from '../enums';
import { BankVerificationStatus } from '../enums/bank-verification-status.enum';
import { CreditStatus } from '../enums/credit-status.enum';
import { EmploymentType } from '../enums/employment-type.constants';
import { IdentityVerificationStatus } from '../enums/identity-verification-status.enum';
import { LoanPurpose } from '../enums/loan-purpose.enum';
import { OwnCurrentHome } from '../enums/own-current-home.enum';
import { StepName } from '../enums/step-name.enum';
import { ApplicationDetailModel } from '../models/application/application-detail.model';
import {
    LoanConditionPredefinedApprovalConditions,
    LoanConditionStatus
} from '../models/application/application-details-loan-condition.model';
import { environment } from 'environments/environment';
import { PropertyOwnership } from '../enums/property-ownership.enum';

@Injectable()
export class CreditStatusService {
    // C.f. CreditStatus enum
    private creditStatusDescriptions = {
        NoStatus: 'No status has been set',
        Declined: 'The application has been declined (either by system or manual underwriter)',
        Approved: 'The application has been approved (either by system or manual underwriter)',
        Created: 'The application has been created',
        Quoted: 'The user has received a RateEstimate but not been declined by it',
        Applied: 'The user has filled in the full application',
        Cancel: "The application was cancelled at the user's request",
        Expired: ' The application has expired - the borrower failed to finish the application in time',
        Fraud: 'The application has been identified as fraudulent',
        Reinstated:
            "The application previously expired or was cancelled and has now been re-instated at the borrower's request",
        Incomplete: 'The application has been identified as fraudulent',
        Cancel_NoReinstate: 'The application has been cancelled and the borrower wants no further contact',
        Credit_Expired: 'The application was approved but the borrower did not take up the application in good time',
        Credit_Withdrawn: 'The application was approved, but then the customer asked for the credit to be cancelled',
        Pending_Processing: 'This is for a loan consolidation request that is waiting to be processed',
        COMPLETE: 'The application was approved and the borrower has taken out the loan',
        Credit_Reinstate:
            'The credit was withdrawn and then the customer asked for it to be reinstated - it then needs to be approved by the credit team',
        Holding:
            'The request has been completed by the user but is waiting for additional checks before being added to the main queue- used by brokers who want to review the application before sending to RS for instance',
        ConditionallyApproved: 'Currently not used by standard car loan security process',
        Accepted: 'Additional funds request will reach accepted after the borrower accepts the loan contract',
        Abandoned:
            "There hasn't been any action taken on this application for a period of time and hence the application will be abandoned",
        ApprovedPendingReview:
            'Application already approved but some details changed hence the state is set to pending to review in order to give underwriters a chance to review application based on updated details'
    };

    // C.f. LoanPurpose in Venus
    private loanPurposeDescriptions = {
        NotSet: 'Not Set',
        HomeImprovement: 'Home Improvement',
        Car: 'Vehicle Loan',
        Holiday: 'Holiday',
        MedicalExpenses: 'Medical Expenses',
        Wedding: 'Wedding',
        SchoolFees: 'School fees' /* Unused */,
        Business: 'Business',
        Other: 'Other',
        Motorbike: 'Motorbike',
        DebtConsolidation: 'Debt Consolidation',
        PayoffCreditCard: 'Pay Off Credit Card',
        PayoffLoan: 'Pay Off Loan',
        Investment: 'Investment',
        MajorEvent: 'Major Event',
        MajorPurchase: 'Major Purchase',
        SecondProperty: 'Second Property',
        BetterRate: 'Better Rate',
        HomeFurnishings: 'Home Furnishings',
        LegalCosts: 'Legal Costs',
        Education: 'Education',
        Boat: 'Boat',
        CarRepairs: 'Car repairs',
        LegalLoan: 'Legal Loan',
        Cosmetics: 'Health and Beauty or Cosmetics',
        FuneralExpenses: 'Funeral Expenses',
        Solar: 'Solar',
        MinorPurchase: 'Minor Purchase',
        MedicalEquipment: 'Medical Equipment',
        DentalExpenses: 'Dental Expenses',
        HospitalExpenses: 'Hospital Expenses',
        SolarEnergy: 'Solar Energy',
        AdditionalFunds: 'Additional Funds',
        SolarPanels: 'Solar panels',
        SolarWaterHeater: 'Solar water heater',
        AirSourceHeatPump: 'Air source heat pump',
        Battery: 'Battery',
        Lighting: 'Lighting',
        PowerFactorCorrection: 'Power factor correction',
        AirConditioning: 'Air conditioning',
        Truck: 'Truck',
        VariableSpeedDrive: 'Variable speed drive',
        VariableFrequencyDrive: 'Variable frequency drive'
    };

    private employmentDescriptions = {
        FullTime: 'Full time',
        PartTime: 'Part time',
        Contract: 'Contact',
        SelfEmployed: 'Self employed',
        SoleTrader: 'Sole trader',
        Student: 'Student',
        Houseperson: 'Houseperson',
        Retired: 'Retired',
        CurrentlyUnemployed: 'Unemployed',
        Casual: 'Casual'
    };

    private homeOwnershipDescriptions = {
        OwnAHomeWithMortgage: 'Own home with a mortgage',
        OwnAHomeWithoutMortgage: 'Own home without a mortgage',
        TenantWithMortgage: 'Tenant (with mortgage on another property)',
        TenantWithoutMortgage: 'Tenant (renting, living with parents, etc.)'
    };

    private propertyOwnershipDescriptions = {
        ownResidentialProperty: 'My client owns the residential property declared above',
        ownResidentialPropertyAtDifferentAddress: 'My client owns a residential property at a different address',
        ownNoResidentialProperty: 'My client does not own a residential property',
        residentialPropertyownedbySpouse: `My client's spouse owns the residential property declared above`
    };

    private productTagDescriptions = {
        Unknown: 'Unknown',
        PersonalLoan: 'Personal loan',
        GreenLoan: 'Green loan',
        BusinessFinance: 'Business finance',
        CarLoan: 'Vehicle loan',
        PointOfSale: 'Point of sale',
        DeferredPaymentAgreement: 'Deferred payment agreement',
        SwiftWorkingCapital: 'Swift working capital',
        LegalLoan: 'Legal loan',
        SARenewableEnergy: 'SA Home Battery Scheme',
        NSWRenewableEnergy: 'NSW Empowering Homes Program',
        RenewableBrokerLoan: 'Renewable loan',
        RenewableInterestFree: 'Zero interest payment plan',
        CommercialCarLoan: 'Commercial car loan'
    };

    // DEVNOTE: Comments below are Tooltip values to be shown.

    private identityCheckResultDescriptions = {
        Unavailable: 'Unavailable', // An error has occurred. Please contact RateSetter support for assistance
        NotChecked: 'Not checked', // You or your client are yet to begin identity verification
        Pass: 'Complete', // We have successfully verified this client's name, address and date of birth
        Refer: 'Incomplete', // We are yet to verify this client's name, address or date of birth
        Error: 'Error', // An error has occurred. Please contact RateSetter support for assistance
        NotMatched: 'Not matched', // An error has occurred. Please contact RateSetter support for assistance
        Picklist: 'Incomplete', // We are yet to verify this client's name, address or date of birth
        Declined: 'Declined', // An error has occurred. Please contact RateSetter support for assistance
        Skipped: 'Incomplete', // We are yet to verify this client's name, address or date of birth
        DocumentProvided: 'Document provided' // "We've received an identity document that was uploaded by you or your client"  [UI only]
    };

    private identityCheckResultExplanations = {
        Unavailable: `An error has occurred. Please contact ${environment.constants.branding.title} support for assistance`,
        NotChecked: 'You or your client are yet to begin identity verification',
        Pass: "We have successfully verified this client's name, address and date of birth",
        Refer: "We are yet to verify this client's name, address or date of birth",
        Error: `An error has occurred. Please contact ${environment.constants.branding.title} support for assistance`,
        NotMatched: `An error has occurred. Please contact ${environment.constants.branding.title} support for assistance`,
        Picklist: "We are yet to verify this client's name, address or date of birth",
        Declined: `An error has occurred. Please contact ${environment.constants.branding.title} support for assistance`,
        Skipped: "We are yet to verify this client's name, address or date of birth",
        DocumentProvided: "We've received an identity document that was uploaded by you or your client"
    };

    private predefinedApprovalConditions = {
        TaxInvoiceRequest: 'Tax Invoice Request',
        PrivateSaleInvoiceTemplate: 'Private Sale Invoice Template',
        InsuranceCertificate: 'Insurance Certificate',
        IncomeAndExpenses: 'Income and expenses',
        Identity: 'Identity',
        YodleeAccountBalanceExceedingUpfrontPayment: 'Yodlee Account Balance Exceeding Upfront Payment',
        FraudCheckMustBePerformed: 'A fraud check must be performed on the account',
        BankAccountValidation: 'Bank Account details must be validated',
        PrimaryLoanCreated: 'Additional funds requests must be approved after the creation of the primary loan',
        ExistingFinancierDetails: 'Existing financier details must be obtained',
        ExistingSecurityDetails: 'Existing security details must be obtained',
        BankStatements: 'Bank statements must be obtained',
        NoExistingSecurity: 'A PPSR Search must not identify existing security',
        // Green loan workflows
        UnderwritingCommenced: 'Underwriting commenced',
        ApprovedPendingInstallation: 'Approved pending installation',
        SubsidyApproved: 'Home Battery Scheme subsidy must be approved ',
        ApplicationDocumentsProvided: 'Application documents must be provided'

        // End green loan workflows
    };

    private riskTierDescriptions = {
        Tier1: 'Tier 1',
        Tier2: 'Tier 2',
        Tier3: 'Tier 3',
        Tier4: 'Tier 4'
    };

    constructor(private featureFlagService: FeatureFlagService) {}

    getHomeOwnershipDescription(value: OwnCurrentHome) {
        return this.homeOwnershipDescriptions[OwnCurrentHome[value]] || 'N/A';
    }

    getPropertyOwnershipDescription(value: PropertyOwnership) {
        return this.propertyOwnershipDescriptions[PropertyOwnership[value]] || '-';
    }

    getCreditStatusDescription(value: CreditStatus) {
        return this.creditStatusDescriptions[CreditStatus[value]] || 'N/A';
    }

    getLoanPurposeDescription(loanPurpose: LoanPurpose) {
        return this.loanPurposeDescriptions[LoanPurpose[loanPurpose]] || 'N/A';
    }

    getEmploymentTypeDescription(employmentType: EmploymentType) {
        return this.employmentDescriptions[EmploymentType[employmentType]] || 'N/A';
    }

    getProductTagDescription(productTag: ProductTag) {
        return this.productTagDescriptions[ProductTag[productTag]] || 'N/A';
    }

    getIdentityStatusDescription(identityStatus: IdentityVerificationStatus) {
        return this.identityCheckResultDescriptions[IdentityVerificationStatus[identityStatus]] || 'N/A';
    }

    getIdentityStatusExplanation(identityStatus: IdentityVerificationStatus) {
        return this.identityCheckResultExplanations[IdentityVerificationStatus[identityStatus]] || 'N/A';
    }

    getPredefinedApprovalConditionDescription(loanCondition: LoanConditionPredefinedApprovalConditions) {
        return this.predefinedApprovalConditions[LoanConditionPredefinedApprovalConditions[loanCondition]] || 'N/A';
    }

    // Mappings (non PB)
    getCurrentStatus(creditStatus: CreditStatus, workflowStep: StepName, identityVerification: string) {
        // NOTE: these mappings are for non-PurpleBricks applications

        switch (creditStatus) {
            case CreditStatus.Quoted: {
                switch (workflowStep) {
                    case StepName.FullApplication: {
                        return 'RateEstimate received';
                    }
                }
            }
            case CreditStatus.Applied: {
                switch (workflowStep) {
                    case StepName.Identity:
                    case StepName.ConditionallyApprovedIdentity: {
                        return 'Application received';
                    }
                    case StepName.BankDetails:
                    case StepName.ConditionallyApprovedBankDetails: {
                        return 'Identity verified';
                    }
                    case StepName.BankDetails:
                    case StepName.ConditionallyApprovedBankDetails: {
                        return 'Identity verified';
                    }
                    case StepName.ApplicationReceived: {
                        return 'Bank statements received';
                    }
                }
            }
            case CreditStatus.ConditionallyApproved: {
                switch (workflowStep) {
                    case StepName.ConditionallyApprovedApplicationReceived: {
                        return 'Conditionally approved';
                    }
                }
            }
            case CreditStatus.Approved: {
                switch (workflowStep) {
                    case StepName.ApplicationApproved: {
                        return 'Approved';
                    }
                }
            }
            case CreditStatus.Declined: {
                switch (workflowStep) {
                    case StepName.Refer: {
                        return 'Declined RateEstimate';
                    }
                    case StepName.ReferFullApplication: {
                        return 'Declined application';
                    }
                }
            }
            case CreditStatus.Cancel: {
                return 'Cancelled';
            }
            case CreditStatus.Abandoned:
            case CreditStatus.Expired: {
                return 'Abandoned';
            }
        }

        if (workflowStep === StepName.Refer) {
            return 'Referred';
        }

        if (workflowStep === StepName.ApplicationPendingReview) {
            return 'Approved pending review';
        }

        return 'Unknown';
    }

    getRiskTierDescription(value: string) {
        return this.riskTierDescriptions[value] || 'Unknown';
    }

    // Mappings (PB)
    public getCurrentStatusDPA(creditStatus: CreditStatus, workflowStep: StepName, identityVerification: string) {
        // NOTE: These are mappings for applications that are deferred payment agreements (I.e. PurpleBricks)

        // TODO: below is just a copy and paste of non-DPA. Need to review

        switch (creditStatus) {
            case CreditStatus.Quoted: {
                switch (workflowStep) {
                    case StepName.FullApplication: {
                        return 'RateEstimate received';
                    }
                }
            }
            case CreditStatus.Applied: {
                switch (workflowStep) {
                    case StepName.Identity:
                    case StepName.ConditionallyApprovedIdentity: {
                        return 'Application received';
                    }
                    case StepName.BankDetails:
                    case StepName.ConditionallyApprovedBankDetails: {
                        return 'Identity verified';
                    }
                    case StepName.BankDetails:
                    case StepName.ConditionallyApprovedBankDetails: {
                        return 'Identity verified';
                    }
                    case StepName.ApplicationReceived: {
                        return 'Bank statements received';
                    }
                }
            }
            case CreditStatus.ConditionallyApproved: {
                switch (workflowStep) {
                    case StepName.ConditionallyApprovedApplicationReceived: {
                        return 'Conditionally approved';
                    }
                }
            }
            case CreditStatus.Approved: {
                switch (workflowStep) {
                    case StepName.ApplicationApproved: {
                        return 'Approved';
                    }
                }
            }
            case CreditStatus.Declined: {
                switch (workflowStep) {
                    case StepName.Refer: {
                        return 'Declined RateEstimate';
                    }
                    case StepName.ReferFullApplication: {
                        return 'Declined application';
                    }
                }
            }
            case CreditStatus.Cancel: {
                return 'Cancelled';
            }
            case CreditStatus.Abandoned:
            case CreditStatus.Expired: {
                return 'Abandoned';
            }
        }

        if (workflowStep === StepName.Refer) {
            return 'Referred';
        }

        if (workflowStep === StepName.ApplicationPendingReview) {
            return 'Approved pending review';
        }

        return 'Unknown';
    }

    public isRateEstimateComplete(creditStatus: CreditStatus): boolean {
        if (
            [
                CreditStatus.Quoted,
                CreditStatus.Applied,
                CreditStatus.ConditionallyApproved,
                CreditStatus.Accepted,
                CreditStatus.ApprovedPendingReview,
                CreditStatus.Pending_Processing,
                CreditStatus.Reinstated,
                CreditStatus.Incomplete,
                CreditStatus.Expired,
                CreditStatus.Approved,
                CreditStatus.Credit_Expired,
                CreditStatus.Credit_Withdrawn,
                CreditStatus.Abandoned
            ].includes(creditStatus)
        ) {
            return true;
        }
        return false;
    }

    public isApplicationComplete(creditStatus: CreditStatus): boolean {
        if (
            [
                CreditStatus.Applied,
                CreditStatus.ConditionallyApproved,
                CreditStatus.Accepted,
                CreditStatus.ApprovedPendingReview,
                CreditStatus.Approved,
                CreditStatus.Credit_Expired,
                CreditStatus.Credit_Withdrawn,
                CreditStatus.Abandoned
            ].includes(creditStatus)
        ) {
            return true;
        }
        return false;
    }

    public isAppliedPreApproval(creditStatus: CreditStatus): boolean {
        if (
            [CreditStatus.Applied, CreditStatus.ConditionallyApproved, CreditStatus.ApprovedPendingReview].includes(
                creditStatus
            )
        ) {
            return true;
        }
        return false;
    }

    public isApplicationDocumentsComplete(stepName: StepName): boolean {
        if (stepName > StepName.ApplicationDocuments) {
            return true;
        }
        return false;
    }

    public isIdentityComplete(
        identityStatus: IdentityVerificationStatus,
        stepName: StepName,
        productTag: ProductTag
    ): boolean {
        const isGreenWorkflow = this.isRenewableProductTag(productTag);
        if (identityStatus === IdentityVerificationStatus.Pass && (isGreenWorkflow || stepName >= StepName.Identity)) {
            return true;
        }
        return false;
    }

    public isIdentityInProgress(stepName: StepName): boolean {
        if (stepName > StepName.Identity) {
            return true;
        }
        return false;
    }

    public isBankStatementsComplete(
        bankStatus: BankVerificationStatus,
        application: ApplicationDetailModel,
        stepName: StepName,
        workflow: WorkflowModel,
        productTag: ProductTag
    ): boolean {
        if (application.hasIncomeDocsProvided && this.shouldPassIncomeOnDocumentUpload(productTag)) {
            return true;
        }

        if (
            this.hasThisCompleteLoanCondition(application, LoanConditionPredefinedApprovalConditions.IncomeAndExpenses)
        ) {
            return true;
        }

        if (productTag === ProductTag.RenewableInterestFree && this.isBeyondBankDetails(stepName)) {
            return true;
        }

        if (bankStatus === BankVerificationStatus.Passed && stepName >= StepName.BankDetails) {
            return true;
        }
        return false;
    }

    public isBeyondBankDetails(stepName: StepName): boolean {
        if (stepName > StepName.BankDetails) {
            return true;
        }
        return false;
    }

    public isBrokerNonCommercial(productTag?: ProductTag) {
        return [ProductTag.PersonalLoan, ProductTag.CarLoan, ProductTag.RenewableBrokerLoan].includes(productTag);
    }

    private shouldPassIncomeOnDocumentUpload(productTag?: ProductTag) {
        return [
            ProductTag.PersonalLoan,
            ProductTag.RenewableBrokerLoan,
            ProductTag.CarLoan,
            ProductTag.GreenLoan
        ].includes(productTag);
    }

    private isPersonalOrCarLoan(productTag?: ProductTag) {
        return [ProductTag.CarLoan, ProductTag.PersonalLoan].includes(productTag);
    }

    public isApplicationDocumentsIncomeComplete(application: ApplicationDetailModel) {
        return (
            application.applicant.automatedBankStatementsCompleted ||
            this.hasThisCompleteLoanCondition(application, LoanConditionPredefinedApprovalConditions.IncomeAndExpenses)
        );
    }

    public isApplicationDocumentsInProgress(application: ApplicationDetailModel): boolean {
        if (application.credit.step !== StepName.ApplicationDocuments) {
            return false;
        }

        const identityComplete = this.isIdentityComplete(
            application.applicant.identityStatus,
            application.credit.step,
            application.productTag
        );
        const incomeComplete = this.isApplicationDocumentsIncomeComplete(application);
        const documentsUploaded = application.hasIncomeDocsProvided || application.hasIdentityDocsProvided;

        return identityComplete || incomeComplete || documentsUploaded;
    }

    public isConditionallyApprovedComplete(
        creditStatus: CreditStatus,
        stepName: StepName,
        application: ApplicationDetailModel
    ): boolean {
        if ([CreditStatus.Approved, CreditStatus.COMPLETE, CreditStatus.ConditionallyApproved].includes(creditStatus)) {
            return true;
        }

        if (
            creditStatus === CreditStatus.ApprovedPendingReview &&
            this.featureFlagService.PlCarNewStatusDisplay() &&
            this.isPersonalOrCarLoan(application.productTag)
        ) {
            return true;
        }

        if (
            [
                StepName.ConditionallyApprovedIdentity,
                StepName.ConditionallyApprovedBankDetails,
                StepName.ConditionallyApprovedApplicationReceived
            ].indexOf(stepName) >= 0
        ) {
            return true;
        }

        return this.hasThisCompleteLoanCondition(
            application,
            LoanConditionPredefinedApprovalConditions.ApprovedPendingInstallation
        );
    }

    public isConditionallyApprovedOrPending(creditStatus: CreditStatus) {
        return [CreditStatus.ConditionallyApproved, CreditStatus.ApprovedPendingReview].includes(creditStatus);
    }

    public isApprovedComplete(creditStatus: CreditStatus): boolean {
        if ([CreditStatus.Accepted, CreditStatus.Approved].indexOf(creditStatus) >= 0) {
            return true;
        }
        return false;
    }

    public isCreditDrawn(creditStatus: CreditStatus): boolean {
        if (creditStatus === CreditStatus.COMPLETE) {
            return true;
        }
        return false;
    }

    public isDeclinedComplete(creditStatus: CreditStatus): boolean {
        if (creditStatus === CreditStatus.Declined) {
            return true;
        }
        return false;
    }

    public isCancelledComplete(creditStatus: CreditStatus): boolean {
        if (
            [
                CreditStatus.Cancel,
                CreditStatus.Cancel_NoReinstate,
                CreditStatus.Credit_Withdrawn,
                CreditStatus.Incomplete
            ].indexOf(creditStatus) >= 0
        ) {
            return true;
        }
        return false;
    }

    public isAbandonedComplete(creditStatus: CreditStatus): boolean {
        if ([CreditStatus.Abandoned, CreditStatus.Credit_Expired, CreditStatus.Expired].indexOf(creditStatus) >= 0) {
            return true;
        }
        return false;
    }

    public hasThisIncompleteLoanCondition(
        application: ApplicationDetailModel,
        loanCondition: LoanConditionPredefinedApprovalConditions
    ) {
        return application.loanConditions.some(lc => {
            return (
                LoanConditionPredefinedApprovalConditions[lc.condition] === loanCondition &&
                lc.status !== LoanConditionStatus.Met
            );
        });
    }
    public hasThisCompleteLoanCondition(
        application: ApplicationDetailModel,
        loanCondition: LoanConditionPredefinedApprovalConditions
    ) {
        return application.loanConditions.some(lc => {
            return (
                LoanConditionPredefinedApprovalConditions[lc.condition] === loanCondition &&
                lc.status === LoanConditionStatus.Met
            );
        });
    }

    public isRenewableProductTag(productTag: ProductTag): boolean {
        return (
            productTag === ProductTag.GreenLoan ||
            productTag === ProductTag.SARenewableEnergy ||
            productTag === ProductTag.NSWRenewableEnergy ||
            productTag === ProductTag.RenewableInterestFree
        );
    }

    public hasApplicationDocumentsStep(workflow: WorkflowModel): boolean {
        if (!workflow.steps) {
            return false;
        }
        return !!workflow.steps.find(step => step.toLowerCase().indexOf('applicationdocuments') >= 0);
    }

    public awaitingIdentityAction(application: ApplicationDetailModel) {
        const isApplied = application.credit.creditStatus === CreditStatus.Applied;
        const isApplicationReceived = application.credit.step === StepName.ApplicationReceived;
        const isBankDetails = application.credit.step === StepName.BankDetails;
        const isInterestFreeConditionallyApprovedOrPending =
            application.productTag === ProductTag.RenewableInterestFree &&
            this.isConditionallyApprovedOrPending(application.credit.creditStatus);

        const identityAllowedAtCreditStatus = isApplied || isInterestFreeConditionallyApprovedOrPending;
        const identityAllowedAtWorkflowStep =
            (!isApplicationReceived && !isBankDetails) || isInterestFreeConditionallyApprovedOrPending;

        return (
            identityAllowedAtCreditStatus &&
            identityAllowedAtWorkflowStep &&
            application.applicant.identityStatus !== IdentityVerificationStatus.Pass &&
            !application.hasIdentityDocsProvided
        );
    }

    public usesIdentityAsLoanCondition(productTag: ProductTag) {
        return productTag === ProductTag.CarLoan || productTag === ProductTag.PersonalLoan;
    }

    public awaitingIncomeAction(application: ApplicationDetailModel) {
        const isApplied = application.credit.creditStatus === CreditStatus.Applied;
        const isApplicationReceived = application.credit.step === StepName.ApplicationReceived;

        return (
            isApplied &&
            !isApplicationReceived &&
            !application.applicant.automatedBankStatementsCompleted &&
            !application.hasIncomeDocsProvided
        );
    }

    public isNonQuoteRenewableLoan(productTag: ProductTag): boolean {
        return productTag === ProductTag.GreenLoan || productTag === ProductTag.RenewableInterestFree;
    }
}
