import { FormControl } from '@angular/forms';

export function nullablePattern(pattern: string | RegExp) {
    return (control: FormControl): { [key: string]: any } => {
        if (!control.value) return;

        let regex = new RegExp(pattern);
        if (!regex.test(control.value)) {
            return { patternError: true };
        }
    };
}
