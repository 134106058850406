import { Pipe, PipeTransform } from '@angular/core';
import { PropertyOwnership } from '../enums/property-ownership.enum';
import { CreditStatusService } from '../services/credit-status.service';

@Pipe({ name: 'propertyOwnershipDescription' })
export class PropertyOwnershipStatusPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: PropertyOwnership): string {
        return this.creditStatusService.getPropertyOwnershipDescription(value);
    }
}
