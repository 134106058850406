import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'bp-responsive-table',
    template: `
        <ng-content></ng-content>
    `
})
export class ResponsiveTableComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
