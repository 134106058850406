import { Injectable } from '@angular/core';
import { PortalConfigurationService } from '@brokerportal/common/services';
import { each, find } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { SidenavItem } from './sidenav-item/sidenav-item.model';
import { shareReplay } from 'rxjs/operators';
import { MenuItemConfiguration } from '@brokerportal/common/models';

@Injectable()
export class SidenavService {
    private _itemsSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
    private _items: SidenavItem[] = [];
    items$: Observable<SidenavItem[]> = this._itemsSubject.asObservable().pipe(shareReplay(1));

    private _currentlyOpenSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
    private _currentlyOpen: SidenavItem[] = [];
    currentlyOpen$: Observable<SidenavItem[]> = this._currentlyOpenSubject.asObservable();

    isIconSidenav: boolean;

    constructor(private portalConfigService: PortalConfigurationService) {}

    buildItems() {
        this._items = [];

        this.portalConfigService.get().subscribe(m => {
            m.menuItems.forEach(itemConfig => {
                const menuItem = this.addItem(
                    itemConfig.description,
                    itemConfig.icon,
                    itemConfig.link,
                    itemConfig.order
                );
                if (Array.isArray(itemConfig.ch)) {
                    this.buildSubMenu(menuItem, itemConfig);
                }
            });
            this._itemsSubject.next(this._items);
        });
    }

    private buildSubMenu(parentMenuItem: SidenavItem, parentMenuItemConfig: MenuItemConfiguration) {
        parentMenuItemConfig.ch.forEach(childItemConfig => {
            let route = childItemConfig.link;
            let description = childItemConfig.description;

            if (description === 'Personal loan new') {
                description = 'Personal loan';
            } else if (description === 'Car loan') {
                description = 'Vehicle loan';
            }

            if (['Declined', 'InProgress', 'Completed', 'Cancelled'].indexOf(childItemConfig.name) > -1) {
                route = `${parentMenuItemConfig.link}/${childItemConfig.link}`;
            }

            const subMenuItem = this.addSubItem(parentMenuItem, description, route, childItemConfig.order);
            if (Array.isArray(childItemConfig.ch)) {
                this.buildSubMenu(subMenuItem, childItemConfig);
            }
        });
    }

    addItem(
        name: string,
        icon: string,
        route: any,
        position: number,
        badge?: string,
        badgeColor?: string,
        customClass?: string
    ) {
        const item = new SidenavItem({
            name: name,
            icon: icon,
            route: route,
            subItems: [],
            position: position,
            badge: badge || null,
            badgeColor: badgeColor || null,
            customClass: customClass || null
        });

        this._items.push(item);

        return item;
    }

    addSubItem(parent: SidenavItem, name: string, route: any, position: number) {
        const item = new SidenavItem({
            name: name,
            route: route,
            parent: parent,
            subItems: [],
            position: position
        });

        parent.subItems.push(item);

        return item;
    }

    removeItem(item: SidenavItem) {
        const index = this._items.indexOf(item);
        if (index > -1) {
            this._items.splice(index, 1);
        }

        this._itemsSubject.next(this._items);
    }

    isOpen(item: SidenavItem) {
        return this._currentlyOpen.indexOf(item) !== -1;
    }

    toggleCurrentlyOpen(item: SidenavItem) {
        let currentlyOpen = this._currentlyOpen;

        if (this.isOpen(item)) {
            if (currentlyOpen.length > 1) {
                currentlyOpen.length = this._currentlyOpen.indexOf(item);
            } else {
                currentlyOpen = [];
            }
        } else {
            currentlyOpen = this.getAllParents(item);
        }

        this._currentlyOpen = currentlyOpen;
        this._currentlyOpenSubject.next(currentlyOpen);
    }

    getAllParents(item: SidenavItem, currentlyOpen: SidenavItem[] = []) {
        currentlyOpen.unshift(item);

        if (item.hasParent()) {
            return this.getAllParents(item.parent, currentlyOpen);
        } else {
            return currentlyOpen;
        }
    }

    nextCurrentlyOpen(currentlyOpen: SidenavItem[]) {
        this._currentlyOpen = currentlyOpen;
        this._currentlyOpenSubject.next(currentlyOpen);
    }

    nextCurrentlyOpenByRoute(route: string) {
        let currentlyOpen = [];

        const item = this.findByRouteRecursive(route, this._items);

        if (item && item.hasParent()) {
            currentlyOpen = this.getAllParents(item);
        } else if (item) {
            currentlyOpen = [item];
        }

        this.nextCurrentlyOpen(currentlyOpen);
    }

    findByRouteRecursive(route: any, collection: SidenavItem[]): SidenavItem {
        let result = find(collection, { route: route } as any) || null;

        if (!result) {
            each(collection, item => {
                if (item.hasSubItems()) {
                    const found = this.findByRouteRecursive(route, item.subItems);

                    if (found) {
                        result = found;
                        return false;
                    }
                }
            });
        }

        return result;
    }

    get currentlyOpen() {
        return this._currentlyOpen;
    }

    getSidenavItemByRoute(route): SidenavItem {
        return this.findByRouteRecursive(route, this._items);
    }
}
