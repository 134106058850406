export enum BillingOwner {
    None = 0,
    Plenti = 1,
    EnergyAustralia = 2,
    EnergyLocals = 3
}

const excludedBillingOwnerSet = new Set([BillingOwner[BillingOwner.None], BillingOwner[BillingOwner.Plenti]]);

// we shouldn't assign Plenti as BillingOwners for new applications
export const AssignableBillingOwners = Object.keys(BillingOwner).filter(bo => !excludedBillingOwnerSet.has(bo));
