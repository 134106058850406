import { Component, Input, OnInit } from '@angular/core';
import { MenuItemConfiguration, PortalConfiguration } from '@brokerportal/common/models';
import { NameOfMenuItem } from 'app/constants';
import { PortalConfigurationService } from '@brokerportal/common/services';

@Component({
    selector: 'bp-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    @Input('sidenav') sidenav: any;
    isFullscreen = false;
    menuItem: Array<MenuItemConfiguration>;

    constructor(private portalConfigurationService: PortalConfigurationService) {}

    ngOnInit() {
        this.portalConfigurationService.get().subscribe((config: PortalConfiguration) => {
            if (!config) return;
            this.menuItem = config.menuItems;
        });
    }
}
