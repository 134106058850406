// C.f. BrokerPortalApiDemand

export enum BrokerPortalDemands {
    CanResetUserIdentity,
    CanUpdateUserIdentity,
    CanResetBankDetails,
    CanSearchApplications,
    CanViewApplications,
    CanCancelApplication,
    CanViewApplicationDetails,
    CanViewApplicationApplicants,
    CanViewApplicationConditions,
    CanViewApplicationDocuments,
    CanUploadApplicationDocuments,
    CanDownloadApplicationDocuments,
    CanViewPortalUserSettings,
    CanUpdatePortalUserSettings,
    CanResetPortalUserPassword,
    CanViewApplicationLoanDrawers,
    CanSendMessages,
    CanViewLibraryDocuments,
    CanAccessLoanApplicationDrawer
}

export enum VenusDemandMapping {
    CanCreateQuote = 'CanCreateQuote_*_*',
    CanPatchQuote = 'CanPatchQuote_*_*',
    CanCancelApplication = 'CanCancelApplication_*_*',
    CanDownloadApplicationDocuments = 'CanDownloadApplicationDocuments_*_*',
    CanResetBankDetails = 'CanResetBankDetails_*_*',
    CanResetPortalUserPassword = 'CanResetPortalUserPassword_*_*',
    CanResetUserIdentity = 'CanResetUserIdentity_*_*',
    CanSearchApplications = 'CanSearchApplications_*_*',
    CanSendMessages = 'CanSendMessages_*_*',
    CanUpdateExtraFields = 'CanUpdateExtraFields_*_*',
    CanUpdatePortalUserSettings = 'CanUpdatePortalUserSettings_*_*',
    CanUpdateUserIdentity = 'CanUpdateUserIdentity_*_*',
    CanUploadApplicationDocuments = 'CanUploadApplicationDocuments_*_*',
    CanViewApplicationApplicants = 'CanViewApplicationApplicants_*_*',
    CanViewApplicationConditions = 'CanViewApplicationConditions_*_*',
    CanViewApplicationDetails = 'CanViewApplicationDetails_*_*',
    CanViewApplicationDocuments = 'CanViewApplicationDocuments_*_*',
    CanViewApplicationLoanDrawers = 'CanViewApplicationLoanDrawers_*_*',
    CanAccessLoanApplicationDrawer = 'CanAccessLoanApplicationDrawer_*_*',
    CanViewApplications = 'CanViewApplications_*_*',
    CanViewLibraryDocuments = 'CanViewLibraryDocuments_*_*',
    CanViewNews = 'CanViewNews_*_*',
    CanViewPortalUserSettings = 'CanViewPortalUserSettings_*_*',
    CanViewThirdPartyBrokerOverviewReport = 'CanViewThirdPartyBrokerOverviewReport_*_*',
    CanConfirmInstallation = 'CanConfirmInstallation_*_*',
    CanCancelQuote = 'CanCancelQuote_*_*',
    CanDeleteApplicationDocuments = 'CanDeleteApplicationDocuments _*_*'
}
