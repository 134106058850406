import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    FinanceResultModel,
    PackageModel,
    PackageCategory,
    PackageRequestScope,
    BuilderModel,
    QuoteCreationModel,
    QuoteDocumentUploadTypeLabels,
    QuoteFormModel,
    QuoteResultModel,
    QuoteRetrievedModel,
    QuoteUploadedDocumentModel,
    SubsidyCalculationModel,
    Scheme,
    HomeOwnershipOrInterest,
    CecPackageModel,
    CecInstallerModel,
    InstallationType,
    ActivitiesModel
} from '../models';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { HalResponse } from '@brokerportal/common/models';
import { toQueryString } from '@brokerportal/common/utils';
import { ActivityModel, QuoteV2CreateModel, QuoteV2PatchModel } from '../models/quoteV2.model';

export interface CecPackagesFilterParams {
    text?: string;
}

@Injectable()
export class QuoteService {
    private baseUrl: String;

    constructor(protected httpClient: HttpClient) {
        this.baseUrl = `${environment.useDirectQuoteService ? environment.quoteServiceHost : environment.api.baseUrl}`;
    }

    createQuote(quoteCreationModel: QuoteCreationModel): Observable<QuoteResultModel> {
        return this.httpClient.post(`${this.baseUrl}/quotes`, quoteCreationModel).pipe(
            map((result: any) => {
                return {
                    quoteNumber: result.quoteNumber,
                    quoteExternalId: result.id,
                    scope: result.scope
                };
            })
        );
    }

    createQuoteV2(quoteCreationModel: QuoteV2CreateModel): Observable<QuoteResultModel> {
        return this.httpClient.post(`${this.baseUrl}/quotes/v2`, quoteCreationModel).pipe(
            map((result: any) => {
                return {
                    quoteNumber: result.quoteNumber,
                    quoteExternalId: result.id,
                    scope: result.scope
                };
            })
        );
    }

    cancelQuote(quoteExternalId: string) {
        return this.httpClient.post(`${this.baseUrl}/quotes/${quoteExternalId}/quotecancellation`, null);
    }

    getQuote(quoteExternalId: string): Observable<QuoteRetrievedModel> {
        return this.httpClient.get(`${this.baseUrl}/quotes/${quoteExternalId}`).pipe(
            map((result: any) => {
                let applicationGuidToUse = result.staffApplicationGuid;
                if (!applicationGuidToUse && environment.features.UseCustomerGuidFallback) {
                    applicationGuidToUse = result.applicationGuid;
                }
                return {
                    currentStatus: result.currentStatus,
                    quoteNumber: result.quoteNumber,
                    applicationGuid: applicationGuidToUse,
                    quoteFormModel: this.mapToQuoteFormModel(result),
                    isEaas: result.isEaas
                };
            })
        );
    }

    updateQuote(quoteExternalId: string, quotePatchModel: any): Observable<QuoteFormModel> {
        return this.httpClient.patch(`${this.baseUrl}/quotes/${quoteExternalId}`, quotePatchModel).pipe(
            map(result => {
                return this.mapToQuoteFormModel(result);
            })
        );
    }

    updateQuoteV2(quoteExternalId: string, quoteV2PatchModel: QuoteV2PatchModel): Observable<QuoteFormModel> {
        return this.httpClient.patch(`${this.baseUrl}/quotes/${quoteExternalId}/v2`, quoteV2PatchModel).pipe(
            map(result => {
                return this.mapToQuoteFormModel(result);
            })
        );
    }

    renewQuote(quoteExternalId: string, newExpiryDate: Date): Observable<QuoteRetrievedModel> {
        const body = { newExpiryDate: newExpiryDate };

        return this.httpClient.post(`${this.baseUrl}/quotes/${quoteExternalId}/renewal`, body).pipe(
            map((result: any) => {
                return {
                    applicationGuid: '',
                    currentStatus: result.currentStatus,
                    quoteNumber: result.quoteNumber,
                    quoteFormModel: this.mapToQuoteFormModel(result),
                    isEaas: result.isEaas
                };
            })
        );
    }

    confirmInstallation(quoteExternalId: string, installationConfirmationModel: any): Observable<QuoteFormModel> {
        return this.httpClient
            .post(`${this.baseUrl}/quotes/${quoteExternalId}/providerconfirmation`, installationConfirmationModel)
            .pipe(
                map(result => {
                    return this.mapToQuoteFormModel(result);
                })
            );
    }

    getUploadedDocuments(quoteExternalId: string): Observable<Array<QuoteUploadedDocumentModel>> {
        return this.httpClient.get(`${this.baseUrl}/quotes/${quoteExternalId}/documents`).pipe(
            map((response: any) => {
                return (response || []).map(item => {
                    return {
                        guid: item.guid,
                        extension: item.extension,
                        mimeType: item.mimeType,
                        documentType: item.documentType,
                        documentTypeLabel: QuoteDocumentUploadTypeLabels[item.documentType],
                        dateSubmitted: item.dateSubmitted
                    };
                });
            })
        );
    }

    getPackages(
        category: PackageCategory,
        scheme: Scheme,
        requestScope: PackageRequestScope = PackageRequestScope.OnlyForBroker,
        providerPackage: string
    ): Observable<Array<PackageModel>> {
        const requestQueries: string[] = [];
        if (scheme) {
            requestQueries.push(`scheme=${scheme}`);
        }
        if (requestScope !== PackageRequestScope.OnlyForBroker) {
            requestQueries.push(`category=${category}`);
        }
        if (providerPackage) {
            requestQueries.push(`providerPackage=${providerPackage}`);
        }
        const queryString = requestQueries.length > 0 ? `?${requestQueries.join('&')}` : '';

        return this.httpClient.get(`${this.baseUrl}/${environment.providerBatteryPackagesRoute + queryString}`).pipe(
            map((response: any) => {
                return response._embedded.packages.map(pck => this.mapPackage(pck, null));
            })
        );
    }

    getCecPackage(packageId: string) {
        return this.httpClient
            .get(`${this.baseUrl}/${environment.providerBatteryPackagesRoute}/cecpackages/${packageId}`)
            .pipe(
                map<HalResponse, CecPackageModel>(pkg => {
                    return {
                        packageExternalId: pkg.packageExternalId,
                        model: pkg.model,
                        brand: pkg.brand,
                        category: PackageCategory[pkg.category],
                        energyCapacity: pkg.energyCapacity,
                        outputPower: pkg.outputPower,
                        series: pkg.series,
                        moduleNumber: pkg.moduleNumber
                    };
                })
            );
    }

    getCecPackages(category: PackageCategory, params?: CecPackagesFilterParams): Observable<Array<CecPackageModel>> {
        let filterQuery = '';
        if (params) {
            filterQuery = toQueryString(params);
        }
        return this.httpClient
            .get(
                `${this.baseUrl}/${environment.providerBatteryPackagesRoute}/CecPackages?category=${category}${
                    filterQuery ? '&' + filterQuery : ''
                }`
            )
            .pipe(
                map<HalResponse<{ packages: any[] }>, CecPackageModel[]>(response => {
                    return response._embedded.packages.map<CecPackageModel>(pkg => ({
                        packageExternalId: pkg.packageExternalId,
                        model: pkg.model,
                        brand: pkg.brand,
                        category: PackageCategory[pkg.category],
                        energyCapacity: pkg.energyCapacity,
                        outputPower: pkg.outputPower,
                        series: pkg.series,
                        moduleNumber: pkg.moduleNumber
                    }));
                })
            );
    }

    getCecInstallerByAccreditationNumber(accreditationNumber: string) {
        return this.httpClient.get<CecInstallerModel>(
            `${this.baseUrl}/CecInstallers/${accreditationNumber}/accreditation`
        );
    }

    getApprovedBuilders(): Observable<Array<BuilderModel>> {
        return this.httpClient.get(`${this.baseUrl}/${environment.approvedBuildersRoute}`).pipe(
            map((response: any) => {
                return response._embedded.builders.map(this.mapBuilder);
            })
        );
    }

    getPackage(packageInternalId: string): Observable<PackageModel> {
        return this.httpClient
            .get(`${this.baseUrl}/${environment.providerBatteryPackagesRoute}/${packageInternalId}`)
            .pipe(map(pck => this.mapPackage(pck, packageInternalId)));
    }

    getSchemeCoverage(scheme: string): Observable<Array<string>> {
        return this.httpClient
            .get(`${this.baseUrl}/${environment.schemeCoverageRoute}/${scheme}/postcodes`)
            .pipe(map(this.mapSchemeCoverage));
    }

    getApprovedBuilder(builderId: string): Observable<BuilderModel> {
        return this.httpClient
            .get(`${this.baseUrl}/${environment.approvedBuildersRoute}/${builderId}`)
            .pipe(map(this.mapBuilder));
    }

    getSubsidy(quoteExternalId: string): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/quotes/${quoteExternalId}/subsidy`).pipe(
            map((response: any) => {
                return response.subsidyAmount;
            })
        );
    }

    calculateSubsidy(packageId: string, calculationModel: SubsidyCalculationModel): Observable<any> {
        return this.httpClient
            .post(`${this.baseUrl}/${environment.providerBatteryPackagesRoute}/${packageId}/subsidy`, calculationModel)
            .pipe(
                map((response: any) => {
                    return response.subsidyAmount;
                })
            );
    }

    downloadDocument(quoteExternalId: string, documentGuid: string): Observable<any> {
        return this.httpClient
            .get(`${this.baseUrl}/quotes/${quoteExternalId}/documents/${documentGuid}/content`, {
                observe: 'response',
                responseType: 'blob'
            })
            .pipe(
                map(response => {
                    return {
                        blob: response.body,
                        filename: response.headers.get('Quote-Doc-FileName')
                    };
                })
            );
    }

    deleteDocument(quoteExternalId: string, documentGuid: string) {
        return this.httpClient.delete(`${this.baseUrl}/quotes/${quoteExternalId}/documents/${documentGuid}`);
    }

    applyForFinance(quoteExternalId: string): Observable<FinanceResultModel> {
        return this.httpClient
            .post(`${this.baseUrl}/quotes/${quoteExternalId}/financeapplication?isStaff=true`, null)
            .pipe(
                map((response: any) => {
                    const href = response._links.application.href;
                    return {
                        applicationGuid: this.extractGuidFromApplicationLink(href)
                    };
                })
            );
    }

    private mapToQuoteFormModel(result: any): QuoteFormModel {
        if (result.scope === Scheme.SouthAustralia || result.scope === Scheme.NewSouthWales)
            return this.mapToQuoteV1FormModel(result);

        const convertedInstallationDate = this.convertToLocalTime(result.intendedInstallationDate);
        const convertedQuoteValidity = this.convertToLocalTime(result.validUntil);
        const homeOwnershipOrInterestStatus = result.hasHomeOwnershipOrInterest
            ? result.isHomeOwnershipOrInterestDirect
                ? HomeOwnershipOrInterest.Direct
                : HomeOwnershipOrInterest.Indirect
            : '';

        const sysDetails = result.systemDetails;
        const getSystemTypeDetails = type => sysDetails.find(x => x.type === type);

        const batteryDetails = getSystemTypeDetails(InstallationType.Battery);
        const solarDetails = getSystemTypeDetails(InstallationType.Solar);
        const inverterDetails = getSystemTypeDetails('Inverter');
        const hemsDeviceDetails = getSystemTypeDetails(InstallationType.HEMSDevice);
        const heatPumpDetails = getSystemTypeDetails(InstallationType.HeatPumpWaterHeater);
        const evChargerDetails = getSystemTypeDetails(InstallationType.EVCharger);
        const inductionCooktopDetails = getSystemTypeDetails(InstallationType.InductionCooktop);
        const smartMeterDetails = getSystemTypeDetails(InstallationType.SmartMeter);
        const airConditionerDetails = getSystemTypeDetails(InstallationType.SplitSystemAirConditioner);

        const activityConvert: ActivityModel[] =
            result.activities?.map(a => ({
                activityType: a.activitytype,
                associatedRebate: a.associatedrebate,
                rebateAmount: Number(a.rebateamount),
                vpp: a.vpp,
                systemAndInstallationCost: Number(a.systemandinstallationcost),
                additionalCost: Number(a.addditionalcost),
                subtotal: Number(a.subtotal)
            })) || [];

        return {
            scope: result.scope || Scheme.SouthAustralia,
            firstName: result.customerFirstName,
            lastName: result.customerLastName,
            mobile: result.customerMobile,
            email: result.customerEmail,
            energyConcessionRecipient: result.isEnergyConcessionHolder,
            statutoryPremiumFeedInMember: result.receivesDistributionFeedInTariff,
            batteryPackage: batteryDetails?.packageExternalId || '',
            solarPackage: solarDetails?.packageExternalId || '',
            inverterPackage: inverterDetails?.packageExternalId || '',
            activities: activityConvert || [],
            dcIsolatorPackage: result.dcIsolatorPackageType || '',
            approvedBuilder: result.builderId ? result.builderId : '',
            customerDeposit: result.customerDeposit,
            existingSolarPanels: result.existingSolarPanels,
            newSolarPanelInstallationType: result.newSolarPanelInstallationType || '',
            newBatteryInstallationType: result.newBatteryInstallationType || '',
            existingBattery: result.existingBattery,
            installationAddress: {
                unitNumber: result.installationAddress.unitNumber ? result.installationAddress.unitNumber : '',
                streetNumber: result.installationAddress.streetNumber,
                street: result.installationAddress.street,
                suburb: result.installationAddress.suburb,
                postCode: result.installationAddress.postCode,
                state: result.installationAddress.state
            },
            installationAddressIsPredominantResidential: true,
            hasHomeOwnershipOrInterest: result.hasHomeOwnershipOrInterest,
            confirmOwnerInstallationConsent: result.confirmOwnerInstallationConsent
                ? result.confirmOwnerInstallationConsent
                : false,
            installationPrice: result.installationPrice,
            intendedInstallationDate: convertedInstallationDate,
            nmi: result.nationalMeterIdentifier,
            alternateNetwork: result.isAlternateNetwork,
            isCustomerJoiningVpp: !!result.vppOperator,
            vppOperator: result.vppOperator,
            meterIdentificationNumber: result.meterIdentificationNumber,
            quoteValidity: convertedQuoteValidity,
            fairPricingConfirmation: true,
            isPriceGstInc: result.isPriceGstInc,
            isNewBuild: result.isNewBuild,
            newBuildConfirmation: result.isNewBuild,
            notApprovedForLowIncomeHouseholdsConfirmation: true,
            householdIncomeConfirmation: true,
            installationType: result.installationType.split(',') || [],
            homeOwnershipOrInterestStatus: homeOwnershipOrInterestStatus,
            previousExtraRequiredDeposit:
                result.extraRequiredDeposit === null || result.extraRequiredDeposit === undefined
                    ? 0
                    : result.extraRequiredDeposit,
            previousInterestFreeLoanThreshold:
                result.interestFreeLoanThreshold !== undefined ? result.interestFreeLoanThreshold : null,
            noInterestBearingLoan: result.extraRequiredDeposit == null ? false : result.extraRequiredDeposit > 0,
            newSolarPanelCapacity: result.newSolarPanelCapacity || '',
            existingSolarPanelCapacity: result.existingSolarPanelCapacity || '',
            existingBatteryCapacity: result.existingBatteryCapacity || '',
            batterySerialNumber: result.batterySerialNumber,
            batteryInverterSerialNumbers: result.batteryInverterSerialNumbers,
            solarPanelSerialNumbers: result.solarPanelSerialNumbers,
            solarInverterSerialNumber: result.solarInverterSerialNumber,
            installationDate: result.installationDate ? this.convertToLocalTime(result.installationDate) : null,
            isEaas: result.isEaas || false,
            providerCustomerId: result.providerCustomerId || '',
            providerInvoiceNumber: result.providerInvoiceNumber || '',
            billingOwner: result.billingOwner || '',
            systemModelIdentifier_HEMSDevice: hemsDeviceDetails?.systemModelIdentifier || '',
            systemModelIdentifier_HeatPumpWaterHeater: heatPumpDetails?.systemModelIdentifier || '',
            systemModelIdentifier_EVCharger: evChargerDetails?.systemModelIdentifier || '',
            systemModelIdentifier_InductionCooktop: inductionCooktopDetails?.systemModelIdentifier || '',
            systemModelIdentifier_SmartMeter: smartMeterDetails?.systemModelIdentifier || '',
            systemModelIdentifier_SplitSystemAirConditioner: airConditionerDetails?.systemModelIdentifier || '',
            packagePrice: batteryDetails?.price || 0,
            solarPanelPrice: solarDetails?.price || 0,
            price_HEMSDevice: hemsDeviceDetails?.price || 0,
            price_HeatPumpWaterHeater: heatPumpDetails?.price || 0,
            price_EVCharger: evChargerDetails?.price || 0,
            price_InductionCooktop: inductionCooktopDetails?.price || 0,
            price_SmartMeter: smartMeterDetails?.price || 0,
            price_SplitSystemAirConditioner: airConditionerDetails?.price || 0
        };
    }

    private mapToQuoteV1FormModel(result: any): QuoteFormModel {
        const convertedInstallationDate = this.convertToLocalTime(result.intendedInstallationDate);
        const convertedQuoteValidity = this.convertToLocalTime(result.validUntil);
        const homeOwnershipOrInterestStatus = result.hasHomeOwnershipOrInterest
            ? result.isHomeOwnershipOrInterestDirect
                ? HomeOwnershipOrInterest.Direct
                : HomeOwnershipOrInterest.Indirect
            : '';
        return {
            scope: result.scope || Scheme.SouthAustralia,
            firstName: result.customerFirstName,
            lastName: result.customerLastName,
            mobile: result.customerMobile,
            email: result.customerEmail,
            energyConcessionRecipient: result.isEnergyConcessionHolder,
            statutoryPremiumFeedInMember: result.receivesDistributionFeedInTariff,
            batteryPackage: result.package,
            solarPackage: result.solarPanelPackageType || '',
            inverterPackage: result.inverterPackageType || '',
            dcIsolatorPackage: result.dcIsolatorPackageType || '',
            approvedBuilder: result.builderId ? result.builderId : '',
            customerDeposit: result.customerDeposit,
            solarPanelPrice: result.solarPanelPrice,
            existingSolarPanels: result.existingSolarPanels,
            newSolarPanelInstallationType: result.newSolarPanelInstallationType || '',
            newBatteryInstallationType: result.newBatteryInstallationType || '',
            existingBattery: result.existingBattery,
            installationAddress: {
                unitNumber: result.installationAddress.unitNumber ? result.installationAddress.unitNumber : '',
                streetNumber: result.installationAddress.streetNumber,
                street: result.installationAddress.street,
                suburb: result.installationAddress.suburb,
                postCode: result.installationAddress.postCode,
                state: result.installationAddress.state
            },
            installationAddressIsPredominantResidential: true,
            hasHomeOwnershipOrInterest: result.hasHomeOwnershipOrInterest,
            confirmOwnerInstallationConsent: result.confirmOwnerInstallationConsent
                ? result.confirmOwnerInstallationConsent
                : false,
            installationPrice: result.installationPrice,
            intendedInstallationDate: convertedInstallationDate,
            nmi: result.nationalMeterIdentifier,
            alternateNetwork: result.isAlternateNetwork,
            isCustomerJoiningVpp: !!result.vppOperator,
            vppOperator: result.vppOperator,
            meterIdentificationNumber: result.meterIdentificationNumber,
            packagePrice: result.packagePrice,
            quoteValidity: convertedQuoteValidity,
            fairPricingConfirmation: true,
            isPriceGstInc: result.isPriceGstInc,
            isNewBuild: result.isNewBuild,
            newBuildConfirmation: result.isNewBuild,
            notApprovedForLowIncomeHouseholdsConfirmation: true,
            householdIncomeConfirmation: true,
            installationType: result.installationType || '',
            homeOwnershipOrInterestStatus: homeOwnershipOrInterestStatus,
            previousExtraRequiredDeposit:
                result.extraRequiredDeposit === null || result.extraRequiredDeposit === undefined
                    ? 0
                    : result.extraRequiredDeposit,
            previousInterestFreeLoanThreshold:
                result.interestFreeLoanThreshold !== undefined ? result.interestFreeLoanThreshold : null,
            noInterestBearingLoan: result.extraRequiredDeposit == null ? false : result.extraRequiredDeposit > 0,
            newSolarPanelCapacity: result.newSolarPanelCapacity || '',
            existingSolarPanelCapacity: result.existingSolarPanelCapacity || '',
            existingBatteryCapacity: result.existingBatteryCapacity || '',
            batterySerialNumber: result.batterySerialNumber,
            batteryInverterSerialNumbers: result.batteryInverterSerialNumbers,
            solarPanelSerialNumbers: result.solarPanelSerialNumbers,
            solarInverterSerialNumber: result.solarInverterSerialNumber,
            installationDate: result.installationDate ? this.convertToLocalTime(result.installationDate) : null,
            isEaas: result.isEaas || false,
            providerCustomerId: result.providerCustomerId || '',
            providerInvoiceNumber: result.providerInvoiceNumber || '',
            billingOwner: result.billingOwner || '',
            systemModelIdentifier_HEMSDevice: '',
            systemModelIdentifier_HeatPumpWaterHeater: '',
            systemModelIdentifier_EVCharger: '',
            systemModelIdentifier_InductionCooktop: '',
            systemModelIdentifier_SmartMeter: '',
            systemModelIdentifier_SplitSystemAirConditioner: '',
            price_HEMSDevice: 0,
            price_HeatPumpWaterHeater: 0,
            price_EVCharger: 0,
            price_InductionCooktop: 0,
            price_SmartMeter: 0,
            price_SplitSystemAirConditioner: 0,
            activities: []
        };
    }

    convertToLocalTime(date: string): Date {
        return DateTime.fromISO(date, { zone: 'utc' })
            .toLocal()
            .toJSDate();
    }

    private extractGuidFromApplicationLink(link: string) {
        return link ? link.slice('/Application/'.length) : null;
    }

    private mapPackage(pck, packageInternalId = null): PackageModel {
        return {
            name: pck.description,
            shortName: packageInternalId || pck.packageId,
            capacitykWh: pck.capacityKwH,
            bessManufacturer: pck.bessManufacturer,
            bessModelNo: pck.bessModelNo,
            batterySystemManufacturer: pck.batterySystemManufacturer,
            batterySystemModelNo: pck.batterySystemModelNo,
            numberOfBessAndBs: pck.numberOfBessAndBs,
            inverterManufacturer: pck.inverterManufacturer,
            inverterModelNo: pck.inverterModelNo,
            smartControllerMeterManufacturer: pck.smartControllerMeterManufacturer,
            smartControllerMeterModelNo: pck.smartControllerMeterModelNo,
            currentSensorManufacturer: pck.currentSensorManufacturer,
            currentSensorModelNo: pck.currentSensorModelNo,
            otherMajorComponentManufacturer1: pck.otherMajorComponentManufacturer1,
            otherMajorComponentModelNo1: pck.otherMajorComponentModelNo1,
            otherMajorComponentManufacturer2: pck.otherMajorComponentManufacturer2,
            otherMajorComponentModelNo2: pck.otherMajorComponentModelNo2,
            schemes: pck.schemes.map(s => ({
                scheme: s.scheme,
                systemStatus: s.systemStatus
            }))
        };
    }

    private mapSchemeCoverage(coverage) {
        return coverage.postcodes;
    }

    private mapSolarPackage(pck) {
        return {
            name: pck.description,
            shortName: pck.packageId
        };
    }

    private mapBuilder(builder) {
        return {
            name: builder.companyName,
            shortName: builder.builderId
        };
    }
}
