import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Validator that conditionally returns result from the passed-in validator when another control's value changes.
 *
 * @usageNotes Both driver control and driven control must be defined within a form group.
 *
 * @param {string | (string | number)[]} driverControlPath A dot-delimited string or array of string/number values
 * that define the path to the control that triggers (drives) this control's validation.
 * @param {(control: AbstractControl, driverControl: AbstractControl) => boolean} predicate A function determines whether to run the validator.
 * @param {ValidatorFn | ValidatorFn[]} validatorFn Validator function that will be run when the driver control's value changes and
 * `predicate()` returns `true`.
 *
 * @returns {ValidatorFn} The validator that returns the result from the `validatorFn`.
 */
export function conditionallyDrivenValidator(
    driverControlPath: string | (string | number)[],
    predicate: (control: AbstractControl, driverControl: AbstractControl) => boolean,
    validatorFn: ValidatorFn | ValidatorFn[]
): ValidatorFn {
    let subscribed = false;
    return (ctrl: AbstractControl) => {
        if (
            !ctrl.root ||
            typeof predicate !== 'function' ||
            (typeof validatorFn !== 'function' && !Array.isArray(validatorFn))
        )
            return null;
        const driverCtrl = ctrl.root.get(driverControlPath);
        if (!subscribed && driverCtrl) {
            subscribed = true;
            driverCtrl.valueChanges.subscribe(val => ctrl.updateValueAndValidity({ emitEvent: false }));
        } else if (subscribed && !driverCtrl) {
            // Reset "subscribed" flag if control was removed from form group.
            subscribed = false;
        }

        if (predicate(ctrl, driverCtrl)) {
            if (Array.isArray(validatorFn)) {
                return validatorFn.map(fn => fn(ctrl)).find(err => err) || null;
            }
            return validatorFn(ctrl);
        }

        return null;
    };
}
