import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';

/**
 * Wrapper for Harmony client api v1.
 *
 * More information at:
 * * https://developer.mastersoftgroup.com/harmony/client/js/v1/api.html
 * * https://developer.mastersoftgroup.com/harmony/api/method/v1/address.html
 *
 * @export
 * @class HarmonyClient
 */
@Injectable({
    providedIn: 'root'
})
export class HarmonyClient {
    fullAddress(address: string) {
        return this.lookup('fullAddress', address);
    }

    private lookup(field: string, value: string) {
        return new Observable((observer: Observer<HarmonyAddressRestResponse>) => {
            Harmony.address({ [field]: value }, Harmony.AUPAF, (res: HarmonyAddressRestResponse) => {
                if (typeof res?.status !== 'undefined') {
                    observer.next(res);
                    observer.complete();
                } else {
                    observer.error(res);
                }
            });
        });
    }
}

@Injectable({
    providedIn: 'root'
})
export class HarmonyMockClient extends HarmonyClient {
    fullAddress(address: string): Observable<HarmonyAddressRestResponse> {
        return of(<HarmonyAddressRestResponse>{
            status: 'SUCCESS',
            payload: [{ fullAddress: 'Mock address' }],
            messages: []
        });
    }
}

export interface HarmonyAddress {
    eid: string;
    fullAddress: string;
    country: string;
    state: string;
    postcode: string;
    locality: string;
    street: string;
    postal: string;
    postalNumber: string;
    postalType: string;
    streetNumber: string;
    streetName: string;
    streetType: string;
    streetSuffix: string;
    buildingName: string;
    subdwelling: string;
    flatUnitNumber: string;
    flatUnitType: string;
    floorLevelNumber: string;
    floorLevelType: string;
    lotNumber: string;
    attributes: { [key: string]: string };
}

export type HarmonyStatus = 'SUCCESS' | 'ERROR'; // Harmony.SUCCESS | Harmony.ERROR

export interface HarmonyAddressRestResponse {
    status: HarmonyStatus;
    messages: string[];
    payload: HarmonyAddress[];
}
