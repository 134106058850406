import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pascalToSentenceCase' })
export class PascalToSentenceCasePipe implements PipeTransform {
    transform(pascalCase: string, capitalizeWords = false): any {
        let spacedLowerCase = pascalCase.replace(/([A-Z])/g, ' $1').trim();
        if (!capitalizeWords) {
            spacedLowerCase = spacedLowerCase.toLowerCase();
        }
        return spacedLowerCase.charAt(0).toUpperCase() + spacedLowerCase.slice(1);
    }
}
