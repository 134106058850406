import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollbarComponent } from './scrollbar.component';
import { MaterialComponentsModule } from 'app/material-components.module';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, MaterialComponentsModule, FlexLayoutModule],
    entryComponents: [],
    declarations: [ScrollbarComponent],
    exports: [ScrollbarComponent],
    providers: []
})
export class ScrollbarUiModule {}
