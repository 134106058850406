import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import _ from 'lodash-es';

import { ReportingScope } from '@brokerportal/common/enums';
import { PortalConfigurationService } from '@brokerportal/common/services';
import { SearchBarResult } from './search-bar-result.model';
import { SearchBarService, SearchFilters } from './search-bar.service';
import { SidenavService } from '../../sidenav/sidenav.service';
import { SidenavItem } from '../../sidenav/sidenav-item/sidenav-item.model';

@Component({
    selector: 'bp-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('searchInput', { read: NgModel }) private inputControl: NgModel;
    input: string;
    focused: boolean;
    isLoading = false;
    recentlyVisited: SidenavItem[] = [];
    searchResults: SearchBarResult[] = [];

    private greenConnectOfferPrograms: string[] = [];
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private sidenavService: SidenavService,
        private searchBarService: SearchBarService,
        private portalConfigService: PortalConfigurationService
    ) {}

    ngOnInit() {
        this.subscriptions.add(
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    const item = this.sidenavService.getSidenavItemByRoute(event.urlAfterRedirects);
                    if (item == null) {
                        return;
                    }
                    let index = this.recentlyVisited.indexOf(item);
                    if (index > -1) {
                        this.recentlyVisited.splice(index, 1);
                    }
                    this.recentlyVisited.unshift(item);
                    if (this.recentlyVisited.length > 5) {
                        this.recentlyVisited.pop();
                    }
                }
            })
        );

        this.portalConfigService.get().subscribe(result => {
            const greenConnectProviders = result.greenConnect?.providers || [];
            this.greenConnectOfferPrograms = (
                result.partner?.renewableOffers ||
                result.greenConnect?.offers.map(o => o.code) ||
                []
            ).concat(_.flatten(greenConnectProviders.map(provider => provider.offers.map(o => o.code)) || []));
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit() {
        this.inputControl.valueChanges
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => {
                    this.isLoading = true;
                }),
                switchMap(term => {
                    let filters: SearchFilters = {};
                    if (this.greenConnectOfferPrograms?.length > 0) {
                        filters.scope = ReportingScope.GreenConnect;
                    }
                    return this.searchBarService.search(term, filters);
                }),
                tap(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(results => {
                this.searchResults = results;
            });
    }

    navigateApplication(applicationId: string) {
        this.closeDropdown();
        this.router.navigate(['/application/detail', applicationId]);
    }

    openDropdown() {
        this.focused = true;
    }

    closeDropdown() {
        this.focused = false;
    }

    getCustomerName(item: SearchBarResult): string {
        return item.businessEntityType != 'Trust' && item.businessEntityType != 'Company'
            ? item.customerName
            : `${item.businessEntityName} (${item.customerName.trim()})`;
    }
}
