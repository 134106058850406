import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { ReportingScope } from '@brokerportal/common/enums';
import { SearchBarResult } from './search-bar-result.model';

export interface SearchFilters {
    scope?: ReportingScope;
}

@Injectable({
    providedIn: 'root'
})
export class SearchBarService {
    constructor(private httpClient: HttpClient) {}

    search(terms: string, filters?: SearchFilters): Observable<Array<SearchBarResult>> {
        return this.searchEntries(terms, filters).pipe(
            map(results => {
                // RSAPI returns empty model, instead of array, at times. Force empty array if this is the case
                // so as to not break consumers of this method. Ideally we fix the API SearchController in future.
                return Array.isArray(results) ? results : [];
            })
        );
    }

    private searchEntries(term: string, filters?: SearchFilters): Observable<Array<SearchBarResult>> {
        if (!term) {
            return of(Array<SearchBarResult>());
        }
        const queryParams = [];
        if (filters) {
            queryParams.push(`filters=${JSON.stringify(filters)}`);
        }
        const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

        return this.httpClient.get<Array<SearchBarResult>>(
            `${environment.api.baseUrl}/portal/search/` + term + queryString
        );
    }
}
