import { Pipe, PipeTransform } from '@angular/core';
import { LoanPurpose } from '../enums/loan-purpose.enum';
import { CreditStatusService } from '../services/credit-status.service';

@Pipe({ name: 'loanPurposeDescription' })
export class LoanPurposeDescriptionPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: string): string {
        if (value in LoanPurpose) {
            return this.creditStatusService.getLoanPurposeDescription(LoanPurpose[value]);
        }

        // Most likely a custom loan purpose, which is free text
        return value;
    }
}
