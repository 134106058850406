import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrokerSettingGroupValueType } from '../enums';
import { CachedService } from './cached.service';

@Injectable()
export class BrokerSettingService {
    constructor(private httpClient: HttpClient, private cachedService: CachedService) {
        cachedService.configure(environment.constants.storage.brokerSettingConfigurationKey, 15);
    }

    private getUrl(
        brokerId: string,
        settingGroupName: string,
        settingGroupValueType: BrokerSettingGroupValueType,
        experience: string
    ): string {
        return `${environment.api.baseUrl}/broker/${brokerId}/experience/${experience}/settingGroups/${settingGroupName}?valueType=${settingGroupValueType}`;
    }

    // clear the localstorage. This is called when user logs out.
    public clear() {
        this.cachedService.clear();
    }

    private getObjectKey(brokerId: string, settingGroupName: string, experience: string) {
        return `${brokerId}_${settingGroupName}_${experience}`;
    }

    public Get<T>(
        brokerId: string,
        settingGroupName: string,
        settingGroupValueType: BrokerSettingGroupValueType,
        experience: string = 'Unknown'
    ): Observable<T> {
        let objKey = this.getObjectKey(brokerId, settingGroupName, experience);
        const cachedValue = this.cachedService.GetCached(objKey);
        if (cachedValue) {
            return of(cachedValue);
        }

        return this.httpClient.get(this.getUrl(brokerId, settingGroupName, settingGroupValueType, experience)).pipe(
            map((result: any) => {
                this.cachedService.SetCached(objKey, result);
                return result as T;
            })
        );
    }
}
