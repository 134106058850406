import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { isEmptyInputValue } from '../utils';

/**
 * Validator that requires the control's value to be greater than or equal to the provided number.
 *
 * @param {number} min the minimum value.
 * @param {boolean} [inclusive=true] whether to accept the minimum value. Default to `true`.
 * @return {ValidatorFn} A validator function that returns an error map with the `min` property if the validation check fails, otherwise `null`.
 * @usageNotes This validator extends Angular's `Validators.min` with option to allow equality comparison.
 */
export function minValidator(min: number, inclusive = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
            return null;
        }
        const value = parseFloat(control.value);
        return !isNaN(value) && (inclusive ? value < min : value <= min)
            ? { min: { min: min, actual: control.value } }
            : null;
    };
}
