//These document types are part of enumeration 'RSCore.RS.Documents.DocumentType' in Venus
export enum UploadableDocumentTypes {
    LoanPreContract = 1,
    LoanContract = 4,
    LoanPrecontractualDisclosure = 6,
    VerifiedBankStatement = 9,
    IdentityDocument = 10,
    SecuredLoanContract = 12,
    SecuredLoanPrecontractualDisclosure = 13,
    DealerTaxInvoiceRequest = 15,
    PrivateSaleInvoice = 16,
    BulletLoanSecuredPrecontractualDisclosure = 20,
    BulletLoanPrecontractualDisclosure = 21,
    RetailFinancePrecontractualDisclosure = 24,
    PurplebricksPrecontractualDisclosure = 31,
    PurchaseFinanceAgreementPrecontractualDisclosure = 32,
    BuymobileLargeLoanPreContract = 36,
    VayaLargeLoanPreContract = 37,
    LoanStatement = 38,
    WholesaleCreditProposal = 40,
    Payslip = 41,
    ProofOfAddress = 43,
    IndividualTaxReturn = 44,
    NoticeOfAssessment = 45,
    InsuranceCertificate = 46,
    CompanyFinancialStatements = 47,
    DriverLicense = 48,
    MedicareCard = 49,
    AustralianPassport = 50,
    ForeignPassport = 51,
    PowerOfAttorney = 52,
    GreenEquipmentInvoice = 53,
    SuperAnnuationStatement = 59,
    BorrowerTaxStatement = 66,
    AutoWarehouseCreditProposal = 69,
    AddOnWarrantyPolicy = 71,
    ComprehensiveInsurancePolicy = 72,
    InspectionReport = 73,
    PayoutLetter = 74,
    VehicleSaleInvoice = 75,
    Payslip_Second = 81,
    WholesaleNswEhpCreditProposal = 83,
    DealershipVerification = 96,
    RatesNotice = 112,
    PLRenewableWarehouseLoanContract = 124,
    PLRenewableWarehouseCreditProposal = 125,
    AutoCommercialUnsignedContract = 129,
    AutoCommercialSignedContract = 130,
    SmallScaleTechnologyCertificate = 138,
    ProofOfComparableCredit = 143,
    ConfirmationOfVehicleUseForBusiness = 144,
    AdditionalIncomePayslip = 147,
    AdditionalIncomeSecondPayslip = 148,
    ShareholderDriverLicense = 152,
    ShareholderOtherIdentity = 153,
    AutoCommercialDraftContract = 154,
    Custom = 155,
    TaxDocument = 169
}

export const DocumentTypeLabels = {
    LoanPreContract: 'Credit Proposal',
    LoanContract: 'Loan Contract',
    LoanPrecontractualDisclosure: 'Precontractual Disclosure',
    ProofOfAddress: 'Proof of address',
    VerifiedBankStatement: 'Bank statement',
    IdentityDocument: 'Identity Document',
    SecuredLoanContract: 'Loan Contract',
    SecuredLoanPrecontractualDisclosure: 'Precontractual Disclosure',
    IndividualTaxReturn: 'Individual tax return', // If self-employed
    NoticeOfAssessment: 'Notice of assessment', // If self-employed
    DealerTaxInvoiceRequest: 'Dealer tax invoice', // If vehicle
    CompanyFinancialStatements: 'Company financial statements',
    PrivateSaleInvoice: 'Private sale invoice', // If vehicle
    BulletLoanSecuredPrecontractualDisclosure: 'Precontractual Disclosure',
    BulletLoanPrecontractualDisclosure: 'Precontractual Disclosure',
    RetailFinancePrecontractualDisclosure: 'Precontractual Disclosure',
    PurplebricksPrecontractualDisclosure: 'Precontractual Disclosure',
    PurchaseFinanceAgreementPrecontractualDisclosure: 'Precontractual Disclosure',
    BuymobileLargeLoanPreContract: 'Credit Proposal',
    VayaLargeLoanPreContract: 'Credit Proposal',
    WholesaleCreditProposal: 'Credit Proposal',
    Payslip: 'Payslip',
    Payslip_Second: 'Second most recent payslip',
    InsuranceCertificate: 'Insurance certificate', // If vehicle
    DriverLicense: 'Driver licence',
    MedicareCard: 'Medicare card',
    AustralianPassport: 'Australian passport',
    ForeignPassport: 'Foreign passport',
    PowerOfAttorney: 'Power of attorney',
    GreenEquipmentInvoice: 'Green equipment invoice', // If green loan
    SuperAnnuationStatement: 'Superannuation statement',
    BorrowerTaxStatement: 'Tax statement',
    AutoWarehouseCreditProposal: 'Credit Proposal',
    WholesaleNswEhpCreditProposal: 'Credit Proposal',
    AddOnWarrantyPolicy: 'Add-on warranty policy',
    ComprehensiveInsurancePolicy: 'Comprehensive insurance policy',
    InspectionReport: 'Inspection report',
    PayoutLetter: 'Payout letter',
    VehicleSaleInvoice: 'Vehicle sale invoice',
    LoanStatement: 'Loan statement',
    AutoCommercialUnsignedContract: 'Auto Commercial Unsigned Contract',
    AutoCommercialSignedContract: 'Auto Commercial Signed Contract',
    SmallScaleTechnologyCertificate: 'Small scale technology certificate (STC)',
    RatesNotice: 'Rates notice',
    PLRenewableWarehouseLoanContract: 'Loan Contract',
    PLRenewableWarehouseCreditProposal: 'Credit Proposal',
    ProofOfComparableCredit: 'Comparable credit',
    ConfirmationOfVehicleUseForBusiness: 'Letter confirming use of vehicle for business',
    AdditionalIncomePayslip: 'Additional income payslip',
    AdditionalIncomeSecondPayslip: 'Additional income second payslip',
    ShareholderDriverLicense: 'Shareholder Driver License',
    ShareholderOtherIdentity: 'Shareholder Other Identity',
    TaxDocument: 'Tax document'
};

export const PreContractDocumentTypes: Array<UploadableDocumentTypes> = [
    UploadableDocumentTypes.LoanPreContract,
    UploadableDocumentTypes.BuymobileLargeLoanPreContract,
    UploadableDocumentTypes.VayaLargeLoanPreContract,
    UploadableDocumentTypes.WholesaleCreditProposal,
    UploadableDocumentTypes.AutoWarehouseCreditProposal,
    UploadableDocumentTypes.WholesaleNswEhpCreditProposal,
    UploadableDocumentTypes.PLRenewableWarehouseCreditProposal,
    UploadableDocumentTypes.LoanPrecontractualDisclosure,
    UploadableDocumentTypes.SecuredLoanPrecontractualDisclosure,
    UploadableDocumentTypes.BulletLoanSecuredPrecontractualDisclosure,
    UploadableDocumentTypes.BulletLoanPrecontractualDisclosure,
    UploadableDocumentTypes.RetailFinancePrecontractualDisclosure,
    UploadableDocumentTypes.PurplebricksPrecontractualDisclosure,
    UploadableDocumentTypes.PurchaseFinanceAgreementPrecontractualDisclosure
];
