import { Pipe, PipeTransform } from '@angular/core';

export interface IQuoteScope {
    scope: string;
    isEaas?: boolean;
}

@Pipe({ name: 'quoteScopeFormat' })
export class QuoteScopeFormatPipe implements PipeTransform {
    transform(quoteScope: IQuoteScope): any {
        switch (quoteScope.scope.toLowerCase()) {
            case 'sa_subsidy':
                if (quoteScope.isEaas) return 'SA HBS EaaS';
                return 'South Australia';
            case 'nsw_ehp':
                if (quoteScope.isEaas) return 'NSW EHP EaaS';
                return 'New South Wales';
            default:
                return '';
        }
    }
}
