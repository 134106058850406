// C.f. APIAddressCheckResult in Venus
export enum AddressCheckStatus {
    NotChecked = 0,
    Passed = 1,
    Refer = 2,
    NotMatched = 3,
    Error = 4,
    PAF = 5,
    ManuallyEntered = 6
}
