import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaChange } from '@angular/flex-layout';
import { NavigationEnd, Router } from '@angular/router';
import { MediaReplayService } from '@brokerportal/common/services/media-replay.service';
import { AuthenticationService } from '@brokerportal/common/auth/authentication.service';
import { Subscription } from 'rxjs';
import { SubNavigationService } from '../services/sub-navigation.service';
import { PortalConfigurationService } from '@brokerportal/common/services';
import { environment } from 'environments/environment';

@Component({
    selector: 'bp-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AdminComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('sidenav', { static: true }) sidenav;
    @ViewChild('scrollContainer', { static: true }) scrollContainer;

    private _mediaSubscription: Subscription;
    private _routerEventsSubscription: Subscription;

    sidenavOpen = true;
    sidenavMode = 'side';
    isMobile = false;
    private $zoho = window['$zoho'] || {};

    constructor(
        private router: Router,
        private mediaReplayService: MediaReplayService,
        private authenticationService: AuthenticationService,
        private subNavigationService: SubNavigationService,
        private elementRef: ElementRef,
        private portalConfigurationService: PortalConfigurationService
    ) {}

    ngOnInit() {
        this._mediaSubscription = this.mediaReplayService.isMobile$.subscribe(isMobile => {
            this.isMobile = isMobile;
            this.sidenavMode = isMobile ? 'over' : 'side';
            this.sidenavOpen = !isMobile;
        });

        this._routerEventsSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd && this.isMobile) {
                this.sidenav.close();
            }
        });

        this.authenticationService.isAuthenticated().subscribe(result => {
            if (result && this.router.url === '/') {
                this.router.navigateByUrl('/dashboard');
            }
        });

        this.subNavigationService.navigation$.subscribe(navigation =>
            this.onActivate(navigation, this.scrollContainer.nativeElement)
        );
    }

    ngAfterViewInit() {
        this.authenticationService.isAuthenticated().subscribe(result => {
            if (result) {
                this.portalConfigurationService.get().subscribe(result => {
                    // load chatbox
                    this.loadChatBox(result.user);
                });
            }
        });
    }

    ngOnDestroy() {
        if (this._mediaSubscription) {
            this._mediaSubscription.unsubscribe();
        }

        if (this._routerEventsSubscription) {
            this._routerEventsSubscription.unsubscribe();
        }

        this.hideChatBox();
    }

    onActivate(e, scrollContainer) {
        scrollContainer.scrollTop = 0;
    }

    private loadChatBox(data: any) {
        if (this.$zoho) {
            this.insertScript(
                'https://salesiq.zoho.com/widget',
                data,
                this.$zoho,
                this.fillChatboxInformation,
                'zsiqscript'
            );
            this.insertElementWithId('zsiqwidget');

            this.$zoho.salesiq?.floatbutton?.visible('show');
        }
    }

    private hideChatBox() {
        if (this.$zoho) {
            this.$zoho.salesiq?.floatbutton?.visible('hide');
            this.$zoho.salesiq?.floatwindow?.visible('hide');
        }
    }

    private insertScript(url: string, userData: any, $zoho, fillChatboxInformation, id = null) {
        if (!url) {
            return;
        }

        const s = document.createElement('script');

        if (id) {
            s.id = id;
        }

        s.defer = true;
        s.type = 'text/javascript';
        s.src = url;

        s.onload = () => {
            $zoho.salesiq.afterReady = () => {
                if (fillChatboxInformation) {
                    fillChatboxInformation($zoho, userData);
                }
            };
        };

        this.elementRef.nativeElement.appendChild(s);
    }

    private fillChatboxInformation($zoho, userData) {
        if (userData) {
            $zoho.salesiq.visitor.name(userData.firstName + ' ' + userData.lastName);
            $zoho.salesiq.visitor.email(userData.userName);
        }
    }

    private insertElementWithId(id) {
        if (!id) {
            return;
        }

        const s = document.createElement('div');
        s.id = id;
        this.elementRef.nativeElement.appendChild(s);
    }
}
