<h2 mat-dialog-title>
    {{data.title}}
</h2>
<mat-dialog-content>
    <p [innerHtml]="sanitizedAction"></p>
    <div *ngIf="data.defaultNo">
      <button mat-raised-button color="primary" mat-dialog-close>No</button>
      <button mat-button matDialogClose="confirm" color="warn">Yes</button>
    </div>
    <div *ngIf="!data.defaultNo">
      <button mat-raised-button matDialogClose="confirm" color="primary">Yes</button>
      <button mat-button mat-dialog-close>No</button>
    </div>
</mat-dialog-content>
  