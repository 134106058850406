import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchBarHighlight' })
export class SearchBarHighlightPipe implements PipeTransform {
    transform(text: any, search: any): any {
        if (search && text) {
            let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
            pattern = pattern
                .split(' ')
                .filter(t => {
                    return t.length > 0;
                })
                .join('|');
            const regex = new RegExp(pattern, 'gi');
            return text.replace(regex, match => `<mark>${match}</mark>`);
        } else {
            return text;
        }
    }
}
