
<div class="rate-container" [ngClass]="{'loading': asyncConfig.isLoading} ">
<ng-container *ngIf="asyncConfig.isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    Calculating...
</ng-container>

  <mat-table [dataSource]="rowData"  class="semantic-header-table rate-table">
      <ng-container matColumnDef="property">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">{{displayLabel[element.property]}}</mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="{'async-cell': asyncConfig.isLoading}">
          {{displayPrefix[element.property]}}{{useDecimalPlaces[element.property] 
            ? (element.value | number : '1.2') : element.value}}{{displaySuffix[element.property]}}</mat-cell>
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
  </mat-table>
</div>