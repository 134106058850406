// C.f. StepName in Venus
export enum StepName {
    NoStep = 0,
    RateEstimate = 10,
    PendingApplication = 15,
    InTriage = 20,
    CreditEvaluation = 25,
    BrokerConfirmation = 21,
    Accept = 28,
    FullApplication = 30,
    AutomatedDecisioning = 35,
    Identity = 40,
    ConditionallyApprovedIdentity = 45,
    BankStatementsDecisioning = 49,
    BankDetails = 50,
    ConditionallyApprovedBankDetails = 55,
    ApplicationReceived = 60,
    ConditionallyApprovedApplicationReceived = 65,
    ApplicationDocuments = 67,
    Accepted = 68,
    Refer = 70,
    ReferFullApplication = 71,
    ApplicationCanceled = 72,
    ApplicationApprovedConditionally = 75,
    ApplicationApproved = 80,
    ApplicationPendingReview = 81,
    AccountCreation = 85,
    AcceptTerms = 86,
    DirectDebitAgreementGeneration = 89,
    DirectDebitDetails = 90,
    PreContractualDocumentGeneration = 95,
    PreContractualDocumentManualGeneration = 96,
    FinalLoanContract = 100,
    FinalLoanContractSigningAgain = 101,
    LoanOfferSubmitted = 110,
    Finalised = 118,
    MatchRequested = 120,
    BankStatementsProcessing = 130,
    FullApplicationSubmittedTriage = 131,
    ApplicationAbandoned = 132,

    // For Lender Journey
    LenderRegister = 200,
    LenderInvestingEntityTriage = 210,
    LenderInvestingEntityJointInvestor = 211,
    LenderInvestingEntityCompanyInvestor = 212,
    LIETrustWithIndividualTrustees = 213,
    LIETrustWithCorporateTrustees = 214,
    LenderConfirm = 215,
    LenderIdentityVerification = 216,
    LenderIdentityManualVerification = 217,
    LenderPendingConfirmation = 218,
    LenderPendingConfirmationIndividual = 219,
    LenderTransferFunds = 220,
    LenderJointIdentityVerification = 221,
    LenderJointIdentityManualVerification = 222,
    LenderAccepted = 223,
    LoanContractSigned = 250,

    // For PL Broker
    Serviceability = 262
}

export const RenewableAwaitingForContractSigningSteps = [
    StepName.FinalLoanContract,
    StepName.FinalLoanContractSigningAgain
];
