import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { OriginationTier } from '@brokerportal/modules/application/detail/rates-fees/rates-fees.service';
import { ProductTag } from '../enums';
import { BrokerSettingModel } from '../models/broker-setting.model';

@Injectable()
export class TieredOriginationService {
    getOriginationTierFromLoanAmount(loanAmount: number, originationTiers: OriginationTier[]): OriginationTier | null {
        const loanAmountInCents = loanAmount * 100;
        var originationTier = originationTiers.find(
            originationTier =>
                loanAmountInCents >= originationTier.minLoanAmountInCents &&
                loanAmountInCents <= originationTier.maxLoanAmountInCents
        );

        return originationTier ?? null;
    }

    getSmallestOriginationTierFromOriginationFee(
        originationFee: number,
        originationTiers: OriginationTier[]
    ): OriginationTier | null {
        const originationFeeInCents = originationFee * 100;
        var originationTier = originationTiers
            .sort((a, b) => a.maxOriginationFeeInCents - b.maxOriginationFeeInCents)
            .find(originationTier => originationFeeInCents <= originationTier.maxOriginationFeeInCents);

        return originationTier ?? null;
    }

    isTieredOriginationEnabled(productTag: ProductTag, brokerSetting: BrokerSettingModel) {
        const isEnabledProduct = productTag === ProductTag.PersonalLoan || brokerSetting.isAutoClassicBroker;
        return isEnabledProduct;
    }
}
