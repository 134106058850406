import { Component, Input, OnInit } from '@angular/core';
import { Scheme } from '../../models';
import { TemplateText } from '../../template-text';

@Component({
    selector: 'scheme-contact',
    templateUrl: './scheme-contact.component.html',
    styleUrls: ['./scheme-contact.component.scss']
})
export class SchemeContactComponent implements OnInit {
    @Input()
    isInline = false;

    @Input()
    scope: Scheme;

    txt: TemplateText;

    ngOnInit(): void {
        this.txt = new TemplateText(this.scope);
    }
}
