<bp-loading-overlay [isLoading]="!documentsRetrieved"></bp-loading-overlay>
<ng-container>
    <h3>Verification documents</h3>
    <mat-card>
        <p>
            The following documents must be uploaded before you can confirm that installation of the {{txt.EligibleEquipment}} has been completed.
            <br />
            These documents will be reviewed to ensure compliance with the terms and conditions of the {{txt.schemeName}}.
        </p>

        <div class="document-upload-section" *ngFor="let requiredDocument of getRequiredDocuments()">
            <p>
                <strong>
                    {{requiredDocument.callToAction}}
                    <span *ngIf="requiredDocument.additionalRequirements.length > 1">
                        <br/>Ensure that:
                    </span>
                </strong>

                <span *ngIf="requiredDocument.additionalRequirements.length === 1">
                    <br/>Ensure that {{requiredDocument.additionalRequirements[0]}}
                </span>
            </p>

            <ul *ngIf="requiredDocument.additionalRequirements.length > 1">
                <li *ngFor="let requirement of requiredDocument.additionalRequirements">
                    {{requirement}}
                </li>
            </ul>

            <mat-expansion-panel *ngIf="requiredDocument.type === quoteDocumentUploadTypes.InstallationPhotograph"
                class="detailed-instructions-panel" #panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Required photographs</mat-panel-title>
                    <mat-panel-description>Click to {{panel.expanded ? 'hide' : 'expand'}}</mat-panel-description>
                </mat-expansion-panel-header>
                <nsw-document-requirements *ngIf="!isSapnScheme()" [quoteFormModel]="quoteFormModel"></nsw-document-requirements>
            </mat-expansion-panel>

            <quote-form-upload [quoteExternalId]="quoteExternalId" [quoteDocumentUploadType]="requiredDocument.type"
                [uploadedDocuments]="uploadedDocuments[requiredDocument.type]" (onUploadedDocumentsRequested)="getUploadedDocuments()"
                (onDeleteDocument)="onDeleteDocument($event)" [useMultiUpload]="requiredDocument.useMultiUpload" [canDelete]="canDeleteDocument">
            </quote-form-upload>
        </div>
    </mat-card>
</ng-container>

