import { Pipe, PipeTransform } from '@angular/core';
import { CreditStatusService } from '../services/credit-status.service';
import { ProductTag } from '../enums';

@Pipe({ name: 'productTagDescription' })
export class ProductTagDescriptionPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: ProductTag): string {
        // Value is expected to be a ProductTag
        return this.creditStatusService.getProductTagDescription(value);
    }
}

@Pipe({ name: 'productTagDescriptionFromString' })
export class ProductTagDescriptionFromStringPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: string): string {
        // Value is expected to be string version of ProductTag, e.g. 'PersonalLoan'
        return this.creditStatusService.getProductTagDescription(ProductTag[value]);
    }
}
