import { FormControl } from '@angular/forms';

const required = (value: any): boolean => !value && value !== false;
export function requiredWhenDependentPositive(dependentContainer: any, dependentFieldName: string) {
    return (control: FormControl): { [key: string]: any } => {
        if (dependentContainer[dependentFieldName] > 0 && required(control.value)) {
            return { required: true };
        }
    };
}
