import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuoteRetrievedModel, Scheme } from '../../models';

@Component({
    selector: 'view-quotes-button',
    templateUrl: './view-quotes-button.component.html'
})
export class ViewQuotesButtonComponent {
    private get quote() {
        return (this.activatedRoute.snapshot.data['quote'] as QuoteRetrievedModel) || null;
    }
    private get scope() {
        return (
            (this.activatedRoute.snapshot.data['scope'] as Scheme) ||
            (this.quote ? this.quote.quoteFormModel.scope : null)
        );
    }
    private get isEaas() {
        return (
            (this.activatedRoute.snapshot.data['isEaas'] as boolean) ||
            (this.quote ? this.quote.quoteFormModel.isEaas : null)
        );
    }
    get quoteListUrl() {
        if (this.scope === Scheme.SouthAustraliaPowerNetworks) {
            return '/sapn-quotes';
        }

        if (this.isEaas) return '/eaas-quotes';

        return this.scope === Scheme.NewSouthWales ? '/nsw-quotes' : '/quotes';
    }

    constructor(private activatedRoute: ActivatedRoute) {}
}
