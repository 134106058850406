import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ManifestConfigurationContext } from '../enums';
import { WorkflowType } from '../enums/workflow-type.enum';
import { WorkflowModel } from '../models';
import { IWorkflowManifest, IWorkflowManifestConfiguration } from '../models/workflow-manifest';
import { CachedService } from './cached.service';

@Injectable()
export class WorkflowManifestService {
    constructor(private httpClient: HttpClient, private cachedService: CachedService) {
        cachedService.configure(environment.constants.storage.workflowManifestConfigurationKey, 15);
    }

    public clear() {
        this.cachedService.clear();
    }

    public GetManifestByBrokerId(brokerId: string, experience: string = 'Standard'): Observable<IWorkflowManifest> {
        const objectKey = this.getObjectKey(brokerId, experience);
        const cachedValue = this.cachedService.GetCached(objectKey);
        if (cachedValue) return of(cachedValue);

        return this.httpClient.get<IWorkflowManifest>(this.getUrl(brokerId, experience)).pipe(
            map((result: any) => {
                let mappedResult = this.processWorkflowManifest(result);
                this.cachedService.SetCached(objectKey, mappedResult);
                return mappedResult;
            })
        );
    }

    public GetConfigurationsByBrokerIdAndExperience(
        brokerId: string,
        experience: string
    ): Observable<IWorkflowManifestConfiguration[]> {
        return this.GetManifestByBrokerId(brokerId, experience).pipe(
            map((result: IWorkflowManifest) => {
                return result.configurations.filter(config => {
                    return config.experience === experience;
                });
            })
        );
    }

    public GetWorkflowByType(applicationGuid: string, wfType: WorkflowType): Observable<WorkflowModel> {
        return this.httpClient.get<WorkflowModel>(
            `${environment.api.baseUrl}/portal/details/${applicationGuid}/workflow/${wfType}`
        );
    }

    private getObjectKey(brokerId: string, experience: string) {
        return `${brokerId}_${experience}`;
    }

    private getUrl(brokerId: string, experience: string): string {
        return `${environment.api.baseUrl}/WorkflowService/Manifest/partner/${brokerId}/${experience}`;
    }

    private processWorkflowManifest(data: any): IWorkflowManifest {
        return {
            id: data.id,
            partnerId: data.partnerId,
            configurations: data.configurations
                ? data.configurations.map(config => this.processConfiguration(config))
                : []
        };
    }

    private processConfiguration(data: any): IWorkflowManifestConfiguration {
        return {
            id: data.id,
            applicationModel: data.applicationModel,
            uiDefinitionPrefix: data.uiDefinitionPrefix,
            context: ManifestConfigurationContext[<keyof typeof ManifestConfigurationContext>data.context],
            experience: data.experience,
            brokerManifestControlTargets: data.brokerManifestControlTargets,
            cobranding: data.cobranding
        };
    }
}
