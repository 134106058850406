export const toNumber = (value: string) => Number(value.replace(/[^0-9.-]+/g, ''));

export const toCurrency = (value: number): string =>
    value.toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2
    });

export const toPercent = (value: number): string =>
    value.toLocaleString('en-AU', {
        minimumFractionDigits: 2
    }) + '%';
