import { Injectable } from '@angular/core';
import { HalResponse } from '@brokerportal/common/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PackageCategory, CecPackageModel } from '../models';
import { QuoteService } from './quote.service';

@Injectable()
export class QuoteMockService extends QuoteService {
    private baseMockUrl = 'http://localhost:50012/mock-api/quoteservice';

    getCecPackages(category: PackageCategory): Observable<CecPackageModel[]> {
        return this.httpClient
            .get(`${this.baseMockUrl}/${environment.providerBatteryPackagesRoute}/CecPackages?category=${category}`)
            .pipe(
                map((response: HalResponse<{ packages: any[] }>) => {
                    return response._embedded.packages.map<CecPackageModel>(pck => ({
                        packageExternalId: pck.packageExternalId,
                        model: pck.model,
                        brand: pck.brand,
                        category: PackageCategory[pck.category],
                        energyCapacity: pck.energyCapacity,
                        outputPower: pck.outputPower
                    }));
                })
            );
    }
}
