import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '@brokerportal/common/auth/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthenticationService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (environment.maintenanceMode) {
            return this.router.parseUrl('/maintenance');
        }

        if (environment.bypassAuthentication) {
            return true;
        }

        return this.auth.isAuthenticated().pipe(
            map(isAuthenticated => {
                if (isAuthenticated) return true;

                let returnUrl = '';
                if (state && state.url && state.url !== '/') {
                    returnUrl = `?returnUrl=${state.url}`;
                }

                return this.router.parseUrl(`/authentication/login${returnUrl}`);
            })
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }
}
