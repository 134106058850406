import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditableLabel } from './form/editable-label.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialComponentsModule } from 'app/material-components.module';
import { CardLoadingOverlayComponent } from './card-loading-overlay/card-loading-overlay.component';
import { CardLoadingSpinnerOverlayComponent } from './card-loading-spinner-overlay/card-loading-spinner-overlay.component';
import { PageHeaderComponent } from './header/page-header.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Message } from './message/message.component';
import { ResponsiveTableRowComponent } from './responsive-table/responsive-table-row.component';
import { ResponsiveTableComponent } from './responsive-table/responsive-table.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { DropDownListFilterComponent } from './grid/dropdownlist-filter.component';
import {
    PackageSelectComponent,
    PackageDetailsComponent,
    CecPackageSelectComponent
} from '@brokerportal/modules/quote/shared/components';
import { CommonUiModule } from '@brokerportal/common/common.module';
import { AddressSearchComponent, AddressSearchFormControlDirective } from './address/search/search.component';
import { AddressComponent } from './address/address.component';
import { RenewableSystemTypesComponent } from './renewable-system-types/renewable-system-types.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        DropDownListModule,
        CommonUiModule
    ],
    declarations: [
        LoadingOverlayComponent,
        CardLoadingOverlayComponent,
        CardLoadingSpinnerOverlayComponent,
        PageHeaderComponent,
        MaintenanceComponent,
        ResponsiveTableComponent,
        ResponsiveTableRowComponent,
        Message,
        EditableLabel,
        DropDownListFilterComponent,
        PackageSelectComponent,
        PackageDetailsComponent,
        CecPackageSelectComponent,
        AddressSearchComponent,
        AddressSearchFormControlDirective,
        AddressComponent,
        RenewableSystemTypesComponent
    ],
    exports: [
        LoadingOverlayComponent,
        CardLoadingOverlayComponent,
        CardLoadingSpinnerOverlayComponent,
        PageHeaderComponent,
        MaintenanceComponent,
        ResponsiveTableComponent,
        ResponsiveTableRowComponent,
        Message,
        EditableLabel,
        DropDownListFilterComponent,
        PackageSelectComponent,
        PackageDetailsComponent,
        CecPackageSelectComponent,
        AddressSearchComponent,
        AddressSearchFormControlDirective,
        AddressComponent,
        RenewableSystemTypesComponent
    ],
    providers: []
})
export class SharedUiModule {}
