import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'customerNamePipe' })
export class CustomerNamePipe implements PipeTransform {
    transform(value: any): string {
        // Strip all chars except for
        // alphanumerical, hyphen, dot, single quote and space chars
        // g to match all instances
        // i for case insensitive, keeping
        return value.replace(/[^0-9a-z\-.' ]/gi, '');
    }
}
