// refer to BrokerSettingGroup.SettingGroupValueType in venus
export enum BrokerSettingGroupValueType {
    String = 0,
    StringList = 1,
    Bool = 2,
    Integer = 3,
    Money = 4,
    BasicPoint = 5,
    Setting = 6,
    SettingList = 7
}
