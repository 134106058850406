<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
  <div class="maintenance mat-elevation-z1">
    <div class="header" fxLayout="column">
      <img
        [src]="env.constants.branding.images.logoUrl"
      />
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="start stretch">
      <h1 style="text-align: center">System Maintenance</h1>
      <p style="text-align: center">
        The {{env.constants.branding.title}} Broker Portal is offline for approximately {{env.maintenanceEstimate}} for system
        maintenance.
      </p>
      <hr />
      <p style="text-align: center">
        Please contact us on 1300 768 710 if you require application support.
      </p>
    </div>
  </div>
</div>
