import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'bp-message',
    templateUrl: 'message.component.html',
    styleUrls: ['./message.scss']
})
export class Message implements OnInit {
    @Input()
    message: String;

    @Input()
    type: String;

    constructor() {}

    ngOnInit() {}
}
