export enum ApplicationPrefillKeys {
    clientEmail,
    systemCost,
    deposit,
    term,
    systemDetails,
    systemType,
    estimatedElectricityBillSavings,
    electricityBillSavingsFrequency,
    solarPanelSize,
    batterySize,
    pricingExplanationConfirmation,
    invoiceNumber,
    contractAccountNumber,
    loanAmount,
    monthlyRepayment
}
