<div>
    <div *ngIf="uploadedDocuments && uploadedDocuments.length > 0">
        <table class="table table-grey-header table-bordered table-hover">
            <thead>
                <tr>
                    <td>Submitted</td>
                    <th>Type</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let document of uploadedDocuments">
                    <td width="30%" fxHide.lt-md>{{ (document.dateSubmitted | amDateFormat: 'dd-MMM-yyyy ') + ('local' | timezoneFormat)}}</td>
                    <td width="30%">{{ quoteDocumentUploadTypeLabel }}</td>
                    <td width="10%">
                        <button mat-mini-fab (click)="downloadDocument(document.guid); $event.preventDefault();" matTooltip="Download document">
                            <mat-icon class="md-24" aria-label="Download">file_download</mat-icon>
                        </button>
                    </td>
                    <td width="10%">
                        <button mat-mini-fab *ngIf="canDelete" (click)="deleteDocument(document); $event.preventDefault();" matTooltip="Delete document">
                            <mat-icon class="md-24" aria-label="Delete">delete</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="!uploadedDocuments || uploadedDocuments.length === 0">
        <p>
            No {{ quoteDocumentUploadTypeButtonLabel }} has been uploaded.
        </p>
    </div>
    <div>
        <button mat-raised-button (click)="openUploadDialog(); $event.preventDefault();">
            <mat-icon>file_upload</mat-icon> Upload {{ quoteDocumentUploadTypeButtonLabel }}
        </button>
    </div>
</div>
