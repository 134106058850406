import { FormControl } from '@angular/forms';
import { NamedAbstractControl } from '../models';

export function dependentValidator(dependentFields: NamedAbstractControl[], dependentFieldNames?: string[]) {
    let savedControl: FormControl;
    let watchedFieldControls: NamedAbstractControl[];

    return (control: FormControl): { [key: string]: any } => {
        if (!savedControl) {
            savedControl = control;
            watchedFieldControls = dependentFields || [];
            dependentFieldNames = dependentFieldNames || [];
            dependentFieldNames.forEach(fieldName =>
                watchedFieldControls.push({ name: fieldName, control: savedControl.parent.get(fieldName) })
            );

            watchedFieldControls.forEach(namedControl =>
                namedControl.control.valueChanges.subscribe(() =>
                    savedControl.updateValueAndValidity({ emitEvent: false })
                )
            );
        }

        let hasErrors = false;
        const errors = {};
        watchedFieldControls.forEach(watched => {
            if (!watched.control.valid) {
                errors[watched.name] = 'true';
                hasErrors = true;
            }
        });
        return hasErrors ? errors : null;
    };
}
