import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'financial-table-value',
    templateUrl: './financial-table-value.component.html',
    styleUrls: ['./financial-table-value.component.scss']
})
export class FinancialTableValueComponent implements OnInit, OnChanges {
    @Input()
    value: number;

    currencySymbol: string;
    leadingZeroes: string;
    significantDigits: string;

    constructor() {}

    ngOnInit() {
        this.formatValue();
    }

    ngOnChanges() {
        this.formatValue();
    }

    formatValue() {
        const value = this.value ? this.value : 0;
        const minIntegerDigits = 6;
        const maxFractionDigits = 2;
        const minFractionDigits = 2;

        const currencyTransformed = new CurrencyPipe('en_AU').transform(
            value,
            'AUD',
            'symbol-narrow',
            `${minIntegerDigits}.${minFractionDigits}-${minFractionDigits}`
        );

        const firstNonZero = currencyTransformed.search(/[1-9]|(0\.)/);
        this.leadingZeroes = `${currencyTransformed.substring(1, firstNonZero)}`;
        this.significantDigits = `${currencyTransformed.substring(firstNonZero)}`;
        this.currencySymbol = `${currencyTransformed.substring(0, 1)}`;
    }
}
