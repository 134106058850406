import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HalResponse } from '@brokerportal/common/models';
import { Scheme, SubsidyPriceStep } from '../models';

interface GetSubsidyPriceStepsResponse extends HalResponse<{ steps: SubsidyPriceStep[] }, 'self'> {
    scheme: Scheme;
    totalSteps: number;
}

@Injectable()
export class SubsidyPriceService {
    private readonly baseUrl = `${
        environment.useDirectQuoteService ? environment.quoteServiceHost : environment.api.baseUrl
    }`;

    constructor(private httpClient: HttpClient) {}

    getStepsByScope(scope: string) {
        return this.httpClient
            .get<GetSubsidyPriceStepsResponse>(`${this.baseUrl}/SubsidyPrices/${scope}/steps`)
            .pipe(map(res => (res && res._embedded.steps) || []));
    }
}
