import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'amDateFormat' })
export class AmDateFormatPipe implements PipeTransform {
    transform(value: number | string | Date, formatString: string): number | string | Date {
        if (value && formatString) {
            let d: string | Date;

            const type = typeof value;

            switch (type) {
                case 'number': {
                    d = new Date(value);
                    break;
                }
                case 'string': {
                    d = DateTime.fromISO(value as string, { zone: 'utc' })
                        .toLocal()
                        .toJSDate();
                    break;
                }
                default: {
                    d = value as Date;
                    break;
                }
            }

            const outputFormat = formatString === 'LL' ? 'd MMMM yyyy' : formatString;

            return DateTime.fromJSDate(d)
                .toFormat(outputFormat)
                .toString();
        }

        return value;
    }
}
