import { FormControl } from '@angular/forms';

export function minimumNumberValidator(dependentContainer: any, dependentFieldName: string, minimumNumber: number) {
    return (control: FormControl): { [key: string]: any } => {
        let minimum = minimumNumber == null ? 0 : minimumNumber;
        if (dependentContainer[dependentFieldName] < minimum) {
            return { minimumValue: true };
        }
    };
}
