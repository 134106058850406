import { Pipe, PipeTransform } from '@angular/core';
import { CreditStatusService } from '../services/credit-status.service';

@Pipe({ name: 'riskTierDescription' })
export class RiskTierDescriptionPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: string): string {
        return this.creditStatusService.getRiskTierDescription(value);
    }
}
