import { Pipe, PipeTransform } from '@angular/core';
import { LoanConditionStatus } from '../models/application/application-details-loan-condition.model';

@Pipe({ name: 'loanConditionStatusDescription' })
export class LoanConditionStatusDescriptionPipe implements PipeTransform {
    constructor() {}

    transform(value: LoanConditionStatus): string {
        switch (value) {
            case LoanConditionStatus.Met: {
                return 'Condition has been met';
            }
            case LoanConditionStatus.NotMet: {
                return 'Condition has not been satisfied';
            }
            case LoanConditionStatus.Pending: {
                return 'Condition is yet to be satisfied';
            }
            default: {
                return 'N/A';
            }
        }
    }
}
