import { KeyValuePair } from '../enums';

const getExtraField = (extraFields: KeyValuePair[], fieldKey: string): KeyValuePair => {
    return (
        extraFields.find(_ => _.key.toLocaleLowerCase() === fieldKey.toLocaleLowerCase()) ||
        <KeyValuePair>{
            key: fieldKey,
            value: null
        }
    );
};

export const getExtraFieldValue = (extraFields: Array<KeyValuePair>, fieldKey: string): string => {
    const extraField = getExtraField(extraFields, fieldKey);

    return extraField.key ? extraField.value : '';
};
