import { AbstractControl, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Determines whether the current control is in error state by checking another control's error state.
 *
 * @export
 * @class DependentControlErrorMatcher
 * @implements {ErrorStateMatcher}
 */
export class DependentControlErrorMatcher implements ErrorStateMatcher {
    /**
     * Creates an instance of DependentControlErrorMatcher.
     * @param {AbstractControl} control the source control that the target control depends on.
     * @memberof DependentControlErrorMatcher
     */
    constructor(readonly control: AbstractControl) {}
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return this.control && this.control.touched && (this.control.invalid || !!this.control.errors);
    }
}
