import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './confirm-dialog.quote.renewal.component.html'
})
export class QuoteRenewalConfirmDialog {
    today: Date;
    isMobile: boolean;
    newExpiryDate: Date;

    private quoteExternalId: string;
    private onRenewQuote: Function;

    constructor(
        private dialogRef: MatDialogRef<QuoteRenewalConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.quoteExternalId = this.data.quoteExternalId;
        this.isMobile = this.data.isMobile;
        this.onRenewQuote = this.data.onRenewQuote;

        const now = new Date();
        this.today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }

    handleRenewQuote(): void {
        if (this.isValidNewExpiryDate() && this.onRenewQuote) {
            const newExpiryDate = this.toEndOfDay(this.newExpiryDate);
            this.onRenewQuote(newExpiryDate);
        }
    }

    isValidNewExpiryDate(): boolean {
        const newExpiryDate = this.toEndOfDay(this.newExpiryDate);
        return newExpiryDate && Date.now() < newExpiryDate.getTime();
    }

    private toEndOfDay = (date): Date => {
        if (!date) {
            return null;
        }

        const endOfDay = new Date(date);
        endOfDay.setHours(23, 59, 59);
        return endOfDay;
    };
}
