import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileRestrictions, SelectEvent, SuccessEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { AuthenticationService } from '@brokerportal/common/auth/authentication.service';
import { environment } from 'environments/environment';
import { takeWhile } from 'rxjs/operators';
import { QuoteDocumentUploadTypeLabels, QuoteDocumentUploadTypes } from '../../models';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog.component';

@Component({
    templateUrl: './quote-form-upload-dialog.component.html',
    styleUrls: ['./quote-form-upload-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuoteFormUploadDialog implements OnInit, OnDestroy {
    private quoteExternalId: string;
    private uploadSaveUrl: string;

    quoteDocumentUploadType: QuoteDocumentUploadTypes;
    quoteDocumentUploadTypeLabels = QuoteDocumentUploadTypeLabels;
    thumbnails = [];
    uploading = false;
    imageExtensions = ['.jpg', '.jpeg', '.png'];

    uploadRestrictions: FileRestrictions;

    quoteDocumentUploadTypeLabel: string;
    multiUpload: boolean;
    alive = true;
    attemptedUpload = false;

    constructor(
        public dialogRef: MatDialogRef<QuoteFormUploadDialog>,
        public confirmDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authenticationService: AuthenticationService
    ) {
        this.dialogRef.disableClose = true;
        this.dialogRef
            .backdropClick()
            .pipe(takeWhile(() => this.alive))
            .subscribe(() => this.closeHandler());

        this.quoteExternalId = this.data.quoteExternalId;
        this.quoteDocumentUploadType = this.data.quoteDocumentUploadType;
        this.quoteDocumentUploadTypeLabel = this.data.quoteDocumentUploadTypeLabel;
        this.multiUpload = this.data.useMultiUpload;
        this.uploadRestrictions = {
            allowedExtensions: ['.jpg', '.jpeg', '.png', '.pdf'],
            maxFileSize: environment.maxUploadFileSize
        };
        if (this.multiUpload) {
            this.uploadRestrictions.allowedExtensions.push('zip');
        }
    }

    ngOnDestroy() {
        this.alive = false;
    }

    ngOnInit() {
        this.uploadSaveUrl = `${
            environment.useDirectQuoteService ? environment.quoteServiceHost : environment.api.baseUrl
        }/quotes/${this.quoteExternalId}/documents`;
    }

    selectEventHandler(event: SelectEvent) {
        this.uploading = event.files.reduce((acc, nextFile) => acc || !nextFile.validationErrors, this.uploading);
        if (!this.multiUpload) {
            return;
        }

        event.files.forEach(file => {
            if (file.validationErrors) {
                return;
            }

            if (!this.imageExtensions.includes(file.extension)) {
                this.thumbnails.unshift({
                    uid: file.uid,
                    name: file.name
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = onLoadEvent => {
                const image = {
                    src: onLoadEvent.target['result'],
                    uid: file.uid,
                    name: file.name,
                    isImage: true
                };
                this.thumbnails.unshift(image);
            };
            reader.readAsDataURL(file.rawFile);
        });
    }

    uploadFinishedHandler(event: SuccessEvent) {
        this.uploading = false;
    }

    uploadEventHandler(event: UploadEvent) {
        this.attemptedUpload = true;
        event.headers = event.headers.append('DocumentType', this.quoteDocumentUploadType.toString());
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.closeHandler();
    }

    closeDialog() {
        this.dialogRef.close({ uploaded: this.attemptedUpload });
    }

    closeHandler() {
        if (!this.uploading) {
            this.closeDialog();
            return;
        }

        this.confirmDialog
            .open(ConfirmDialog, {
                data: {
                    title: 'Cancel upload',
                    action: `Are you sure? Closing will cancel your pending uploads.`,
                    defaultNo: true
                }
            })
            .afterClosed()
            .subscribe(result => {
                if (result === 'confirm') {
                    this.closeDialog();
                }
            });
    }

    completeEventHandler(event: SuccessEvent) {
        if (!this.multiUpload) {
            this.closeDialog();
        }
    }

    isFeatureUploadToDSSEnabled(): boolean {
        return environment.features.UploadBrokerDocumentsToDSS;
    }
}
