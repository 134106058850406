import { Component, Inject, SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialog {
    sanitizedAction: string | null;
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialog>,
        sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.sanitizedAction = sanitizer.sanitize(SecurityContext.HTML, data.action);
    }
}
