import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    InjectFlags,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroupDirective,
    NG_ASYNC_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,
    ValidationErrors
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { fromEvent, Observable, of, ReplaySubject, Subject, timer, interval } from 'rxjs';
import {
    debounce,
    distinctUntilChanged,
    finalize,
    map,
    shareReplay,
    skipWhile,
    switchMap,
    takeUntil,
    tap,
    takeWhile
} from 'rxjs/operators';

import { CecPackageModel, PackageCategory } from '@brokerportal/modules/quote/shared/models';
import { QuoteService } from '@brokerportal/modules/quote/shared/services';
import { DependentControlErrorMatcher } from '@brokerportal/modules/shared/core';

interface CecPackageSelectionChangeEvent {
    value: string;
    package: CecPackageModel;
    raiseEvent?: boolean;
}

export type CecPackageSelectFilterStrategy = 'default' | 'onDemand';

@Component({
    selector: 'bp-cec-package-select',
    templateUrl: './cec-package-select.component.html',
    styleUrls: ['./cec-package-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CecPackageSelectComponent),
            multi: true
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => CecPackageSelectComponent),
            multi: true
        }
    ],
    host: {
        class: 'bp-cec-package-select'
    }
})
export class CecPackageSelectComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, AsyncValidator {
    @Input() value: string;
    @Input() category: PackageCategory | string;
    @Input() disabled = false;
    @Input() placeholder: string;
    @Input() filterStrategy: CecPackageSelectFilterStrategy = 'default';
    @Output() readonly selectedPackageChange = new EventEmitter<CecPackageModel>();
    @ViewChild('input', { static: true }) private input: ElementRef<HTMLInputElement>;
    @ViewChild(MatInput, { static: true }) private matInput: MatInput;
    @ViewChild(MatAutocompleteTrigger) private autocompleteTrigger: MatAutocompleteTrigger;

    private control: AbstractControl;

    private touched = false;

    private _selectedPackage: CecPackageModel;
    public get selectedPackage() {
        return this._selectedPackage;
    }

    private _packages: CecPackageModel[] = [];
    public get packages() {
        return this._packages;
    }

    private _filteredPackages: CecPackageModel[] = [];
    public get filteredPackages() {
        return this._filteredPackages;
    }

    private _loading = false;
    public get loading() {
        return this._loading;
    }

    private selectionChange = new EventEmitter<CecPackageSelectionChangeEvent>();
    private destroy = new Subject();
    private package$: Observable<CecPackageModel>;
    private getPackageSub = new ReplaySubject<string>(1);

    constructor(
        @Inject(Injector) private injector: Injector,
        private renderer: Renderer2,
        private quoteService: QuoteService
    ) {
        this.package$ = this.getPackageSub.pipe(
            distinctUntilChanged(),
            tap(() => (this._loading = true)),
            switchMap(id => this.quoteService.getCecPackage(id).pipe(finalize(() => (this._loading = false)))),
            shareReplay(1)
        );
    }

    ngOnInit() {
        const ngControl = this.injector.get(NgControl, null, InjectFlags.Self);

        if (ngControl) {
            switch (ngControl.constructor) {
                case NgModel: {
                    const { control, update } = ngControl as NgModel;

                    this.control = control;
                    this.control.valueChanges
                        .pipe(
                            tap(value => update.emit(value)),
                            takeUntil(this.destroy)
                        )
                        .subscribe();
                    break;
                }
                case FormControlName: {
                    this.control = ((ngControl as FormControlName).formDirective as FormGroupDirective).getControl(
                        ngControl as FormControlName
                    );
                    break;
                }
                default: {
                    this.control = (ngControl as FormControlDirective).form;
                    break;
                }
            }
            this.matInput.errorStateMatcher = new DependentControlErrorMatcher(this.control);
        }
        fromEvent<InputEvent>(this.input.nativeElement, 'input')
            .pipe(
                map(e => (e.target as HTMLInputElement).value),
                debounce(() => timer(this.filterStrategy === 'onDemand' ? 300 : 0))
            )
            .subscribe(val => this.filter(val));
        this.selectionChange.subscribe(event => {
            this.handleSelectionChange(event);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('filterStrategy' in changes && this.filterStrategy === 'onDemand') {
            //Clear current packages when filter strategy changes.
            this._packages = [];
            this._filteredPackages = [];
        }
        if (this.filterStrategy !== 'onDemand') {
            //Pre-fetch all packages on 'default' strategy.
            if ('category' in changes) {
                this.fetchPackagesOnCategoryChangeForDefaultFilterStrategy(
                    'value' in changes && !changes['value'].firstChange
                );
            } else if ('value' in changes) {
                this.selectionChange.emit({
                    value: this.value,
                    package: this.get(this.value),
                    raiseEvent: !changes['value'].firstChange
                });
            }
        } else if ('value' in changes) {
            this.getPackage(this.value).subscribe(result => {
                this._filteredPackages = [result];
                this.selectionChange.emit({
                    value: this.value,
                    package: result,
                    raiseEvent: !changes['value'].firstChange
                });
            });
        }
        if ('disabled' in changes) {
            if (this.control) {
                this.disabled ? this.control.disable() : this.control.enable();
            }
        }
    }

    private fetchPackagesOnCategoryChangeForDefaultFilterStrategy(isValueChanged: boolean) {
        this.getPackages().subscribe(packages => {
            this._packages = packages;
            this._filteredPackages = packages.slice();
            if (this.value) {
                this.selectionChange.emit({
                    value: this.value,
                    package: this.get(this.value),
                    raiseEvent: isValueChanged
                });
                if (this.control) {
                    this.control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                }
            }
        });
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    // Placeholder methods only. Will be replaced with actual methods if registered.
    private onChange = (obj: any) => {};
    private onTouched = () => {};

    //#region ControlValueAccessor methods
    writeValue(obj: string) {
        this.value = obj;
        if (this.filterStrategy !== 'onDemand' && this.packages.length === 0) return;
        if (!this.selectedPackage || this.selectedPackage.packageExternalId !== obj) {
            if (this.filterStrategy !== 'onDemand') {
                this.selectionChange.emit({
                    value: this.value,
                    package: this.get(this.value),
                    raiseEvent: false
                });
            } else if (obj) {
                this.getPackage(obj).subscribe(pkg => {
                    this._filteredPackages = [pkg];
                    this.selectionChange.emit({
                        value: this.value,
                        package: pkg,
                        raiseEvent: false
                    });
                });
            }
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.matInput.ngControl.control.markAsTouched();
            this.touched = true;
        }
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
    //#endregion ControlValueAccessor methods

    //#region Validator methods
    validate(control: FormControl) {
        const validate$ = of<string>(control.value).pipe(
            map((value): ValidationErrors | null => {
                /**
                 * Validate the package if control's value matches a unique id.
                 * Otherwise, validate whether a package has been selected.
                 */
                if (
                    value?.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)?.length >
                    0
                ) {
                    const pkg = this.get(value);
                    if (control.value && !this.selectedPackage) {
                        return !pkg ? { notfound: control.value } : { invalid: true };
                    }
                    return null;
                }
                return control.value && !this.selectedPackage ? { invalid: true } : null;
            }),
            tap(errors => this.matInput.ngControl.control.setErrors(errors))
        );

        if (this.loading) {
            //Wait until the loading process finishes and start the validation.
            return interval(300).pipe(
                takeWhile(() => this.loading, true),
                skipWhile(() => this.loading),
                switchMap(() => validate$)
            );
        }
        return validate$;
    }
    //#endregion Validator methods

    filter(val: string) {
        this._selectedPackage = null;
        this.onChange(val);
        if (!val) {
            this._filteredPackages = this.packages.slice();
        } else {
            const searchText = val.toLowerCase();
            if (this.filterStrategy === 'onDemand') {
                this.getPackages(searchText).subscribe(result => {
                    this._filteredPackages = result;
                    if (!this.disabled) {
                        //Input loses focus after being re-enabled, so we need to re-focus.
                        setTimeout(() => this.input.nativeElement.focus(), 0);
                    }
                });
            } else {
                this._filteredPackages = this.packages.filter(
                    pkg =>
                        pkg.model.toLowerCase().indexOf(searchText) >= 0 ||
                        pkg.brand?.toLowerCase().indexOf(searchText) >= 0
                );
            }
        }
    }

    clear() {
        this.selectionChange.emit({ value: undefined, package: undefined });
        this._filteredPackages = this.packages.slice();
    }

    select(value: string) {
        this.selectionChange.emit({ value, package: this.get(value) });
        this.markAsTouched();
    }

    get(value: string) {
        return (this.filterStrategy !== 'onDemand' ? this.packages : this.filteredPackages)?.find(
            _ => _.packageExternalId === value
        );
    }

    openPanel() {
        this.autocompleteTrigger.openPanel();
    }

    _getDisplayText(pkg: CecPackageModel) {
        return (pkg && pkg.model) || '';
    }

    _onSelected(pkg: CecPackageModel) {
        this.selectionChange.emit({ value: pkg?.packageExternalId, package: pkg });
    }

    private handleSelectionChange(event: CecPackageSelectionChangeEvent) {
        this.value = event.value;
        this._selectedPackage = event.package;
        this.setInputValue(this._getDisplayText(this.selectedPackage) || this.value);
        if (event.raiseEvent !== false) {
            this.selectedPackageChange.emit(this.selectedPackage);
            this.onChange(this.value);
        }
    }

    private setInputValue(val: string | undefined | null) {
        val = val || '';
        this.renderer.setProperty(this.input.nativeElement, 'value', val);
    }

    private getPackage(packageId: string) {
        this.getPackageSub.next(packageId);
        return this.package$;
    }

    private getPackages(text?: string) {
        this._loading = true;
        return this.quoteService
            .getCecPackages(this.category as PackageCategory, { text: text })
            .pipe(finalize(() => (this._loading = false)));
    }
}
