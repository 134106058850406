import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { StepName, ProductTag } from '../enums';

@Injectable()
export class ApplicationLinksService {
    getInitiateApplicationLink(brokerId: string, isBroker: boolean) {
        // TODO: Encode parameters in URL like broker Email etc so this is picked up by Venus
        return environment.constants.urls.continueApplication
            .replace('{brokerId}', brokerId)
            .replace('{isBroker}', isBroker.toString());
    }

    getContinueApplicationLink(applicationGuid: string, isBroker: boolean) {
        return environment.constants.urls.continueApplication
            .replace('{applicationId}', applicationGuid)
            .replace('{isBroker}', isBroker.toString());
    }

    getContinueApplicationStepLink(applicationGuid: string, isBroker: boolean, step: StepName) {
        return environment.constants.urls.continueApplicationStep
            .replace('{applicationId}', applicationGuid)
            .replace('{isBroker}', isBroker.toString())
            .replace('{stepName}', StepName[step]);
    }

    appendCustomerReference(link: string, customerReference: string): string {
        return this.appendQueryParameter(link, 'cust_ref', customerReference);
    }

    appendAnchorLocation(link: string, anchorLocation: string) {
        return this.appendQueryParameter(link, 'sloc', anchorLocation);
    }

    appendQueryParameter(link: string, parameterName: string, parameterValue: string) {
        if (link.includes(parameterName)) {
            return;
        }

        if (link.includes('?')) {
            link = `${link}&`;
        } else {
            link = `${link}?`;
        }

        return `${link}rsform_${parameterName}=${parameterValue}`;
    }

    getReferApplicationLink(applicationGuid: string, isBroker: boolean) {
        return environment.constants.urls.referExistingApplication
            .replace('{applicationId}', applicationGuid)
            .replace('{isBroker}', isBroker.toString());
    }

    getReferLink(referralCode: string, isBroker: boolean, data: string) {
        return environment.constants.urls.referApplication
            .replace('{referralCode}', referralCode)
            .replace('{isBroker}', isBroker.toString())
            .replace('{data}', data);
    }

    getIdentityStepLink(applicationId: string) {
        return applicationId;
    }

    getBankDetailsStepLink(applicationId: string) {
        return applicationId;
    }

    isNewJourney(productTag: ProductTag) {
        return (
            (productTag === ProductTag.PersonalLoan ||
                productTag === ProductTag.CarLoan ||
                productTag === ProductTag.CommercialCarLoan ||
                productTag === ProductTag.GreenLoan ||
                productTag === ProductTag.RenewableBrokerLoan ||
                productTag === ProductTag.RenewableInterestFree ||
                productTag === ProductTag.SARenewableEnergy) &&
            environment.features[`NewJourney${ProductTag[productTag]}`] === true
        );
    }
}
