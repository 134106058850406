import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import {
    Component,
    Directive,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Inject,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Provider,
    Renderer2,
    Self,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    ControlValueAccessor,
    FormControlDirective,
    FormControlName,
    FormGroupDirective,
    NG_ASYNC_VALIDATORS,
    NgControl,
    NgModel,
    ValidationErrors,
    NG_VALIDATORS
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    share,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import { HarmonyAddress, HarmonyAddressRestResponse, HarmonyClient } from '@brokerportal/common/services/harmony';

export const BP_ADDRESS_SEARCH_FORM_FIELD_CONTROL: Provider = {
    provide: MatFormFieldControl,
    useExisting: forwardRef(() => AddressSearchComponent)
};

@Component({
    selector: 'bp-address-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [BP_ADDRESS_SEARCH_FORM_FIELD_CONTROL],
    host: {
        class: 'bp-address-search'
    }
})
export class AddressSearchComponent
    implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, MatFormFieldControl<string> {
    static nextId = 0;
    /**
     * Maximum number of items to be displayed in the results list.
     * Will fallback to default value if an invalid value is supplied.
     * @default 10
     * @memberof AddressSearchComponent
     */
    @Input()
    public get maxItems() {
        return this._maxItems;
    }
    public set maxItems(value) {
        this._maxItems = coerceNumberProperty(value, 10);
    }
    private _maxItems = 10;
    /**
     * Whether to automatically select the single result when full address matches exactly.
     * @default false
     * @memberof AddressSearchComponent
     */
    @Input() autoSelectExactMatch = false;
    @Output() readonly selectedValueChange = new EventEmitter<HarmonyAddress>();
    @Output() readonly searchCompleted = new EventEmitter<void>();
    @Output() readonly searchError = new EventEmitter<string[]>();
    @ViewChild('input', { static: true, read: ElementRef }) private inputElementRef: ElementRef<HTMLInputElement>;
    @ViewChild('input', { static: true, read: NgModel }) private inputControl: NgModel;

    //#region MatFormFieldControl properties
    @Input() value: string;
    readonly stateChanges = new Subject<void>();
    @HostBinding() id = `bp-address-search-${AddressSearchComponent.nextId++}`;
    @Input() placeholder: string;
    _focused = false;
    get focused() {
        return this._focused;
    }
    get empty() {
        return !this.value;
    }
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    @Input()
    public get required() {
        return this._required;
    }
    public set required(value) {
        this._required = coerceBooleanProperty(value);
    }
    private _required = false;
    @Input()
    public get disabled() {
        return this._disabled;
    }
    public set disabled(value) {
        this._disabled = coerceBooleanProperty(value);
    }
    private _disabled = false;
    get errorState(): boolean {
        return (
            (this.control?.invalid || (this.required && this.empty) || this.errorMessages.length > 0) && this.touched
        );
    }
    readonly controlType = 'address-search';
    readonly autofilled?: boolean;
    @Input('aria-describedby') userAriaDescribedBy: string;
    //#endregion MatFormFieldControl properties

    private _selectedValue: HarmonyAddress;
    public get selectedValue() {
        return this._selectedValue;
    }
    private _results: HarmonyAddress[] = [];
    public get results(): readonly HarmonyAddress[] {
        return this._results;
    }
    private _errorMessages: string[] = [];
    public get errorMessages(): readonly string[] {
        return this._errorMessages;
    }
    private touched = false;
    private _pending = false;
    public get pending() {
        return this._pending;
    }

    private searchTrigger$ = new ReplaySubject<string | null>(1);
    readonly resultChanges$!: Observable<HarmonyAddressRestResponse>;

    private control: AbstractControl;

    constructor(
        private renderer: Renderer2,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) private _formField: MatFormField,
        @Optional() @Self() public readonly ngControl: NgControl,
        @Optional() @Self() public readonly formControlName: FormControlName,
        harmonyClient: HarmonyClient
    ) {
        if (this.ngControl) {
            // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
        this.resultChanges$ = this.searchTrigger$.pipe(
            filter(val => !!val),
            tap(() => (this._pending = true)),
            switchMap(value =>
                harmonyClient.fullAddress(value).pipe(
                    // DEVNOTES: The outer stream is hot stream, which terminates and closes on error.
                    // To keep it alive, catch the error in the inner stream and return a new observable
                    // of the response with properties that can be used to determine the error.
                    // !DO NOT RETHROW!
                    catchError((err, caught) => {
                        console.error('Harmony address search error', err);
                        const messages = ['We have encountered some error during the request. Please try again later.'];
                        return of(<HarmonyAddressRestResponse>{ status: 'ERROR', messages });
                    })
                )
            ),
            tap(() => (this._pending = false)),
            share()
        );
    }

    ngOnInit() {
        if (this.ngControl) {
            switch (this.ngControl.constructor) {
                case NgModel: {
                    const { control, update } = this.ngControl as NgModel;

                    this.control = control;
                    this.control.valueChanges
                        .pipe(
                            tap(value => update.emit(value)),
                            takeUntil(this.stateChanges)
                        )
                        .subscribe();
                    break;
                }
                case FormControlName: {
                    const formControlName = this.ngControl as FormControlName;
                    this.control = (formControlName.formDirective as FormGroupDirective).getControl(formControlName);
                    break;
                }
                default: {
                    this.control = (this.ngControl as FormControlDirective).form;
                    break;
                }
            }
        }

        this.inputControl.valueChanges
            .pipe(
                filter(() => this.inputControl.dirty),
                distinctUntilChanged(),
                debounceTime(500)
            )
            .subscribe(val => {
                if (typeof val === 'string') {
                    this.value = val;
                    this.onChange(this.value);
                    this.search(val);
                } else {
                    this.handleSelectionChange(val);
                }
            });

        this.resultChanges$.subscribe({
            next: ({ status, messages, payload }) => {
                this._errorMessages = messages;
                if (status === 'ERROR') {
                    this.searchError.emit(this._errorMessages.slice());
                } else {
                    if (payload?.length > 0) {
                        const exactMatchedAddress = payload.find(
                            address => address.fullAddress.toLowerCase() === this.value.toLowerCase()
                        );
                        if (exactMatchedAddress && this.autoSelectExactMatch) {
                            this.handleSelectionChange(exactMatchedAddress, false);
                        } else {
                            this._results = payload;
                        }
                    }
                    this.searchCompleted.emit();
                }
            },
            error: err => {
                this.searchError.emit(this._errorMessages.slice());
            }
        });

        if (!!this.value) {
            this.searchTrigger$.next(this.value);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('value' in changes) {
            if (!changes['value'].firstChange) {
                this.inputControl.control.setValue(this.value);
            }
        }
        if ('disabled' in changes) {
            if (this.control) {
                this.disabled ? this.control.disable() : this.control.enable();
            }
        }
        this.stateChanges.next();
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.searchTrigger$.complete();
    }

    // Placeholder methods only. Will be replaced with actual methods when registered.
    private onChange = (obj: any) => {};
    private onTouched = () => {};

    //#region ControlValueAccessor methods
    writeValue(obj: string) {
        this.value = obj;
        this.setInputValue(obj);
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    markAsTouched() {
        if (!this.touched) {
            this.touched = true;
            this.onTouched();
            this.inputControl.control.markAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
    //#endregion ControlValueAccessor methods

    // #region MatFormFieldControl methods
    setDescribedByIds(ids: string[]) {
        if (ids.length) {
            this.inputElementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
        } else {
            this.inputElementRef.nativeElement.removeAttribute('aria-describedby');
        }
    }
    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.inputElementRef.nativeElement.focus();
        }
    }
    // #endregion MatFormFieldControl methods

    search(value: string) {
        const selectedValueChanged = !!this.selectedValue;
        this._selectedValue = null;
        selectedValueChanged ? this.selectedValueChange.emit(this.selectedValue) : null;

        this._errorMessages = [];
        this._results = [];

        if (value) {
            this.searchTrigger$.next(value);
        }
    }

    clear() {
        this.handleSelectionChange(undefined);
        this._results = [];
    }

    select(index: number) {
        this.handleSelectionChange(this.results[index]);
    }

    _focus(event: FocusEvent) {
        if (!this.focused) {
            this._focused = true;
            this.stateChanges.next();
        }
    }

    _blur(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this._focused = false;
            this.markAsTouched();
            this.stateChanges.next();
        }
    }

    _getDisplayText(address: HarmonyAddress) {
        return (address && address.fullAddress) || '';
    }

    _addressOptionSelected(address: HarmonyAddress) {
        this.handleSelectionChange(address);
    }

    private handleSelectionChange(address: HarmonyAddress, raiseOnChangeEvent = true) {
        this._results = [];
        this.value = address?.fullAddress;
        this._selectedValue = address;
        this.setInputValue(address?.fullAddress || '');
        this.selectedValueChange.emit(this.selectedValue);
        if (raiseOnChangeEvent) this.onChange(this.value);
    }

    private setInputValue(val: string | undefined | null) {
        this.renderer.setProperty(this.inputElementRef.nativeElement, 'value', val);
    }
}

export const BP_ADDRESS_SEARCH_ASYNC_VALIDATOR: Provider = {
    provide: NG_ASYNC_VALIDATORS,
    useExisting: forwardRef(() => AddressSearchFormControlDirective),
    multi: true
};

@Directive({
    selector: 'bp-address-search[formControlName],bp-address-search[formControl],bp-address-search[ngModel]',
    providers: [BP_ADDRESS_SEARCH_ASYNC_VALIDATOR]
})
export class AddressSearchFormControlDirective implements AsyncValidator, OnInit {
    private searchComponent: AddressSearchComponent;

    constructor(private injector: Injector) {}

    ngOnInit() {
        this.searchComponent = this.injector.get(AddressSearchComponent);
    }

    validate(control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
        if (control.dirty && control.value && !this.searchComponent.selectedValue) {
            return this.searchComponent.resultChanges$.pipe(
                take(1),
                map(({ status, messages, payload }) => {
                    if (status === 'ERROR') {
                        return { _internalError: messages };
                    }
                    return null;
                }),
                catchError(err => {
                    return of({
                        _internalError: ['We have encountered some error during the request. Please try again later.']
                    });
                })
            );
        }
        return of(null);
    }
    private onValidatorChange = () => {};
    registerOnValidatorChange?(fn: () => void) {
        this.onValidatorChange = fn;
    }
}
