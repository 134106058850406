import { FormControl, ValidationErrors } from '@angular/forms';
import { antiFluffValidator, eliminateFluff } from './anti-fluff-validator';

export const testability = {
    antiFluffValidator,
    eliminateFluff
};

const repetitionRegex = /(\d)\1{4,}|([a-zA-Z])\2{2,}/;
const consecutiveNumbersList = ['0123456789', '9876543210'];
const consecutiveLettersList = ['abcdefghijklmnopqrstuvwxyz', 'zyxwvutsrqponmlkjihgfedcba'];

export const policyNumberValidator = () => (control: FormControl): ValidationErrors | null => {
    const antiFluffResult = testability.antiFluffValidator()(control);
    if (antiFluffResult) return antiFluffResult;

    const stringValue = control.value + '';
    return stringValue.length < 4 ||
        repetitionRegex.test(stringValue) ||
        checkConsecutiveCharacters(stringValue) ||
        checkConsecutiveCharacters(testability.eliminateFluff(stringValue))
        ? { patternError: true }
        : null;
};

function checkConsecutiveCharacters(input: string): boolean {
    if (parseInt(input[0])) {
        return isConsecutive(input.toLowerCase(), consecutiveNumbersList);
    }
    return isConsecutive(input.toLowerCase(), consecutiveLettersList);
}

function isConsecutive(input: string, consecutiveCharsList: string[], count = 0): boolean {
    if (count > 1) return false;

    if (input.length <= 1) {
        return false;
    }

    const consecutiveChars = consecutiveCharsList[0];
    const consecutiveCharsReversed = consecutiveCharsList[1];
    const startIndex = consecutiveChars.indexOf(input[0]);
    const startIndexReversed = consecutiveCharsReversed.indexOf(input[0]);

    if (startIndex === -1) {
        return false;
    }

    for (let i = 1; i < input.length; i++) {
        const currentIndex = consecutiveChars.indexOf(input[i]);
        const currentIndexReversed = consecutiveCharsReversed.indexOf(input[i]);

        if (currentIndex !== startIndex + i && currentIndexReversed != startIndexReversed + i) {
            if (parseInt(consecutiveChars)) {
                return isConsecutive(input.substring(i, input.length), consecutiveLettersList, count + 1);
            } else {
                return isConsecutive(input.substring(i, input.length), consecutiveNumbersList, count + 1);
            }
        }
    }

    return true;
}
