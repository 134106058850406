export enum FFScope {
    BrokerPortal = 'BrokerPortal'
}

export const BrokerPortalFeatureCodes = {
    EnableFusionAuth: '2cb8b8784011507e43817976aa12cca5',
    EnableBrokerFlow: '1a2ebf79ab2340b09d02434b862b2098',
    HEUFAssetsForGreen: '5b4801f19911cde745193d812233a245',
    EnableBrokerPortalCobranding: '922fd2ba3b618db607dc9a4506eac375',
    EnableGettingContractDocuments: 'e4553cc6df038576fd7b3ff96caa0e08',
    EnablePLServicingCalculator: 'a72da199f2266507306055ee224cc985',
    EnableAutoServicingCalculator: '288b2063565f6ed32e5e6ebb071cde06',
    EnableCecSystemsOnDemandSearch: '1d4de9664307fe294fe68b7707b6ed80',
    RedirectToFusionAuthResetPassword: '5252236becc89cc134ff75dea2cc016e',
    DisableBrokerUpdateBrokerFeeWithDirectLinkApp: 'f4195b1aab4961b4ec585299fd8c6da6',
    EnableManualRateInput: 'ff934c579ff334e86a76668a7575b34e',
    BalloonPaymentValidation: '10238b3bb2cbe67da0588ad45aa69173',
    ResetSalesIqLiveChat: '02a5bebfe223190cab83c66cf7da4783',
    TradeInAndPayoutExposure: '3d3b6b13ed061073385009c79c58ef33'
};

export const getFeatureName = (featureCode: string) =>
    (Object.keys(BrokerPortalFeatureCodes) as (keyof typeof BrokerPortalFeatureCodes)[]).find(key => {
        return BrokerPortalFeatureCodes[key] === featureCode;
    });
