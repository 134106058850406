// See Venus EmploymentType enum
export enum EmploymentType {
    FullTime = 1,
    PartTime = 2,
    Contract = 3,
    SelfEmployed = 4,
    SoleTrader = 5,
    Student = 6,
    Houseperson = 7,
    Retired = 8,
    CurrentlyUnemployed = 9,
    Casual = 10
}
