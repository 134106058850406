import { FormControl } from '@angular/forms';

export function valueIdFoundValidator(
    dictionary: Object,
    validWhenNoEntities: boolean = false,
    validWhenFieldEmpty: boolean = false
) {
    return (control: FormControl): { [key: string]: any } => {
        if (validWhenFieldEmpty && !control.value) {
            return;
        }
        if (validWhenNoEntities && (!dictionary || Object.keys(dictionary).length === 0)) {
            return;
        }
        if (!dictionary[control.value]) {
            return { idNotFound: true };
        }
    };
}
