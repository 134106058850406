import { Component, Input } from '@angular/core';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';

@Component({
    selector: 'bp-dropdown-filter',
    template: `
        <kendo-dropdownlist
            [data]="data"
            (valueChange)="onChange($event)"
            [defaultItem]="defaultItem"
            [value]="selectedValue"
            [valuePrimitive]="true"
            [textField]="textField"
            [valueField]="valueField"
        >
        </kendo-dropdownlist>
    `
})
export class DropDownListFilterComponent extends BaseFilterCellComponent {
    public get selectedValue(): any {
        const filter = this.filterByField(this.field);
        return filter ? filter.value : null;
    }

    @Input() public filter: any;
    @Input() public field: string;
    @Input() public data: any[];
    @Input() public textField: string;
    @Input() public valueField: string;
    @Input() public defaultItem: any;

    constructor(filterService: FilterService, column: ColumnComponent) {
        super(filterService);
        this.field = column?.field;
    }

    public onChange(value: any): void {
        this.applyFilter(
            value === null // value of the default item
                ? this.removeFilter(this.field) // remove the filter
                : this.updateFilter({
                      // add a filter for the field with the value
                      field: this.field,
                      operator: 'eq',
                      value: value
                  })
        ); // update the root filter
    }
}
