import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerService {
    constructor(private httpClient: HttpClient) {}

    IsUserOptoutFeature(userGuid: string, feature: string): Observable<any> {
        return this.httpClient.get(`${environment.api.baseUrl}/customer/${userGuid}/feature/${feature}`).pipe(
            map((result: any) => {
                return result.UserHasOptoutFeature;
            })
        );
    }
}
