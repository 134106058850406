// C.f. ApiCheckResult in Venus
export enum BankVerificationStatus {
    NotChecked = 0,
    Passed = 1,
    Refer = 2,
    NotMatched = 3,
    PartialMatched = 4,
    Declined = 5,
    Error = 6,
    Expired = 7,
    NoScore = 8,
    IdentityMultipleMatches = 9,
    Reset = 10,
    SuggestPassed = 11,
    HoldCheck = 12,
    Pending = 13,
    Skipped = 14,
    SubmittedDocuments = 15
}
