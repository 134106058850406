import { Pipe, PipeTransform } from '@angular/core';
import { LoanConditionPredefinedApprovalConditions } from '../models/application';
import { CreditStatusService } from '../services';

@Pipe({ name: 'conditionDescription' })
export class ConditionDescriptionPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: string): string {
        if (value in LoanConditionPredefinedApprovalConditions) {
            return this.creditStatusService.getPredefinedApprovalConditionDescription(
                LoanConditionPredefinedApprovalConditions[value]
            );
        }

        // Most likely a custom loan condition, which is free text
        return value;
    }
}
