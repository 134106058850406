// C.f. ApplicationStatus.ApprovalStatus in CreditEngineService
export enum ApplicationApprovalStatus {
    Referred,
    Declined,
    ConditionallyApproved,
    Approved,
    PendingReview,
    Cancelled,
    Abandoned,
    Qualified,
    Expired
}
