<mat-sidenav-container class="admin-container">
  <mat-sidenav class="sidenav" [opened]="sidenavOpen" align="start" [mode]="sidenavMode" bpIconSidenav #sidenav>
    <bp-sidenav></bp-sidenav>
  </mat-sidenav>
  <div class="content-container" fxLayout="column">
    <bp-toolbar [sidenav]="sidenav" fxFlex></bp-toolbar>
    <div class="main-container" #scrollContainer>
      <router-outlet (activate)="onActivate($event, scrollContainer)"></router-outlet>
    </div>
  </div>
</mat-sidenav-container>
