export interface LoanDrawer {
    amount: number;
    name: string;
    reference: string;
    reason: string;
    accountName: string;
    accountNumber: string;
    bsb: string;
    billerCode: string;
    crn: string;
    channel: LoanDrawerChannel;
}

export enum LoanDrawerChannel {
    DirectCredit = 'DirectCredit',
    Bpay = 'Bpay',
    RTGS = 'RTGS'
}
