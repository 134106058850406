<div class="search-wrapper" fxLayout="row" [ngClass]="{ 'focus': focused, 'has-input': input }" fxFlex (msClickOutside)="closeDropdown()">
  <mat-icon class="search-icon">search</mat-icon>
  <input type="search" autocomplete="off" spellcheck="false" class="search-input" [(ngModel)]="input" (focus)="openDropdown()"
    placeholder="Search..." data-test-id="search-input" #searchInput>

  <div class="search-dropdown mat-elevation-z1">
    <div class="content results" *ngIf="input" fxLayout="column">
      <div class="heading" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="icon">search</mat-icon>
        Search results for: {{ input }}
      </div>
      <div *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div *ngIf="!isLoading" class="items">
        <div class="item" *ngFor="let item of searchResults" mat-ripple (click)="navigateApplication(item.guid)">
          <div class="name" [innerHTML]="getCustomerName(item) | searchBarHighlight: input"></div>
          <div class="broker" [innerHTML]="item.broker | searchBarHighlight: input"></div>
          <div class="amount">{{item.amount | currency }} @ {{item.term}} months</div>
          <div [ngClass]="['current-status']" [innerHTML]="item.currentStatusDescription"></div>
        </div>
      </div>
    </div>

    <div class="content recents" fxLayout="row" *ngIf="!input">
      <div class="recently" fxLayout="column" fxFlex>
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">restore</mat-icon>
          Recently Visited
        </div>
        <div class="items">
          <div class="item" mat-ripple *ngFor="let item of recentlyVisited" [routerLink]="item.route" (click)="closeDropdown()">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
