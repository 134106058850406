import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'residencyStatus' })
export class ResidencyStatusPipe implements PipeTransform {
    transform(status: string): any {
        switch (status) {
            case 'auNzCitizen':
                return 'AU/NZ Citizen';
            case 'auPermanentResident':
                return 'AU Permanent Resident';
            case 'onVisa':
                return 'On Visa';
            default:
                return status;
        }
    }
}
