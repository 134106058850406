import { Component, OnInit } from '@angular/core';
import { BrokerPortalFeatureCodes } from '@brokerportal/common/enums';
import { FeatureFlagService } from '@brokerportal/common/services';
import { CustomBroadcastChannel } from '@brokerportal/common/services/custom-broadcast-channel';
import { GLOBAL_CONSTANTS } from 'app/constants';

@Component({
    selector: 'bp-toolbar-user-button',
    templateUrl: './toolbar-user-button.component.html',
    styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit {
    isOpen: boolean;
    displayName: string;
    isEnabledResetSalesIqLiveChat: boolean;
    private $zoho = window['$zoho'] || {};

    constructor(private bc: CustomBroadcastChannel, private featureFlagService: FeatureFlagService) {}

    ngOnInit() {
        this.featureFlagService.isEnabled(BrokerPortalFeatureCodes.ResetSalesIqLiveChat).subscribe(enabled => {
            this.isEnabledResetSalesIqLiveChat = enabled;
        });
    }

    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }

    onClickOutside() {
        this.isOpen = false;
    }

    handleLogout() {
        if (this.isEnabledResetSalesIqLiveChat) {
            this.resetChatBox();
        }

        this.bc.postMessage(GLOBAL_CONSTANTS.CHANNEL.USER.LOGOUT);
    }

    private resetChatBox() {
        if (this.$zoho) {
            this.$zoho.salesiq?.reset();
        }
    }
}
