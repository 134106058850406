import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { of, timer } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { VenusDemandMapping } from './demands.const';
import { CookieService } from 'ngx-cookie-service';
import { PortalConfigurationService } from '../services';
import { IdentityService } from '../services/identity.service';
import { rootDomain } from 'environments/settings';
import { BrokerPortalFeatureCodes } from '@brokerportal/common/enums/feature-flag';
import { FeatureFlagService } from '@brokerportal/common/services';

@Injectable()
export class AuthenticationService {
    private impersonationTokenName = 'rs-impersonation_auth_token';
    private fusionAuthIndicatorName = 'rs_broker_fusion_auth_indicator';
    private userDemands: string[] = [];

    constructor(
        private httpClient: HttpClient,
        private oAuthService: OAuthService,
        private oAuthStorage: OAuthStorage,
        private cookieService: CookieService,
        private portalConfigurationService: PortalConfigurationService,
        private identityService: IdentityService,
        private featureFlagService: FeatureFlagService
    ) {
        this.oAuthService.configure({
            tokenEndpoint: `${environment.api.baseUrl}/${environment.authentication.token}`,
            clientId: environment.authentication.key,
            scope: 'password'
        });

        // DEVNOTE: If impersonation information is present in local storage, manually set the session storage
        // variables used by the angular-oauth2-oidc library. What we are doing here is side-loading values
        // into session state that would normally be set when the user successfully logs in
        // Refer to Venus UserImpersonation.cshtml for the code that sets this value
        if (localStorage) {
            const impersonationData = localStorage.getItem(this.impersonationTokenName);
            if (impersonationData) {
                this.processImpersonationToken(JSON.parse(impersonationData));
            }
        }

        const tokenAsCookie = this.cookieService.get(this.impersonationTokenName);
        if (tokenAsCookie) {
            this.processImpersonationToken(JSON.parse(tokenAsCookie));
        }
    }

    private processImpersonationToken(impersonationToken: any) {
        this.portalConfigurationService.clear();
        this.oAuthStorage.removeItem('access_token');
        this.oAuthStorage.removeItem('refresh_token');
        this.oAuthStorage.removeItem('access_token_stored_at');
        this.oAuthStorage.setItem('access_token', impersonationToken.access_token);
        this.oAuthStorage.setItem('refresh_token', impersonationToken.refresh_token);
        this.oAuthStorage.setItem('access_token_stored_at', Date.now().toString());

        localStorage.removeItem(this.impersonationTokenName);
        this.cookieService.delete(this.impersonationTokenName);

        this.featureFlagService.isEnabled(BrokerPortalFeatureCodes.EnableFusionAuth).subscribe(enabled => {
            if (enabled) {
                this.cookieService.delete(this.impersonationTokenName, '/', `.${rootDomain}`, true, 'Lax');
            }
        });
    }

    async hasDemand(venusDemand: VenusDemandMapping): Promise<boolean> {
        const userDemands = ((await this.getDemands()) as string[]) || null;
        if (!userDemands) {
            return false;
        }

        for (let userDemand of userDemands) {
            if (this.demandMatches(userDemand, venusDemand)) {
                return true;
            }
        }
        return false;
    }

    private async getDemands(): Promise<any[]> {
        if (!(await this.isAuthenticated().toPromise())) {
            return null;
        }

        if (this.isUserLoginAsFusionAuth()) {
            if (this.userDemands.length > 0) return this.userDemands;

            this.userDemands = await this.identityService.getDemand(environment.fusionAuth.clientId).toPromise();
            return this.userDemands;
        }

        // DEVNOTE: `this.oAuthService.getIdentityClaims() as any;` returns NULL here, hence
        // the process below to decode the JWT directly to evaluate what Demand[] are present
        const decodedToken = JSON.parse(atob(this.oAuthService.getAccessToken().split('.')[1]));
        return decodedToken.Demand;
    }

    public isUserLoginAsFusionAuth() {
        return this.cookieService.get(this.fusionAuthIndicatorName) === 'Valid';
    }

    private demandMatches(userDemand: string, venusDemand: string): boolean {
        if (this.isUserLoginAsFusionAuth()) {
            return venusDemand.startsWith(userDemand);
        }

        const splitUserDemand = userDemand.split('_');
        const splitVenusDemand = venusDemand.split('_');

        if (splitUserDemand.length != splitVenusDemand.length) return false;

        const isDemandMatching = splitUserDemand.reduce((isMatching, currUserSegment, idx) => {
            if (!isMatching) return isMatching;

            if (splitVenusDemand[idx] === '*' || currUserSegment === splitVenusDemand[idx]) {
                return true;
            }

            return false;
        }, true);

        return isDemandMatching;
    }

    isAuthenticated() {
        if (this.isUserLoginAsFusionAuth()) {
            return this.validateToken();
        }

        return of(this.oAuthService.hasValidAccessToken());
    }

    async refreshToken(): Promise<boolean> {
        if (this.isUserLoginAsFusionAuth()) {
            var refreshToken = await this.identityService.refreshToken(environment.fusionAuth.clientId).toPromise();
            return refreshToken === 'TokenRefreshed';
        }

        const result = await this.oAuthService.refreshToken();
        if (result && result.access_token) {
            return true;
        }
        return false;
    }

    async login(username: string, password: string): Promise<boolean> {
        const result = await this.oAuthService.fetchTokenUsingPasswordFlow(
            encodeURIComponent(username),
            encodeURIComponent(password)
        );
        if (result && result.access_token) {
            return true;
        }
        return false;
    }

    logout(): void {
        // DEVNOTE: remove any local storage based token that may be present for impersonation
        if (localStorage) {
            localStorage.removeItem(this.impersonationTokenName);
        }
        if (this.isUserLoginAsFusionAuth()) {
            this.cookieService.delete(this.impersonationTokenName, '/', `.${rootDomain}`, true, 'Lax');
        }
        return this.oAuthService.logOut();
    }

    fetchToken(): string {
        return this.oAuthService.getAccessToken();
    }

    revokeRefreshToken() {
        return this.httpClient.post(`${environment.api.baseUrl}/portal/authentication/oauth2/revoke`, {
            Token: this.oAuthStorage.getItem('refresh_token')
        });
    }

    validateToken() {
        return this.httpClient.get(`${environment.api.baseUrl}/portal/authentication/oauth2/token/validate`).pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }

    resetPassword(username: string, oldPassword: string, newPassword: string) {
        return this.httpClient.post(`${environment.api.baseUrl}/portal/authentication/resetpassword`, {
            access_token: this.fetchToken(),
            client_id: environment.authentication.key,
            username: username,
            password: oldPassword,
            new_password: newPassword
        });
    }

    refreshTokenPeriodic() {
        return timer(0, environment.constants.settings.tokenRefreshIntervalSeconds * 1000).pipe(
            switchMap(() => this.refreshToken())
        );
    }
}
