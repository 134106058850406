// C.f. CreditEvaluation.ApprovalStatus in CreditEngineService
export enum CreditEvaluationApprovalStatus {
    NotEvaluated,
    NotEvaluatable,
    Refer,
    Failed,
    Passed,
    ConditionallyPassed,
    Qualified
}
