import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';

export function conditionallyRequired(conditionalFieldNames: string[], expectedValues: any[]) {
    let conditionalFieldControls: AbstractControl[];

    return (control: FormControl): ValidationErrors | null => {
        if (!control.parent) {
            return null;
        }
        if (!conditionalFieldControls) {
            conditionalFieldControls = conditionalFieldNames.map(name => control.parent.get(name));
            conditionalFieldControls.forEach(conditionalControl =>
                conditionalControl.valueChanges.subscribe(() => control.updateValueAndValidity())
            );
        }

        let conditionsMet = true;
        conditionalFieldControls.forEach((conditionalControl, i) => {
            let valueNotMet = Array.isArray(expectedValues[i])
                ? !expectedValues[i].includes(conditionalControl.value)
                : conditionalControl.value !== expectedValues[i];

            if (valueNotMet) {
                conditionsMet = false;
            }
        });

        return conditionsMet ? Validators.required(control) : null;
    };
}
