import { Component, Input } from '@angular/core';
import { InstallationType, QuoteFormModel } from '../../shared/models';

@Component({
    selector: 'nsw-document-requirements',
    templateUrl: './nsw-document-requirements.component.html'
})
export class NswDocumentRequirementsComponent {
    @Input()
    quoteFormModel: QuoteFormModel;

    constructor() {}

    isBatteryAndSolarSystem() {
        return (
            this.quoteFormModel.installationType.includes(InstallationType.Battery) &&
            this.quoteFormModel.installationType.includes(InstallationType.Solar)
        );
    }
}
