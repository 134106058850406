import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { ToLuxonDatePipe } from './toLuxonDate.pipe';

@Pipe({ name: 'readableTimeElapsedSinceDatePipe' })
export class ReadableTimeElapsedSinceDatePipe implements PipeTransform {
    transform(value: Date): string {
        const timestamp = new ToLuxonDatePipe().transform(value);
        const utcNow = DateTime.utc();
        const differenceObject = utcNow.diff(timestamp, ['days', 'hours', 'minutes', 'seconds']);

        const unit = differenceObject.days || differenceObject.hours || differenceObject.minutes;
        if (!unit) {
            return 'just a moment ago';
        }

        let unitName = differenceObject.days
            ? 'day'
            : differenceObject.hours
            ? 'hour'
            : differenceObject.minutes
            ? 'minute'
            : null;

        if (unit >= 2) {
            unitName = unitName + 's';
        }

        return `${Math.floor(unit)} ${unitName} ago`;
    }
}
