import { Pipe, PipeTransform } from '@angular/core';
import { ToLuxonDatePipe } from '@brokerportal/common/pipes/toLuxonDate.pipe';

@Pipe({ name: 'convertTimezone' })
export class ConvertTimezonePipe implements PipeTransform {
    transform(value: string | Date | null): string | null {
        if (!value) {
            return value as null;
        }

        const transformedValue = new ToLuxonDatePipe().transform(value);

        return transformedValue.toLocal().toString();
    }

    transformWithFormat(value: string, format: string): string {
        if (!value) {
            return value;
        }

        const transformedValue = new ToLuxonDatePipe().transform(value);

        return transformedValue
            .toLocal()
            .toFormat(format)
            .toString();
    }
}
