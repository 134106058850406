import { HomeOwnershipOrInterest, InstallationType, AdditionalInstallationType } from './quote.model';

export const homeOwnershipOrInterestLabels = {
    [HomeOwnershipOrInterest.Direct]: 'Direct owner (including joint or tenant-in-common owner)',
    [HomeOwnershipOrInterest.Indirect]: 'Indirect ownership interest (including via spouse, family trust, company)',
    [HomeOwnershipOrInterest.NoOwnership]: 'No ownership or ownership interest (including renters and boarders)'
};

export const installationTypeLabels = {
    [InstallationType.Battery]: 'Battery',
    [InstallationType.Solar]: 'Solar panel'
};

export const batteryInstallationTypeLabels = {
    [AdditionalInstallationType.Extension]: 'Extension of existing battery system',
    [AdditionalInstallationType.Replacement]: 'Replacement of existing battery system'
};

export const panelInstallationTypeLabels = {
    [AdditionalInstallationType.Extension]: 'Extension of existing solar system',
    [AdditionalInstallationType.Replacement]: 'Replacement of existing solar system'
};
