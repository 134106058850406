import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalConfigurationService } from '@brokerportal/common/services/portal-configuration.service';
import { AuthenticationService } from '@brokerportal/common/auth/authentication.service';
import { UserConfiguration } from '@brokerportal/common/models/user-configuration.model';
import { environment } from 'environments/environment';
import { NgRadio } from 'ng-radio';
import { GLOBAL_CONSTANTS } from 'app/constants';

@Component({
    selector: 'bp-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    isLoading: boolean;
    returnUrl: string;
    form: FormGroup;

    env: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private portalConfigurationService: PortalConfigurationService,
        private snackbar: MatSnackBar,
        private formBuilder: FormBuilder,
        private radio: NgRadio,
        private ngZone: NgZone
    ) {
        this.env = environment;
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';

        this.form = this.formBuilder.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required, Validators.minLength(8)]]
        });
    }

    onSubmit() {
        this.form.updateValueAndValidity();
        if (this.form.valid) {
            this.isLoading = true;
            const userName = this.form.get('email').value.trim();
            const password = this.form.get('password').value.trim();

            this.authenticationService
                .login(userName, password)
                .then(result => {
                    if (!result) {
                        this.snackbar.open('Sorry, there was a problem logging in. Please try again.', null, {
                            duration: 5000
                        });
                        return;
                    }
                    this.portalConfigurationService.init().subscribe(
                        ps => {
                            this.snackbar.dismiss();
                            this.radio.cast(GLOBAL_CONSTANTS.CHANNEL.USER.LOGIN);
                            this.ngZone
                                .run(() => this.router.navigateByUrl(this.returnUrl))
                                .then(nav => {
                                    this.initAppCues(ps.user);
                                    this.isLoading = false;
                                });
                        },
                        pe => {
                            this.isLoading = false;
                            this.snackbar.open(
                                'Sorry, there was an error loading your profile. Please try logging in again.',
                                null,
                                {
                                    duration: 5000
                                }
                            );
                        }
                    );
                })
                .catch(err => {
                    this.isLoading = false;
                    this.snackbar.open('Sorry, there was a problem logging in. Please try again.', null, {
                        duration: 5000
                    });
                });
        }
    }

    private initAppCues(user: UserConfiguration) {
        if (!window['Appcues'] || !user) {
            return;
        }
        window['Appcues'].identify(user.id, {
            name: user.displayName,
            email: user.userName
        });
    }
}
