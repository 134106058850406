import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AddressModel } from '../../models';
import { AddressValidationService } from '../../services';
import { dependentValidator } from '../../validators';

declare var Harmony: any;

@Component({
    selector: 'harmony-address',
    templateUrl: './harmony-address.component.html',
    styleUrls: ['../quote-form/quote-form.component.scss', './harmony-address.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HarmonyAddressComponent implements OnInit {
    @Input()
    parentFormGroup: FormGroup;
    @Input()
    fieldName: string;
    @Input()
    submissionErrors: Observable<void>;
    @Input()
    allowedStates: string[];

    @Input()
    disabled: boolean;

    addressGroup: FormGroup;
    searchAddressGroup: FormGroup;
    suggestions: any[];
    selectedIndex: number;
    isLoading = false;
    harmonySearchError = false;
    manualPanelExpanded = false;

    states = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

    postCodeRegex = /^[0-9]{4}$/;

    constructor(private addressValidation: AddressValidationService) {}

    ngOnInit() {
        this.addressGroup = <FormGroup>this.parentFormGroup.get(this.fieldName);
        const existingAddressModel: AddressModel = {
            unitNumber: this.addressGroup.get('unitNumber').value,
            streetNumber: this.addressGroup.get('streetNumber').value,
            street: this.addressGroup.get('street').value,
            suburb: this.addressGroup.get('suburb').value,
            postCode: this.addressGroup.get('postCode').value,
            state: this.addressGroup.get('state').value
        };
        const searchAddress = this.mapFieldValuesToFullAddress(existingAddressModel);

        this.searchAddressGroup = new FormGroup({
            searchAddress: new FormControl(searchAddress, [
                dependentValidator([{ name: 'state', control: this.field('state') }])
            ])
        });
        this.suggestions = [];

        this.searchAddressGroup.valueChanges.pipe(debounceTime(400)).subscribe(event => {
            if (this.field('searchAddress').touched || !this.field('searchAddress').pristine) {
                this.search(event.searchAddress);
            }
        });
        this.submissionErrors.subscribe(() => {
            this.manualPanelExpanded = this.manualPanelExpanded || this.addressGroup.invalid;
        });
        this.disableSearchField(this.disabled);
    }

    selectAddress(addressIndex) {
        const selectedAddress = this.suggestions[addressIndex];
        if (!selectedAddress) {
            return;
        }
        this.mapFieldValues(this.suggestions[addressIndex]);
        this.suggestions = [];
    }

    silentlyUpdateAddress(newAddress?: string) {
        const opts = { emitEvent: false };
        if (newAddress !== undefined) {
            this.field('searchAddress').setValue(newAddress, opts);
        } else {
            this.field('searchAddress').updateValueAndValidity(opts);
        }
    }

    onUpdateManualField() {
        if (this.addressGroup.valid) {
            this.silentlyUpdateAddress(this.field('searchAddress').value);
        }
    }

    mapFieldValues(address) {
        // If no street number, but a subdwelling (e.g. a Lot number), use the subdwelling
        // in place of the street number
        let unitNumber = '';
        if (!address.streetNumber && address.lotNumber) {
            this.field('streetNumber').setValue(address.subdwelling);
        } else {
            this.field('streetNumber').setValue(address.streetNumber);
            unitNumber = address.subdwelling;
        }
        unitNumber += address.buildingName;
        this.field('unitNumber').setValue(unitNumber);

        this.field('street').setValue(address.street);
        this.field('suburb').setValue(address.locality);
        if (this.postCodeRegex.test(address.postcode)) {
            this.field('postCode').setValue(address.postcode);
        }
        this.field('state').setValue(address.state);

        this.field('searchAddress').updateValueAndValidity({
            emitEvent: false
        });

        this.setManualAddressTouched();
        this.manualPanelExpanded = this.addressGroup.invalid;

        this.silentlyUpdateAddress(address.fullAddress);
    }

    mapFieldValuesToFullAddress(model: AddressModel): string {
        let fullAddress = '';
        const delimitedStreet = model.street ? `${model.street},` : model.street;
        const order = [
            model.unitNumber,
            model.streetNumber,
            delimitedStreet,
            model.suburb,
            model.state,
            model.postCode
        ];
        order.forEach(part => (fullAddress += part ? `${part} ` : ''));
        fullAddress = fullAddress.length > 0 ? fullAddress.slice(0, fullAddress.length - 1) : fullAddress;
        return fullAddress;
    }

    checkKeyDown(event) {
        const key = event.key || event.keyCode;

        if (key === 'ArrowDown' || key === 40) {
            event.preventDefault();
            if (this.selectedIndex + 1 !== this.suggestions.length) {
                this.selectedIndex++;
            }
        } else if (key === 'ArrowUp' || key === 38) {
            event.preventDefault();
            if (this.selectedIndex - 1 !== -1) {
                this.selectedIndex--;
            }
        } else if (key === 'Enter' || key === 13) {
            event.preventDefault();
            this.selectAddress(this.selectedIndex);
        } else if (key === 'Backspace' || key === 8) {
            this.silentlyUpdateAddress('');
            this.suggestions = [];
        }
    }

    search(searchAddress) {
        this.suggestions = [];
        this.isLoading = true;

        Harmony.address({ fullAddress: searchAddress }, 'AUPAF', harmonyResponse => {
            if (!harmonyResponse || !harmonyResponse.payload || !harmonyResponse.payload.length) {
                this.harmonySearchError = true;
                this.isLoading = false;
                this.silentlyUpdateAddress(searchAddress);
                return;
            }
            this.harmonySearchError = false;

            let maxResultsToShow = harmonyResponse.payload.length;
            maxResultsToShow = maxResultsToShow < 10 ? maxResultsToShow : 10;

            for (let i = 0; i < maxResultsToShow; i++) {
                this.suggestions.push(harmonyResponse.payload[i]);
            }

            this.isLoading = false;
        });

        this.selectedIndex = -1;
    }

    getStateList() {
        return this.addressValidation.getReadableStateList(this.allowedStates);
    }

    setManualAddressTouched() {
        for (const controlName of Object.keys(this.addressGroup.controls)) {
            this.addressGroup.get(controlName).markAsTouched();
        }
        return false;
    }

    field(childFieldName: string): AbstractControl {
        return this.addressGroup.get(childFieldName) || this.searchAddressGroup.get(childFieldName);
    }

    private disableSearchField(disabled: boolean) {
        if (disabled) {
            this.field('searchAddress').disable();
        } else {
            this.field('searchAddress').enable();
        }
    }
}
