import { Component, Input } from '@angular/core';

@Component({
    selector: 'bp-card-loading-spinner-overlay',
    templateUrl: './card-loading-spinner-overlay.component.html',
    styleUrls: ['./card-loading-spinner-overlay.component.scss']
})
export class CardLoadingSpinnerOverlayComponent {
    @Input()
    isLoading = false;
}
