import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SearchBarHighlightPipe } from './search-bar/search-bar-highlight.pipe';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchBarService } from './search-bar/search-bar.service';
import { SearchComponent } from './search/search.component';
import { ToolbarUserButtonComponent } from './toolbar-user-button/toolbar-user-button.component';
import { ToolbarComponent } from './toolbar.component';
import { MaterialComponentsModule } from 'app/material-components.module';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, MaterialComponentsModule, FlexLayoutModule, FormsModule, RouterModule],
    entryComponents: [],
    declarations: [
        SearchComponent,
        SearchBarComponent,
        ToolbarUserButtonComponent,
        ToolbarComponent,
        SearchBarHighlightPipe
    ],
    exports: [SearchComponent, SearchBarComponent, ToolbarUserButtonComponent, ToolbarComponent],
    providers: [SearchBarService]
})
export class ToolbarUiModule {}
