import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';

export function rateSetterOAuthStorageFactory(): OAuthStorage {
    return new RateSetterCustomOAuthStorage();
}

@Injectable()
export class RateSetterCustomOAuthStorage {
    private prefix = 'rs';

    public getItem(key: string): string | null {
        return sessionStorage.getItem(`${this.prefix}-${key}`);
    }
    public removeItem(key: string): void {
        return sessionStorage.removeItem(`${this.prefix}-${key}`);
    }
    public setItem(key: string, data: string): void {
        return sessionStorage.setItem(`${this.prefix}-${key}`, data);
    }
}
