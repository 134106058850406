import { Pipe, PipeTransform } from '@angular/core';
import { OwnCurrentHome } from '../enums/own-current-home.enum';
import { CreditStatusService } from '../services/credit-status.service';

@Pipe({ name: 'homeOwnershipDescription' })
export class HomeOwnershipDescriptionPipe implements PipeTransform {
    constructor(private creditStatusService: CreditStatusService) {}

    transform(value: OwnCurrentHome): string {
        // Value is expected to be string version of OwnCurrentHome, e.g. 'OwnHomeWithMortgage'
        return this.creditStatusService.getHomeOwnershipDescription(value);
    }
}
