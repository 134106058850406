import { FormControl, AbstractControl } from '@angular/forms';

export function conditionallyRequiredTrue(conditionalFieldName: string, conditionalValue: any) {
    let savedControl: FormControl;
    let conditionalFieldControl: AbstractControl;

    return (control: FormControl): { [key: string]: any } => {
        savedControl = control;
        if (!control.parent) {
            return;
        }
        if (!conditionalFieldControl) {
            conditionalFieldControl = control.parent.get(conditionalFieldName);
            conditionalFieldControl.valueChanges.subscribe(() => {
                savedControl.updateValueAndValidity();
            });
        }

        if (conditionalFieldControl.value === conditionalValue && !control.value) {
            return { required: true };
        }
    };
}
