import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UploadModule } from '@progress/kendo-angular-upload';
import { NgRadio } from 'ng-radio';
import { MaterialComponentsModule } from '../material-components.module';
import { SharedUiModule } from './shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        MaterialComponentsModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        SharedUiModule,
        UploadModule
    ],
    entryComponents: [],
    declarations: [],
    providers: [NgRadio]
})
export class UiModule {}
