<div class="editable-label" [formGroup]="form">
  <div *ngIf="edittingInfo;then infoInput else infoLabel"></div>    
  <ng-template #infoLabel>{{ form.get(name).value || '' }}</ng-template>
  <ng-template #infoInput>
      <mat-form-field style="width: 90%;">
          <input
              matInput
              [placeholder]="placeholder"
              type="text"
              [formControlName]="name"
              [readonly]="isLoading"
          />
      </mat-form-field>
  </ng-template>
  <div fxFlex></div>
  <mat-icon (click)="toggleEdit()" class="edit-icon">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.92424 7.78287C9.1195 7.58761 9.1195 7.27103 8.92424 7.07576C8.72898 6.8805 8.41239 6.8805 8.21713 7.07576L8.92424 7.78287ZM4.18179 11.1111C3.98653 11.3064 3.98653 11.623 4.18179 11.8182C4.37705 12.0135 4.69363 12.0135 4.8889 11.8182L4.18179 11.1111ZM0.5 10.7579L0 10.7579V10.7579H0.5ZM0.5 15.2579H1H0.5ZM15.2636 4.43743L14.9101 4.79098L15.2636 4.43743ZM11.5626 0.736385L11.9161 0.382831L11.5626 0.736385ZM5.41333 15.4291L5.05977 15.0755L5.41333 15.4291ZM15.2636 5.5788L15.6172 5.93235V5.93235L15.2636 5.5788ZM0.570915 10.5867L0.217362 10.2331H0.217362L0.570915 10.5867ZM10.4212 0.736385L10.0677 0.382832V0.382832L10.4212 0.736385ZM8.21713 7.07576L4.18179 11.1111L4.8889 11.8182L8.92424 7.78287L8.21713 7.07576ZM0 10.7579L1.19209e-07 15.2579H1L1 10.7579H0ZM5.24212 15H0.742121V16H5.24212V15ZM15.6172 4.08387L11.9161 0.382831L11.209 1.08994L14.9101 4.79098L15.6172 4.08387ZM5.76688 15.7826L13.7666 7.78287L13.0595 7.07576L5.05977 15.0755L5.76688 15.7826ZM13.7666 7.78287L15.6172 5.93235L14.9101 5.22524L13.0595 7.07576L13.7666 7.78287ZM0.924469 10.9402L8.92424 2.94046L8.21713 2.23335L0.217362 10.2331L0.924469 10.9402ZM8.92424 2.94046L10.7748 1.08994L10.0677 0.382832L8.21713 2.23335L8.92424 2.94046ZM13.7666 7.07576L8.92424 2.23335L8.21713 2.94046L13.0595 7.78287L13.7666 7.07576ZM1.19209e-07 15.2579C1.19209e-07 15.6677 0.332257 16 0.742121 16V15C0.884546 15 1 15.1155 1 15.2579H1.19209e-07ZM1 10.7579C1 10.8263 0.972832 10.8919 0.924469 10.9402L0.217362 10.2331C0.0781855 10.3723 2.68221e-07 10.5611 0 10.7579L1 10.7579ZM14.9101 4.79098C15.03 4.9109 15.03 5.10533 14.9101 5.22524L15.6172 5.93235C16.1276 5.42191 16.1276 4.59432 15.6172 4.08387L14.9101 4.79098ZM5.24212 16C5.43895 16 5.62771 15.9218 5.76688 15.7826L5.05977 15.0755C5.10813 15.0272 5.17372 15 5.24212 15V16ZM11.9161 0.382831C11.4057 -0.127611 10.5781 -0.12761 10.0677 0.382832L10.7748 1.08994C10.8947 0.970021 11.0891 0.970021 11.209 1.08994L11.9161 0.382831Z" fill="#FF2C52"/>
      </svg>
  </mat-icon>
</div>