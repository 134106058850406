import { Injectable } from '@angular/core';
import { ApplicationDetailsSupportingDocumentationModel } from '../models';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DocumentInformationService {
    private documents: Array<ApplicationDetailsSupportingDocumentationModel>;
    private documentsSubject = new BehaviorSubject<Array<ApplicationDetailsSupportingDocumentationModel>>([]);

    setDocumentInformations(documents: Array<ApplicationDetailsSupportingDocumentationModel>) {
        this.documents = documents;
        this.documentsSubject.next(documents);
    }

    getDocumentsSubject() {
        return this.documentsSubject.asObservable();
    }

    getSubmittedDocumentdDate(documentGuid: string) {
        return this.documents?.find(doc => doc.guid === documentGuid)?.dateSubmitted;
    }

    isDownloadableDocument(documentDateSubmitted: string) {
        const dateSubmitted = DateTime.fromJSDate(new Date(documentDateSubmitted));
        const diff = DateTime.local().diff(dateSubmitted, ['days']);
        return diff.days <= 30;
    }
}
