import { Injectable } from '@angular/core';
import { BroadcastChannelEvent } from '../models/custom-broadcast-message.model';

// used for firing event accross tabs with same origin
@Injectable()
export class CustomBroadcastChannel {
    private messagePrefixKey = 'rs_event_';
    private eventHandlers = {};

    handleMessageEvent(storageEvent: StorageEvent) {
        if (!storageEvent.newValue) return; // ignore message reset event
        if (this.eventHandlers[storageEvent.key]) {
            this.eventHandlers[storageEvent.key](JSON.parse(storageEvent.newValue));
        }
    }

    postMessage(channel: string, message?: any) {
        var key = this.getMessageChannel(channel);
        var eventData = new BroadcastChannelEvent(key, message);
        // use local storage for messaging. Set message in local storage and clear it right away
        // this is a safe way how to communicate with other tabs while not leaving any traces
        localStorage.setItem(key, JSON.stringify(eventData));
        localStorage.removeItem(key);
        // simulate localstorage change event on the same tab
        window.dispatchEvent(
            new StorageEvent('storage', {
                key,
                newValue: JSON.stringify(eventData)
            })
        );
    }

    subscribeEvent(channel: string, handleEventCb: Function) {
        this.eventHandlers[this.getMessageChannel(channel)] = handleEventCb;
        window.addEventListener('storage', this.handleMessageEvent.bind(this));
    }

    unsubscribeEvent(channel: string) {
        delete this.eventHandlers[this.getMessageChannel(channel)];
    }

    unsubscribeAllEvent() {
        window.removeEventListener('storage', this.handleMessageEvent);
    }

    getMessageChannel(channel: string) {
        return this.messagePrefixKey + channel;
    }
}
