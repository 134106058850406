<div [ngClass]="{'message': true, 'error-message': type === 'error', 'success-message': type === 'success', 'warning-message': type === 'warning'}">
  <div fxFlex="calc(5%)" [ngSwitch]="true">
    <mat-icon *ngSwitchCase="type === 'error'">error_outline</mat-icon>
    <mat-icon *ngSwitchCase="type === 'warning'">warning</mat-icon>
    <mat-icon *ngSwitchCase="type === 'success'">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.663 2.83851C12.8902 2.07213 10.9096 1.88227 9.01657 2.29725C7.12351 2.71223 5.41942 3.70981 4.15845 5.14122C2.89749 6.57262 2.14721 8.36116 2.01951 10.2401C1.89181 12.119 2.39354 13.9876 3.44987 15.5673C4.50619 17.147 6.06051 18.353 7.88102 19.0056C9.70153 19.6581 11.6907 19.7222 13.5518 19.1883C15.413 18.6544 17.0463 17.5511 18.2084 16.043C19.3704 14.5348 19.9989 12.7026 20 10.8196V9.92756C20 9.39172 20.4477 8.95733 21 8.95733C21.5523 8.95733 22 9.39172 22 9.92756V10.8202C21.9986 13.1216 21.2305 15.3615 19.8103 17.2048C18.39 19.0481 16.3936 20.3966 14.1189 21.0492C11.8442 21.7017 9.41298 21.6234 7.18792 20.8258C4.96285 20.0282 3.06312 18.5541 1.77206 16.6235C0.480993 14.6928 -0.13223 12.4089 0.0238445 10.1124C0.179919 7.81595 1.09693 5.62996 2.63811 3.88046C4.17929 2.13096 6.26206 0.911692 8.57581 0.404495C10.8896 -0.102702 13.3103 0.129348 15.477 1.06604C15.9815 1.28412 16.2082 1.8577 15.9834 2.34716C15.7587 2.83661 15.1675 3.05659 14.663 2.83851Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.7068 2.37197C22.0975 2.75067 22.0978 3.36499 21.7075 3.74408L11.7075 13.4561C11.52 13.6382 11.2656 13.7405 11.0002 13.7406C10.7349 13.7407 10.4805 13.6384 10.2929 13.4564L7.29289 10.5457C6.90237 10.1668 6.90237 9.55252 7.29289 9.17362C7.68342 8.79472 8.31658 8.79472 8.70711 9.17362L10.9996 11.3979L20.2925 2.37265C20.6829 1.99356 21.316 1.99326 21.7068 2.37197Z" fill="white"/>
      </svg>
    </mat-icon>
  </div>
  <div fxFlex="calc(95%)">
      <p>{{ message }}</p>
  </div>
</div>