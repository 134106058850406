import { ReportApplicationNextStep } from '@brokerportal/modules/application/reports/shared/next-step.enum';
import { StepName } from '../enums';

export const getCurrentStatusDescription = (dataItem: any): string => {
    const castWorkflowStepName = StepName[<keyof typeof StepName>dataItem.workflowStep];
    const castNextStep = ReportApplicationNextStep[<keyof typeof ReportApplicationNextStep>dataItem.nextStep];
    const isSettlementsV2App = dataItem.actionRequiredCondition && dataItem.actionRequiredCondition !== 'Undefined';
    const isRenewableInterestFree = dataItem.product && dataItem.product.toLowerCase() === 'renewableinterestfree';
    const isIdentityStatusPass = dataItem.userIdentityCheckStatus === 'Pass';

    const remindableWorkflowSteps = [
        StepName.AccountCreation,
        StepName.AcceptTerms,
        StepName.DirectDebitDetails,
        StepName.PreContractualDocumentGeneration,
        StepName.FinalLoanContractSigningAgain,
        StepName.FinalLoanContract
    ];

    if (castNextStep !== ReportApplicationNextStep.VerifyIdentity) {
        remindableWorkflowSteps.push(StepName.ConditionallyApprovedApplicationReceived);
    }

    if (isRenewableInterestFree && isSettlementsV2App && remindableWorkflowSteps.includes(castWorkflowStepName)) {
        return 'Awaiting contract signing' + (isIdentityStatusPass ? '' : ' and ID verification');
    }

    return dataItem.currentStatusDescription;
};
