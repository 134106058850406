// C.f. LoanRequestCreditStatus in Venus
export enum CreditStatus {
    NoStatus = 0,
    Declined = 1,
    Approved = 2,
    Created = 3,
    Quoted = 4,
    Applied = 5,
    Cancel = 6,
    Expired = 7,
    Fraud = 8,
    Reinstated = 9,
    Incomplete = 10,
    Cancel_NoReinstate = 11,
    Credit_Expired = 12,
    Credit_Withdrawn = 13,
    Pending_Processing = 14,
    COMPLETE = 15,
    Credit_Reinstate = 16,
    Holding = 17,
    ConditionallyApproved = 18,
    Accepted = 19,
    Abandoned = 20,
    ApprovedPendingReview = 21
}
