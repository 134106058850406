export enum RenewableSystemType {
    /** @deprecated */
    BatteryOnly = 'Battery only',
    /** @deprecated */
    SolarOnly = 'Solar panels only',
    /** @deprecated */
    SolarAndBattery = 'Solar panels & battery',
    Solar = 'Solar',
    Battery = 'Battery',
    HeatPump = 'HeatPump',
    EvCharger = 'EvCharger',
    SolarHotWater = 'SolarHotWater',
    Aircon = 'Aircon',
    SmartMeter = 'SmartMeter',
    PoolPump = 'PoolPump',
    EnergyMonitoringSystem = 'EnergyMonitoringSystem',
    InductionCooktop = 'InductionCooktop',
    Insulation = 'Insulation'
}
