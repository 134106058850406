import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lowerCase, isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { PackageCategory, PackageWithCategoryModel, PackageModel, PackageRequestScope, Scheme } from '../../models';
import { QuoteService, ErrorCallbackGeneratorService } from '../../services';
import { valueIdFoundValidator, copyValidationValidator } from '../../validators';

@Component({
    selector: 'package-select',
    templateUrl: './package-select.component.html',
    styleUrls: ['./package-select.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PackageSelectComponent implements OnInit, OnChanges {
    // tslint:disable-next-line:no-input-rename
    @Input('control')
    controlFromParent: AbstractControl;

    @Input()
    category: PackageCategory;

    @Output()
    onPackageSelected = new EventEmitter<PackageWithCategoryModel>();

    @Input()
    scoped: boolean = false;

    @Input()
    scheme: Scheme;

    @Input()
    optional: false;

    @Input()
    isViewMode: boolean;

    @Input()
    wideField: boolean;

    @Input()
    fieldDisplayName: string;

    @Input()
    providerPackage: string;

    @Input()
    inputWidth: string = null;

    @Input()
    packageDescriptionTitle: string = null;

    @Input()
    showPackageDescription: boolean = false;

    @Input()
    customFilteringCondition: (pkg: PackageModel) => boolean;

    indefiniteArticleText: string;
    sentenceName: string;
    fieldTitle: string;
    displayedControl: FormControl;

    packageTypeSettings = {
        solar: {
            defaultName: 'Eligible Solar Panel System',
            titleCase: null,
            allowLowerCase: false,
            lowerCase: null
        },
        battery: {
            defaultName: 'Eligible Battery System',
            titleCase: null,
            allowLowerCase: false,
            lowerCase: null
        },
        inverter: {
            defaultName: 'Inverter',
            titleCase: null,
            allowLowerCase: true,
            lowerCase: null
        },
        dcIsolator: {
            defaultName: 'DC isolator',
            titleCase: 'DC Isolator',
            allowLowerCase: true,
            lowerCase: 'DC isolator'
        }
    };

    selectedPackage: PackageModel;
    packagesByName: { [key: string]: PackageModel[] } = {};
    packagesByShortName: { [key: string]: PackageModel } = {};
    packages: PackageModel[] = [];

    filteredPackages: Observable<PackageModel[]>;
    selectedIndex: number;
    requestScope: PackageRequestScope;
    packagesLoaded = false;

    constructor(
        private quoteService: QuoteService,
        private errorCallback: ErrorCallbackGeneratorService,
        private snackBar: MatSnackBar
    ) {
        this.displayedControl = new FormControl(null);
    }

    ngOnInit() {
        this.displayedControl.disable();
        this.setDisplayText();
        this.setValidators();

        this.requestScope =
            this.category === PackageCategory.Battery && this.scoped
                ? PackageRequestScope.OnlyForBroker
                : PackageRequestScope.ForAllBrokers;

        this.loadPackages();
    }

    initialiseDisplayControl() {
        const dbInterval = Math.round((this.packages.length || 1) / 100);
        this.displayedControl.valueChanges.pipe(debounceTime(dbInterval)).subscribe(value => {
            this.setPackageFromUserInput(value);
        });
        this.displayedControl.enable();
    }

    ngOnChanges() {
        if (!this.packages.length) {
            return;
        }
        if (
            !this.selectedPackage ||
            this.selectedPackage.shortName !== this.controlFromParent.value ||
            !isEqual(this.selectedPackage, this.displayedControl.value)
        ) {
            this.setPackageFromId(this.controlFromParent.value);
        }
    }

    setDisplayText() {
        const settings = this.packageTypeSettings[this.category];
        if (!this.fieldDisplayName) {
            this.fieldDisplayName = settings.defaultName;
        }
        this.fieldTitle = settings.titleCase;
        this.sentenceName = settings.allowLowerCase
            ? settings.lowerCase || lowerCase(this.fieldDisplayName)
            : this.fieldDisplayName;
        const startsWithVowel = this.fieldDisplayName.charAt(0).match(/[aeiou]/i);
        this.indefiniteArticleText = startsWithVowel ? 'an ' : 'a ';
    }

    setValidators() {
        const valueIdFoundValidatorFn = valueIdFoundValidator(this.packagesByShortName, false, true);
        const validSelectablePackageValidatorFn: ValidatorFn = ctrl => {
            const errors =
                ctrl.value &&
                (!this.selectedPackage || ctrl.value !== this.selectedPackage.shortName) &&
                !this._isSelectable(this.packagesByShortName[ctrl.value])
                    ? { idNotFound: true }
                    : null;

            return errors;
        };
        const validators: ValidatorFn[] = [valueIdFoundValidatorFn, validSelectablePackageValidatorFn];

        if (this.controlFromParent.validator) {
            validators.push(this.controlFromParent.validator);
        }
        this.controlFromParent.setValidators(validators);
        this.displayedControl.setValidators(copyValidationValidator(this.controlFromParent));
    }

    loadPackages() {
        this.quoteService
            .getPackages(this.category, this.scheme, this.requestScope, this.providerPackage)
            .subscribe(result => {
                this.packages.push(...result);
                this.packages.forEach(pck => {
                    const packagesByName = this.packagesByName[pck.name.toLowerCase()] || [];
                    this.packagesByName[pck.name.toLowerCase()] = packagesByName.concat(pck);
                    this.packagesByShortName[pck.shortName] = pck;
                });
                this.loadSelectedPackage();
                this.watchAutocompleteChanges();
            }, this.errorCallback.generate(`getting ${this.category} packages`, this.snackBar));
    }

    watchAutocompleteChanges() {
        const packageLength = this.packages.length || 1;
        const dbInterval = Math.round(packageLength / 100);
        this.filteredPackages = this.displayedControl.valueChanges.pipe(
            debounceTime(dbInterval),
            startWith(''),
            filter(val => !val || typeof val === 'string'),
            map(value => this.filterPackageAutoComplete(value))
        );
    }

    private filterPackageAutoComplete(value: string) {
        const filterValue = (value || '').toLowerCase();
        const selectedPackageId = this.selectedPackage ? this.selectedPackage.shortName : null;

        return this.packages.filter(pck => {
            if (this.customFilteringCondition && !this.customFilteringCondition(pck)) {
                return false;
            }

            if (!this.scheme) return true;
            const currentPackageScheme = pck.schemes.find(s => (s.scheme = this.scheme));
            if (!currentPackageScheme) return false;
            const targetPackageStatus =
                selectedPackageId && pck.shortName == selectedPackageId
                    ? currentPackageScheme.systemStatus
                    : 'Available';
            return (
                pck &&
                currentPackageScheme.systemStatus === targetPackageStatus &&
                (!filterValue ||
                    (pck.name && pck.name.toLowerCase().includes(filterValue)) ||
                    (pck.shortName && pck.shortName.toLowerCase().includes(filterValue)))
            );
        });
    }

    loadSelectedPackage() {
        if (this.controlFromParent.value) {
            this.setPackageFromId(this.controlFromParent.value);
        }
        this.initialiseDisplayControl();
    }

    setSelectedPackage(newPackage: PackageModel) {
        this.selectedPackage = newPackage;
        if (this.onPackageSelected) {
            this.onPackageSelected.next({ package: newPackage, category: this.category });
        }
    }

    private setPackageFromMatchedInput(newPackage: PackageModel) {
        this.setSelectedPackage(newPackage);
        this.controlFromParent.setValue(newPackage && newPackage.shortName);
        this.displayedControl.setValue(newPackage, { emitEvent: false });
    }

    private setPackageFromUnmatchedInput(invalidPackage: string, keepInput = false) {
        this.setSelectedPackage(null);
        this.controlFromParent.setValue(invalidPackage);
        if (!keepInput) {
            this.displayedControl.setValue(null);
        }
    }

    private setPackageFromUserInput(input: string | PackageModel) {
        if (typeof input === 'string') {
            const isEmptyInput = input === '';
            const matchesByName = isEmptyInput ? null : this.packagesByName[input.toLowerCase()];
            const singleMatchedPackage =
                Array.isArray(matchesByName) && matchesByName.length === 1 ? matchesByName[0] : null;
            if (this._isSelectable(singleMatchedPackage)) {
                // Select the single matched package
                this.setPackageFromMatchedInput(singleMatchedPackage);
            } else {
                // Clear selected package and invalidate control
                this.setPackageFromUnmatchedInput(input, !isEmptyInput);
            }
            return;
        }
        this.setPackageFromMatchedInput(input);
    }

    setPackageFromId(packageId: string) {
        const matchingPackage = this.packagesByShortName[packageId];
        if (matchingPackage) {
            this.setPackageFromMatchedInput(matchingPackage);
        } else {
            this.setPackageFromUnmatchedInput(packageId);
        }
    }

    clearPackage(event: Event) {
        event.stopPropagation();
        this.setPackageFromUnmatchedInput(null);
        this.displayedControl.updateValueAndValidity();
    }

    _isSelectable(val: PackageModel) {
        if (!this.scheme) return !!val;
        return val && val.schemes.some(s => s.scheme === this.scheme && s.systemStatus === 'Available');
    }

    _getDisplayName(val: PackageModel) {
        return (val && val.name) || '';
    }

    getInputFlexRatio() {
        if (this.wideField) return '80%';
        if (this.inputWidth) return this.inputWidth;
        return '40%';
    }
}
